import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow, { InvoiceTableRowAddProf } from './InvoiceTableRow';
import InvoiceTableFooter, { InvoiceTableAddProfessionalFooter } from './InvoiceTableFooter';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#3778C2',
    },
});

const InvoiceItemsTable = ({job}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader stateName = {job.address.pincodeData.stateName}/>
        <InvoiceTableRow job={job}/>
        <InvoiceTableFooter job={job}/>
    </View>
);

export const InvoiceProfessionalItemsTable = ({prof}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader subsType = {prof?.subscription?.type} stateName = {prof.address.pincodeData.stateName}/>
        <InvoiceTableRowAddProf prof={prof}/>
        <InvoiceTableAddProfessionalFooter prof={prof}/>
    </View>
);

export default InvoiceItemsTable;