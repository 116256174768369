import React from 'react';
import {
    FaFacebookSquare,
    FaInstagram,
    FaWhatsapp
} from 'react-icons/fa';
import { generatePublicImgURL } from '../../../urlConfig';
import { ADMIN } from '../utils/constants/s3Constants';
import { useLoginPhoneUtils } from '../utils/common/loginPhoneUtils';
import { NAVIGATION } from '../../../utils/Google Analytics/GA4Constants';
import { REGISTER_ACTION } from '../utils/constants/CommonConstants';
import { Link } from 'react-router-dom';

const DesktopFooter = () => {

    const { handleAuthentication, renderLoginModal } = useLoginPhoneUtils();

    return (
        <div className='bg-black w-full border-t px-[7em] border-black/10 shadow mx-auto py-16 grid lg:grid-cols-3 gap-[2em] text-black mt-16'>
            <div>
                <div className='flex justify-center items-center gap-1'>
                    <img src={generatePublicImgURL(ADMIN + '/' + 'BharatProsLogo.png')} alt="bharatpros" width={28} height={23} className='' />
                    <h1 className='w-full text-3xl font-bold text-white -mb-2'>
                        BharatPros
                    </h1>
                </div>
                {/* <p className='py-4'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id odit ullam iste repellat consequatur libero reiciendis, blanditiis accusantium.</p> */}
                <div className='flex  text-white gap-5 mt-5 ml-[0.5em]'>
                    <a href="https://wa.me/+919805685009" target="_blank">
                        <FaWhatsapp size={30} />
                    </a>

                    <a href="https://www.instagram.com/bharat_pros/" target="_blank">
                        <FaInstagram size={30} />
                    </a>

                    <a href="https://www.facebook.com/bharatpros" target="_blank">
                        <FaFacebookSquare size={30} />
                    </a>
                </div>
            </div>
            <div className='lg:col-span-2 flex justify-between mt-0'>
                <div className=' text-white'>
                    <h6 className=' text-blue-400 mb-2 font-bold text-xl '>Services</h6>
                    <ul className='flex  gap-[4em]   '>
                        <ul className='flex flex-col gap-2 '>
                            <li className='text-md '>Hotels</li>
                            <li className=' text-md'>Homestays</li>
                        </ul>
                        <ul className='flex flex-col gap-2 '>
                            <li className=' text-md'>Electricians</li>
                            <li className=' text-md'>Plumbers</li>
                        </ul>
                        <ul className='flex flex-col gap-2 '>
                            <li className=' text-md'>Single Rooms</li>
                            <li className=' text-md'>Flat Homes</li>
                        </ul>
                        <ul className='flex flex-col gap-2 '>
                            <li className=' text-md'>Taxis</li>
                            <li className=' text-md'>Pickup vans</li>
                        </ul>
                    </ul>
                </div>


                <div className=' text-white'>
                    <h6 className=' text-blue-400 mb-2 font-bold text-xl '>For partners

                    </h6>
                    <li className='list-none m-auto'
                        onClick={() => handleAuthentication(NAVIGATION, REGISTER_ACTION)}
                    >Register as Professional</li>
                </div>

            </div>
            {/* <p className='text-white text-sm'>© Copyright 2022 BharatPros Company. All rights reserved.</p> */}
            <div>
                <Link to="/terms" className="no-underline">
                    <p className='text-white text-sm'>Terms and Conditions</p>
                </Link>
                <Link to="/about-us" className="no-underline">
                    <p className='text-white text-sm'>About Us</p>
                </Link>
                <Link to="/refund-policy" className="no-underline">
                    <p className='text-white text-sm'>Refund Policy</p>
                </Link>
            </div>
            {
                renderLoginModal()
            }
        </div>
    );
};

export default DesktopFooter;