import React, { useEffect, useState } from 'react';
import axios from 'axios';
import removeAuthToken from '../../utils/removeAuthToken';
import setAuthToken from '../../utils/setAuthToken';
import PincodeData from '../utils/PincodeData';
import Input from 'antd/es/input/Input';

const Pincode = ({ postOffice, setPostOffice, setPincode, showPincodeData = false }) => {

    const [postOfficeChoices, setPostOfficeChoices] = useState(null)

    const [pincodeData, setPincodeData] = useState({
        pincode: postOffice ? postOffice.Pincode : '',
        error: ""
    });

    useEffect(() => {
        removeAuthToken(); // To call Indian API x-auth-token needs to be removed and set after the api call
        // getPostOfficesFromPincodeUsingRapidAPI(parseInt(postOffice?.Pincode), setPostOfficeChoices, setPincodeData); // Rapid API
        getPostOfficesFromPincodeUsingIndianPostalCode(parseInt(postOffice?.Pincode), setPostOfficeChoices, setPincodeData); // Indian API
        setAuthToken(localStorage.token);
    }, [])

    useEffect(() => {
        if (Object.keys(postOffice).length > 0) {
            setPostOffice(postOffice);
        }
    }, [postOffice])

    const onChange = (e) => {
        if (e.target.value.length === 6) {
            setPincode(e.target.value)
            removeAuthToken();
            // getPostOfficesFromPincodeUsingRapidAPI(e.target.value, setPostOfficeChoices, setPincodeData); // Rapid API
            getPostOfficesFromPincodeUsingIndianPostalCode(e.target.value, setPostOfficeChoices, setPincodeData); // Indian API
            setAuthToken(localStorage.token);
        }
        resetPincodeAndPostOfficeBasedOnLength(e.target.value, setPostOfficeChoices, setPostOffice, setPincodeData);
    }
    return (
        <div className='w-full'>
            {
                pincodeData.error !== '' ? (
                    <span className="error-display">{pincodeData.error}</span>
                ) : null
            }

            <div className='lg: grid lg:grid-cols-2 lg:gap-2 mb-2 lg:mb-0'>
                <div className="mb-2">
                    <label
                        htmlFor="pincode"
                        className="block text-sm font-medium text-gray-600"
                    >
                        Pincode
                    </label>
                    <Input
                        className="mt-1 p-2 w-full border rounded-md"
                        maxLength={6}
                        minLength={6}
                        onChange={e => onChange(e)}
                        name="pincode"
                        value={pincodeData.pincode}
                        type="number"
                        required
                    />
                </div>

                {
                    postOfficeChoices != null ?
                        <div className="mb-2">
                            <label
                                htmlFor="postoffice"
                                className="block text-sm font-medium text-gray-600"
                            >
                                Post Office
                            </label>
                            <select
                                className="mt-1 p-2 py-2.5 w-full border rounded-md"
                                onChange={(e) => setPostOffice(JSON.parse(e.target.value))}>
                                <option key='all' value={JSON.stringify({})} className='text-gray-400'>Select Post Office</option>
                                {
                                    postOfficeChoices.map(po =>
                                        // <option key={po.placename} value={JSON.stringify(po)} className='text-gray-400'>{po.placename}</option>) // Rapid API
                                        <option key={po.placename} value={JSON.stringify(po)} className='text-gray-400'>{po.Name}</option>) // Indian API
                                }
                            </select>
                        </div>
                        : null
                }
            </div>

            {
                Object.keys(postOffice).length > 0 && showPincodeData === false ?
                    <>
                        <div className="flex lg:flex-row flex-col gap-2">
                            {/* <div className='mb-2'>
                                <label
                                    htmlFor="district"
                                    className="block text-sm font-medium text-gray-600"
                                >
                                    Post Office
                                </label>
                                <input
                                    type="text"
                                    readOnly
                                    // placeholder="PostOffice *"
                                    className=" bg-[#f5f5f5] mt-1 p-2 w-full border rounded-md"
                                    value={postOffice.placename}
                                    required
                                />
                            </div> */}
                            <div className="mb-2">
                                <label
                                    htmlFor="city"
                                    className="block text-sm font-medium text-gray-600"
                                >
                                    City
                                </label>
                                <input
                                    type="text"
                                    readOnly
                                    className="bg-black/5 mt-1 p-2 w-full border rounded-md"
                                    // value={postOffice.taluk} // Rapid API
                                    value={postOffice.Block} // Indian API
                                    required
                                />
                            </div>

                            <div className='mb-2'>
                                <label
                                    htmlFor="district"
                                    className="block text-sm font-medium text-gray-600"
                                >
                                    District
                                </label>
                                <input
                                    type="text"
                                    className=" bg-black/5 mt-1 p-2 w-full border rounded-md"
                                    readOnly
                                    // value={postOffice.districtname} // Rapid API
                                    value={postOffice.District} // Indian API
                                    required
                                />
                            </div>

                            <div className='mb-2'>
                                <label
                                    htmlFor="district"
                                    className="block text-sm font-medium text-gray-600"
                                >
                                    State
                                </label>
                                <input
                                    type="text"
                                    className=" bg-black/5 mt-1 p-2 w-full border rounded-md"
                                    readOnly
                                    // value={postOffice.statename} // Rapid API
                                    value={postOffice.State} // Indian API
                                    required
                                />
                            </div>
                        </div>
                    </> : ''
            }
            {
                Object.keys(postOffice).length > 0 && showPincodeData === true ?
                    <PincodeData
                        poName={postOffice.Name}
                        cityBlock={postOffice.Block}
                        district={postOffice.District}
                        stateName={postOffice.State}
                        pincode={postOffice.Pincode}
                    />
                    : ''
            }
        </div>
    );
}

export default Pincode

function getPostOfficesFromPincodeUsingIndianPostalCode(pincode, setPostOfficeChoices, setPincodeData) {
    axios
        .get(`https://api.postalpincode.in/pincode/${pincode}`)
        .then(res => {
            setPostOfficeChoices(res.data[0].PostOffice);
            setPincodeData({
                error: ""
            });
        }
        )
        .catch(err => {
            setPincodeData({
                error: "Invalid PIN Code"
            });
        });
}

async function getPostOfficesFromPincodeUsingRapidAPI(pincode, setPostOfficeChoices, setPincodeData) {
    const options = {
        method: 'GET',
        url: 'https://india-pincode-api.p.rapidapi.com/v1/in/places/pincode',
        params: { pincode: pincode },
        headers: {
            'X-RapidAPI-Key': `${process.env.REACT_APP_RAPID_API_INDIAN_POSTAL_CODE_KEY}`,
            'X-RapidAPI-Host': 'india-pincode-api.p.rapidapi.com'
        }
    };
    const response = await axios.request(options);
    console.log(response.data.result);
    setPostOfficeChoices(response.data.result)
}

function resetPincodeAndPostOfficeBasedOnLength(pincode, setPostOfficeChoices, setPostOffice, setPincodeData) {
    if (pincode.length !== 6) {
        setPincodeData({
            error: "ZIP code must be of 6 digits"
        });
        setPostOfficeChoices(null);
        setPostOffice({});
    }
}
