import { DistrictConstants } from "../actions/types";

const initialState = {
    districts: [],
    error: null,
    loading: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DistrictConstants.GET_DISTRICTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DistrictConstants.GET_DISTRICTS_SUCCESS:
            return {
                ...state,
                districts: payload.districts,
                loading: false,
            };
        case DistrictConstants.GET_DISTRICTS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload.error
            }
        default:
            return state;
    }
}