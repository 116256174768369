import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLoginPhoneUtils } from "./loginPhoneUtils";
import { generatePublicImgURL } from "../../../../urlConfig";
import { ADMIN, ICONS_AND_PHOTOS } from "../constants/s3Constants";
import AddressInImageCards from "./AddressInImageCards";

const ImageCard = ({ professional }) => {

    const { handleCallClick, renderLoginModal } = useLoginPhoneUtils();

    const settings = {
        dots:false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        // arrows: false, // Remove navigation icon
    };

    return (
        <>
            <div>
                <div className="py-4 px-8 border-2 border-[#f5f5f5] rounded-lg my-3 lg:my-0 overflow-hidden">
                    <div className="flex items-center justify-center  ">
                        <div className="w-full overflow-hidden rounded-md   ">
                            {
                                professional?.images == 0 ? (
                                    <div className=" -mt-2 relative">
                                        <img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + 'NoImageIcon.jpg')} alt="NoImageIcon" width="full" height="full" />
                                    </div>
                                ) : (
                                    // <Slider {...settings}>
                                        // {professional?.images.map((picture, index) => (
                                            <div  className="h-[200px] bg-gray-200 rounded-b-lg ">                                               
                                                    <img
                                                        src={generatePublicImgURL('serviceprofessionals/' + professional?.images?.[0].img)}
                                                        alt={"professional image"}
                                                        className="w-full h-full object-cover overflow-hidden"
                                                        style={{objectFit:"contain"}}
                                                    />
                                            </div>
                                        // ))}
                                    // </Slider>
                                )
                            }
                        </div>
                    </div>
                    <div className="flex flex-col mt-2 mx-2 gap-1">
                        <p className="font-bold">{professional.firmName ? professional.firmName : professional.address.name}</p>
                        <AddressInImageCards address={professional.address} />
                        <div className="flex justify-end items-end py-1 rounded-full duration-200 mt-2">
                            {/* <p className="bg-black py-[3px] text-white w-8 flex items-center justify-center rounded-full text-sm font-bold ">
                                    <span className="animate-pulse">
                                        <button onClick={() => handleCallClick(professional.mobile)}>
                                            <IoMdCall />
                                        </button>
                                    </span>
                                </p>
                                <span className="text-gray-500 text-[0.8em]">xxxxxxxxxx</span> */}  
                            <button className="text-md text-center bg-blue-400 px-4 py-1 shadow rounded-[4px] font-bold cursor-pointer"
                                onClick={() => handleCallClick(professional.address.mobile)}
                            >
                                Book now
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            {
                renderLoginModal()
            }
        </>
    );
};

export default ImageCard;