import { AddressConstants } from "./types";
import { setAlert } from './alert';
import backendAxios from "../utils/backendAxios";

export const getAddress = (addressQuery) => {
    return async (dispatch) => {
        try {
            dispatch({ type: AddressConstants.GET_USER_ADDRESS_REQUEST });
            const res = await backendAxios.get(`/api/address/user/getaddress`, {
                params: addressQuery
              });
            if (res.status === 200) {
                const {
                    userAddress: { address },
                    msg
                } = res.data;
                dispatch({
                    type: AddressConstants.GET_USER_ADDRESS_SUCCESS,
                    payload: { address, msg },
                });
            } else {
                const { error } = res.data;
                dispatch({
                    type: AddressConstants.GET_USER_ADDRESS_FAILURE,
                    payload: { error },
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const addAddress = (newAddress) => {
    return async (dispatch) => {
        try {
            dispatch({ type: AddressConstants.ADD_USER_ADDRESS_REQUEST });
            const res = await backendAxios.post(`/api/address/user/create`, newAddress);
            if (res.status === 201) {
                console.log(res);
                const {
                    address: { address },
                } = res.data;
                dispatch({
                    type: AddressConstants.ADD_USER_ADDRESS_SUCCESS,
                    payload: { address },
                });
                dispatch(setAlert('Address Info added', 'success'));
            } else {
                const { error } = res.data;
                dispatch({
                    type: AddressConstants.ADD_USER_ADDRESS_FAILURE,
                    payload: { error },
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

