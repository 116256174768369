import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getProfessionalStatus } from '../../actions/workerCategory';
import './ProfessionalList.css'
import ServiceCardStatus from './ServiceCardStatus';

const MyServices = () => {

    const professional = useSelector((state) => state.professional);

    const [professionalStatus, setProfessionalStatus] = useState(professional.professional);

    useEffect(() => {
        setProfessionalStatus(professional.professional);
    }, [professional.professional]);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log("calling professional status in myservices useEffect")
        dispatch(getProfessionalStatus())
    }, []);

    return (
        <>
            {
                professional.professional?.length > 0 ? (
                    professional.professional.map(professional => (
                        <ServiceCardStatus professional={professional} />
                    ))
                ) : (
                    <>
                    </>
                )
            }
        </>

    );
}

export default MyServices