import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '30%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    hsn: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    permonth: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    duration: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    type: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '30%',
        textAlign: 'right',
        paddingRight: 8,
    },
});

const InvoiceTableRow = ({ job }) => {
    // const rows = items.map(item =>
    // <View style={styles.row} key={item.sno.toString()}>
    const row = <View style={styles.row} >
        <Text style={styles.description}>{job.jobCategoryName}</Text>
        <Text style={styles.hsn}>998314</Text>
        <Text style={styles.duration}>{job.jobsubscription.durationInMonths} Months</Text>
        {/* <Text style={styles.period}>Months</Text>
        {
            job.address.pincodeData.stateName === 'Himachal Pradesh' ? (
                <>
                    <Text style={styles.taxTypeSGSTCGST}>SGST</Text>
                    <Text style={styles.taxRateSGSTCGST}>9%</Text>
                    <Text style={styles.taxTypeSGSTCGST}>CGST</Text>
                    <Text style={styles.taxRateSGSTCGST}>9%</Text>
                </>
            ) : (
                <>
                    <Text style={styles.taxTypeIGST}>IGST</Text>
                    <Text style={styles.taxRateIGST}>18%</Text>
                </>
            )
        } */}
        <Text style={styles.amount}>{job.amountForEnrollment}</Text>
    </View>
    // );
    return (<Fragment>{row}</Fragment>)
};

export const InvoiceTableRowAddProf = ({ prof }) => {
    const row = <View style={styles.row} >
        <Text style={styles.description}>{prof.service}</Text>
        <Text style={styles.hsn}>998314</Text>
        {
            prof?.subscription?.type === "Enrolment Basis" ? <>
                <Text style={styles.permonth}>{(prof.subscription.price)/(prof.subscription.durationInMonths)}</Text>
                <Text style={styles.duration}>{prof.subscription.durationInMonths} Months</Text>
            </> : <Text style={styles.type}>{prof.subscription.type}</Text>
        }
        <Text style={styles.amount}>{prof.amountForEnrollment}</Text>
    </View>
    return (<Fragment>{row}</Fragment>)
};

export default InvoiceTableRow;