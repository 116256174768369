import { CategoryConstants } from "../actions/types";

const initialState = {
    categories: [],
    loading: false,
    error: {}
  };

const buildNewCategories = (parentId,  categories, category) => {
    let myCategories = [];
    if(parentId === "") {
        return [
            ...categories,
            {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                type: category.type,
                children: []
            }
        ];
    }
    for(let cat of categories){

        if(cat._id === parentId){
            const newCategory = {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                parentId: category.parentId,
                type: category.type,
                children: []
            }
            myCategories.push({
                ...cat,
                children: cat.children.length > 0? [...cat.children, newCategory] : [newCategory]
            });
        }else{
            myCategories.push({
                ...cat,
                children: cat.children ? buildNewCategories(parentId, cat.children, category): []
            });
        }
    }
    return myCategories;
}
export default function(state = initialState, action) {
    const {type, payload} = action;
    switch(type) {
        case CategoryConstants.GET_ALL_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: payload.categories
            };
        case CategoryConstants.ADD_NEW_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true   
            };
        case CategoryConstants.ADD_NEW_CATEGORY_SUCCESS:
            const category = payload.category;
            const updatedCategories = buildNewCategories(category.parentId, state.categories, category);
            return {
                ...state,
                categories: updatedCategories,
                loading: false  
            };
        case CategoryConstants.ADD_NEW_CATEGORY_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: action.payload.error
            }
        case CategoryConstants.GET_ALL_CATEGORY_FAILURE:
            return {
                ...initialState
            };
        case CategoryConstants.UPDATE_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CategoryConstants.UPDATE_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case CategoryConstants.UPDATE_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload.error
            }
        case CategoryConstants.DELETE_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CategoryConstants.DELETE_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case CategoryConstants.DELETE_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload.error
            }
        default:
            return state;
    }
}