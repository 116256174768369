import React from "react";
import Slider from "react-slick";
import { generatePublicImgURL } from "../../../urlConfig";
import { ADMIN, BANNER_TILES } from "../utils/constants/s3Constants";
import { NextArrow, PrevArrow } from "../layout/CustomArrows";
import { useLoginPhoneUtils } from "../utils/common/loginPhoneUtils";
import { NAVIGATION } from "../../../utils/Google Analytics/GA4Constants";
import { REGISTER_ACTION } from "../utils/constants/CommonConstants";


const settings = {
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplaySpeed: 3000, // Adjust the autoplay speed in milliseconds
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const DesktopBanners = () => {
  const { handleAuthentication, renderLoginModal } = useLoginPhoneUtils();
  return (
    <div className="lg:max-w-[80em] m-auto w-full px-2 mb-6 rounded-2xl mt-3">
      <div className="rounded-md" onClick={() => handleAuthentication(NAVIGATION, REGISTER_ACTION)}>
        <Slider
          {...settings}
        >
          {[
            { imgName: "RentBanner.jpeg"},
            { imgName: "HomeStaysBanner.jpeg"},
            { imgName: "PlumberBanner.jpeg"}
          ].map((e, index) => (
            <div
              key={index}
              className="bg-none lg:w-[18em] gap-3  cursor-pointer"
            >
              <img
                src={generatePublicImgURL(ADMIN + '/' + BANNER_TILES + '/' + e.imgName)}
                alt={'Banner Tiles'}
                width={400}
                className="object-cover hover:scale-105 transitions-all duration-200 rounded-xl"
              />
            </div>
          ))}
        </Slider>
      </div>
      {
        renderLoginModal()
      }
    </div>
  );
};

export default DesktopBanners