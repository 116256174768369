import { Fragment, useState, useEffect } from 'react';
// import Pincode from 'react-pincode';
import Pincode from '../profile-forms/Pincode';
import { useDispatch, useSelector } from "react-redux";
import { getDistricts, getStates } from '../../actions/countrySpecificData';


const LocationBasedFiltering = (postOffice, 
                                setPostOffice, 
                                setDistrict, 
                                setStateName, 
                                setPincode,
                                locationFilter,
                                setLocationFilter) => {
    const states = useSelector((state) => state.states);
    const districts = useSelector((state) => state.districts);
    const [stateNames, setStateNames] = useState(states.states);
    const [nameOfState, setNameOfState] = useState('')
    const [districtNames, setDistrictNames] = useState(districts.districts);

    const dispatch = useDispatch();

    useEffect(() => {
        setStateNames(states.states);
    }, [states.states]);

    useEffect(() => {
        setDistrictNames(districts.districts);
    }, [districts.districts]);

    useEffect(() => {
        dispatch(getDistricts(nameOfState))
    }, [nameOfState])

    return (
        <Fragment>

            <input
                type="radio"
                onClick={() => setLocationFilter("statewise")}
                name="locationFilter"
                value="statewise"
            />
            <span>   State Wise    </span>

            <input
                type="radio"
                onClick={() => setLocationFilter("pincodewise")}
                name="locationFilter"
                value="pincode"
            />
            <span>   Pincode Wise  </span>

            {
                locationFilter === "statewise" || locationFilter === ''?
            <>
                <select
                    onChange={(e) => { setStateName(e.target.value); setNameOfState(e.target.value) }} className='btn btn-light'>
                    <option key={""} value={""}>Select State</option>
                    {
                        stateNames.map(option =>
                            <option key={option._id} value={option.name}>{option.name}</option>)
                    }
                </select>


                <select
                    onChange={(e) => setDistrict(e.target.value)} className='btn btn-light' >
                    <option key={""} value={""}>Select District</option>
                    {
                        districtNames.map(option =>
                            <option key={option._id} value={option.name}>{option.name}</option>)
                    }
                </select>
            </> : 
            <>
                <Pincode postOffice={postOffice} setPostOffice={setPostOffice} setPincode={setPincode} showPincodeData={true}/>
            </>
            }
            
        </Fragment>
    );
}

export default LocationBasedFiltering;