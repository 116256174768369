import React from "react";
import { Link } from 'react-router-dom';
import { generatePublicImgURL } from "../../../urlConfig";
import { ADMIN } from "../utils/constants/s3Constants";

const DesktopHeroSection = () => {
  return (
    <div className="flex justify-between gap-[6em] items-center mb-5 mt-8 max-w-[80em] m-auto">
      <div className="flex flex-col gap-2.5">
        <p className="text-4xl  font-bold mb-5">Services One Click away</p>
        <div className="flex flex-col gap-2 border border-black/10 p-4 rounded-lg ">
          <p className="text-gray-500 text-xl font-bold mb-3">
            Services you are looking for ?
          </p>
          <div className="grid grid-cols-3 gap-1">
            {[
              { s3PathName: "electrical-plumbing-appliance-services", imgName: "Electrician.jpg", serviceName: "Electricians", link: "/electrician" },
              { s3PathName: "electrical-plumbing-appliance-services", imgName: "WashingMachine.jpeg", serviceName: "Washing Machine", link: "/washing-machine" },
              { s3PathName: "electrical-plumbing-appliance-services", imgName: "AC.jpeg", serviceName: "AC Repair and Installation", link: "/ac" },
              { s3PathName: "electrical-plumbing-appliance-services", imgName: "ROPurifier.jpeg", serviceName: "RO Purifier Services", link: "/ro" },
              { s3PathName: "rentals", imgName: "SingleRoom.jpeg", serviceName: "Single Room", link: "/single-room" },
              { s3PathName: "rentals", imgName: "Flats.jpeg", serviceName: "Flat Homes", link: "/flat-homes" },
            ].map((e, index) => (
              <Link to={e.link}>
                <div
                  className="flex flex-col flex-wrap  justify-center  items-center    cursor-pointer "
                  key={index}
                >
                  <div
                    className="rounded-lg m"
                  >
                    <img
                      src={generatePublicImgURL(ADMIN + '/' + e.s3PathName + '/' + e.imgName)}
                      alt={`Poster ${index + 1}`}
                      width={120}
                      height={120}
                      className="rounded-md hover:scale-105 transitions-all duration-200"
                    />
                  </div>
                  <p className="text-sm mt-2">{e.serviceName}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {/* seconed section */}
      <div className="grid grid-cols-2 gap-2 mt-3">
        {[
          { imgName: "WashingMachine.jpeg", roundness: "rounded-tl-lg", link: "/washing-machine" },
          { imgName: "ROPurifier.jpeg", roundness: "rounded-tr-lg", link: "/ro" },
          { imgName: "AC.jpeg", roundness: "rounded-bl-lg", link: "/ac" },
          { imgName: "CCTV.jpeg", roundness: "rounded-br-lg", link: "/cctv" },
        ].map((e, index) => (
          <Link to={e.link}>
            <div key={index} className="w-[280px] h-[280px]">
              <img
                src={generatePublicImgURL(ADMIN + '/' + 'electrical-plumbing-appliance-services/' + e.imgName)}
                alt={`Poster ${index + 1}`}
                sizes="full"
                className={`${e.roundness} hover:scale-105 transitions-all duration-200`}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DesktopHeroSection;
