import React from 'react'

const SelectYourAddress = ({ showAddressModal, confirmAddress }) => {
    return (
        <div
            onClick={showAddressModal}
            className={
                `flex lg:flex-col md:flex-col gap-3 justify-center item-center text-md font-bold p-2 rounded-lg border-dashed lg:ml-4 text-black px-4 active:scale-105 duration-200 shadow-sm border border-black/30
                ${!confirmAddress ? 'lg:h-[10em]' : ''}`
            }
        >
            {
                confirmAddress ? (
                    <p className='text text-blue-500'>Change Address</p>
                ) : (
                    <p>Select Your Address</p>
                )
            }
            <span className="text-2xl text-center">+</span>
        </div>
    )
}

export default SelectYourAddress