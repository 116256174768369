import React from "react";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { generatePublicImgURL } from "../../../urlConfig";

import { MdLocalPhone } from "react-icons/md";
import { logout } from "../../../actions/auth";
import { ADMIN, ICONS_AND_PHOTOS, SOCIAL_MEDIA_LOGOS } from "../utils/constants/s3Constants";
import { TrackGoogleAnalyticsEvent } from "../../../utils/Google Analytics/Hooks/useGAEventsTracker";
import { BUTTON, CLICKED, LOGOUT } from "../../../utils/Google Analytics/GA4Constants";

const ProfileActions = ({ onClose }) => {

    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const signout = () => {
        TrackGoogleAnalyticsEvent(CLICKED, LOGOUT, BUTTON)
        dispatch(logout());
    };

    const menuItems = [
        {
            id: 1,
            label: "MyProfile",
            icon: "UserIcon.svg",
            link: `/profile/${auth?.user?._id}`,
        },
        {
            id: 2,
            label: "My Services",
            icon: "ServiceIcon.svg",
            link: "/myservices",
        },
        { id: 3, label: "Logout", icon: "LogoutIcon.svg", onClick: signout },
        { id: 4, label: "About Us", icon: "AboutIcon.svg", link: "/about-us" },
    ];

    return (
        <div
            onClick={onClose} // Close the mobile side nav when clicking anywhere on the overlay
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100%",
                background: "white", // semi-transparent black background
                zIndex: 0,
            }}
            className="bg-[#cfcdcd] h-full z-50 overflow-hidden"
        >
            {/* Your mobile side navigation content goes here */}
            <div className=" bg-[#f5f5f5] pt-[30px] h-full ">
                {/* header div */}
                <div className="bg-[#cfcdcd] py-2  flex items-center mx-2 rounded-md shadow-md mb-2 ">
                    <img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + 'UserProfileIcon.png')} alt="user_icon" width={80} />
                    <div className="flex flex-col justify-center  ">
                        <p className="font-bold text-gray-600 px-2 text-2xl ">
                            Welcome {auth?.user?.name}!
                        </p>
                        <p className="text-gray-800 font-bold px-2 ">{auth?.user?.mobile}</p>
                    </div>
                </div>

                <div className="w-full ">
                    <ul className="w-full grid grid-cols-2">
                        {menuItems.map((item) => (
                            <li key={item.id} className="col-span-1">
                                {
                                    item.onClick ? (
                                        <div className="flex flex-col gap-2 py-3 px-2 font-bold text-xl justify-center items-center m-2 bg-blue-100 shadow-sm border border-gray-500 
                                        rounded-md hover:bg-slate-300 transition-all duration-200"
                                        onClick={() => item.onClick()}>
                                            <img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + item.icon)} alt={`${item.label}_icon`} width={50} />
                                            {item.label}
                                        </div>
                                    ) : (
                                        <Link to={item.link} className="no-underline">
                                            <div className="flex flex-col gap-2 py-3 px-2 font-bold text-xl justify-center items-center m-2 bg-blue-100 shadow-sm border border-gray-500 rounded-md hover:bg-slate-300 transition-all duration-200">
                                                <img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + item.icon)} alt={`${item.label}_icon`} width={50} />
                                                {item.label}
                                            </div>
                                        </Link>
                                    )

                                }

                            </li>
                        ))}
                    </ul>
                </div>

                <div className="px-3 mt-3 flex flex-col text-center">
                    <p className="font-extrabold text-3xl text-gray-400 flex items-center gap-2 justify-center mt-4">
                        {" "}
                        <img src={generatePublicImgURL(ADMIN + '/' + 'BharatProsLogo.png')} alt="bharatPros" width={30} />
                        BharatPros
                    </p>
                    <Link to="/terms" className="no-underline">
                        <p className="mt-5">Terms and Conditions </p>
                    </Link>
                    <Link to="/refund-policy" className="no-underline">
                        <p className="mt-1">Refund Policy </p>
                    </Link>
                    <p className="flex items-center justify-center gap-2 mt-3">

                        Customer Care:
                        <a href={`tel:/${"+919805685009"}`}>  <MdLocalPhone className="text-black" />
                        </a>
                    </p>
                </div>

                <div className="w-full flex gap-5 justify-center items-center mt-4">
                    <a href="https://wa.me/+919805685009" target="_blank">
                        <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'WhatsAppLogo.png')} alt="WhatsAppLogo" width={40} />
                    </a>

                    <a href="https://www.instagram.com/bharat_pros/" target="_blank">
                        <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' +'InstagramLogo.png')} alt="InstagramLogo" width={42} />
                    </a>

                    <a href="https://www.facebook.com/bharatpros" target="_blank">
                        <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'FacebookLogo.png')} alt="FacebookLogo" width={40} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ProfileActions;