import { SubscriptionConstants } from './types';
import backendAxios from '../utils/backendAxios';

//Admin
export const addSubscription = (newSubscription, subscriptionObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SubscriptionConstants.ADD_SUBSCRIPTION_REQUEST });
            const res = await backendAxios.post(`/api/subscription`, newSubscription);
            if (res.status === 201) {
                dispatch({ type: SubscriptionConstants.ADD_SUBSCRIPTION_SUCCESS });
                dispatch(getSubscriptions(subscriptionObj));
            } else {
                const { error } = res.data;
                dispatch({
                    type: SubscriptionConstants.ADD_SUBSCRIPTION_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: SubscriptionConstants.ADD_SUBSCRIPTION_FAILURE,
                payload: { error }
            });
        }
    };
};

export const getSubscriptions = (subscriptionObj) => {
    return async dispatch => {
        try {
            dispatch({ type: SubscriptionConstants.GET_SUBSCRIPTIONS_REQUEST });
            const res = await backendAxios.get('/api/subscription', {
                params: subscriptionObj
            });
            if (res.status === 200) {
                const { subscriptions } = res.data;
                dispatch({
                    type: SubscriptionConstants.GET_SUBSCRIPTIONS_SUCCESS,
                    payload: { subscriptions }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: SubscriptionConstants.GET_SUBSCRIPTIONS_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: SubscriptionConstants.GET_SUBSCRIPTIONS_FAILURE,
                payload: { error },
            });
        }
    }
}

export const toggleSubscription = (subscriptionObj, subsId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SubscriptionConstants.TOGGLE_SUBSCRIPTION_ENABLE_REQUEST });
            const res = await backendAxios.put(`/api/subscription/${subsId}/toggle-enabled`);
            if (res.status === 200) {
                dispatch({ type: SubscriptionConstants.TOGGLE_SUBSCRIPTION_ENABLE_SUCCESS });
                dispatch(getSubscriptions(subscriptionObj));
            } else {
                const { error } = res.data;
                dispatch({
                    type: SubscriptionConstants.TOGGLE_SUBSCRIPTION_ENABLE_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: SubscriptionConstants.TOGGLE_SUBSCRIPTION_ENABLE_FAILURE,
                payload: { error }
            });
        }
    };
};

export const updateSubscriptionPrice = (subscriptionObj, subsId, newPrice) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SubscriptionConstants.UPDATE_SUBSCRIPTION_PRICE_REQUEST });
            const res = await backendAxios.put(`/api/subscription/${subsId}/price`, {
                price: newPrice
            });
            if (res.status === 200) {
                dispatch({ type: SubscriptionConstants.UPDATE_SUBSCRIPTION_PRICE_SUCCESS });
                dispatch(getSubscriptions(subscriptionObj));
            } else {
                const { error } = res.data;
                dispatch({
                    type: SubscriptionConstants.UPDATE_SUBSCRIPTION_PRICE_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: SubscriptionConstants.UPDATE_SUBSCRIPTION_PRICE_FAILURE,
                payload: { error }
            });
        }
    };
};