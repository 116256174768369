import React from 'react'

const AddressInImageCards = (props) => {
    const { address } = props;
    return (
        <>
            <p className="text-[0.8em] text-gray-500">
                {address.pincodeData?.poName} &nbsp; {address.pincodeData?.cityBlock}
                &nbsp; {address.pincodeData?.stateName}  &nbsp; {address.pincodeData?.pincode}
            </p>    
        </>
    )
}

export default AddressInImageCards