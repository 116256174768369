import { PhonePeConstants } from '../../actions/types';
  
  const initialState = {
    res: {},
    loading: false,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const {type, payload} = action;
  
    switch(type) {
      case PhonePeConstants.PHONEPE_REQUEST:
        return {
          ...state,
          res: payload,
          loading: true
        };
      case PhonePeConstants.PHONEPE_SUCCESS:
        return {
          ...state,
          res: payload,
          loading: false
        };
      case PhonePeConstants.PHONEPE_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false
        };
      default:
        return state;
    }
  }