function formatDate(date) {
    return new Intl.DateTimeFormat().format(new Date(date));
  }

export const formatDateNew = (date) => {
  if (date) {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }
  return "";
};

export const customDate = (date, numOfMonths) => {
  if (date) {
    const d = new Date(date);
    let numOfYears = Math.floor((d.getMonth() +  numOfMonths) / 12);
    return `${d.getDate()}-${(d.getMonth() + numOfMonths)%12 + 1}-${d.getFullYear() + numOfYears}`;
  }
  return "";
};

export const formatDate2 = (date) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (date) {
    const d = new Date(date);
    return `${month[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  }
};
  
export default formatDate