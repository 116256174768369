import React from 'react';
import { Modal, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { propTypes } from 'react-bootstrap/esm/Image';

const NewModal = (props) => {
    return (
        <Modal size={props.size} show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                {
                    props.enableButton === undefined || props.enableButton? 
                    (
                        props.buttons ? 
                            props.buttons.map((btn, index) =>
                                <Button key={index} variant={btn.color} onClick={btn.onClick}>
                                    {btn.label}
                                </Button>
                            ) :
                            <Button 
                                variant="primary" 
                                className="btn-sm" 
                                onClick={props.onSubmit}
                            >
                                Submit
                            </Button> 
                    ) : null
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NewModal;