import React from 'react';

const RefundPolicy = () => {
    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Refund Policy</h2>
            <p style={styles.paragraph}>At BHARATPROS, we value transparency and strive to ensure a positive experience for all users of our platform. As we solely charge for advertisements of services and do not engage in direct sales or transactions, please note that refunds are not provided for ad placements on BHARATPROS.</p>

            <h3 style={styles.subHeading}>1. Ad Placement Fee:</h3>
            <p style={styles.paragraph}>BHARATPROS charges a fee for the placement of advertisements of services on our platform. The fee is determined based on various factors, including ad placement duration, visibility, and targeting options.</p>

            <h3 style={styles.subHeading}>2. Payment for Advertisements:</h3>
            <p style={styles.paragraph}>Payment for ad placements is required upfront before the advertisement is published on BHARATPROS. Once payment is made, the ad will be reviewed and, if approved, published on the platform according to the selected criteria.</p>

            <h3 style={styles.subHeading}>3. No Refunds:</h3>
            <p style={styles.paragraph}>As we only charge for the placement of advertisements and do not engage in direct sales or transactions of services, refunds are not provided for ad placements on BHARATPROS. It is the responsibility of advertisers to ensure that the content of their advertisements complies with our policies and guidelines before making payment.</p>

            <h3 style={styles.subHeading}>4. Modifications and Cancellations:</h3>
            <p style={styles.paragraph}>Advertisers may modify or cancel their ad placements before the ad is published on BHARATPROS, subject to our modification and cancellation policies. Any modifications or cancellations must be communicated to BHARATPROS support in a timely manner.</p>

            <h3 style={styles.subHeading}>5. Dispute Resolution:</h3>
            <p style={styles.paragraph}>In the event of any disputes or concerns regarding ad placements, advertisers are encouraged to contact BHARATPROS support for assistance. BHARATPROS will endeavor to resolve any issues promptly and fairly, in accordance with our policies and guidelines.</p>

            <h3 style={styles.subHeading}>6. Modifications to this Policy:</h3>
            <p style={styles.paragraph}>BHARATPROS reserves the right to modify or update this Refund Policy at any time without prior notice. Any changes will be effective immediately upon posting on the BHARATPROS platform. Advertisers are responsible for reviewing this policy periodically for any updates or changes.</p>

            <h3 style={styles.subHeading}>Contact Us:</h3>
            <p style={styles.paragraph}>If you have any questions or concerns about our Refund Policy or ad placements on BHARATPROS, please contact us at <a href="mailto:contact-us@bharatpros.com" style={styles.link}>contact-us@bharatpros.com</a>.</p>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    heading: {
        fontSize: '24px',
        marginBottom: '15px',
    },
    subHeading: {
        fontSize: '20px',
        marginTop: '20px',
        marginBottom: '10px',
    },
    paragraph: {
        fontSize: '16px',
        lineHeight: '1.5',
        marginBottom: '15px',
    },
    link: {
        color: '#007bff',
        textDecoration: 'none',
        fontWeight: 'bold',
    },
};

export default RefundPolicy;
