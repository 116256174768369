import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoginModal from '../../auth/LoginModal';
import { TrackGoogleAnalyticsEvent } from '../../../../utils/Google Analytics/Hooks/useGAEventsTracker';

export const useLoginPhoneUtils = () => {
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const [loginModal, setLoginModal] = useState(false);

  const showLoginModal = () => {
    setLoginModal(true);
  };

  const handleOk = () => {
    setLoginModal(false);
  };

  const handleCancel = () => {
    setLoginModal(false);
  };

  const handleCallClick = (mobile) => {
    window.location.href = `tel:/${mobile}`;
    // if (auth.isAuthenticated) {
    //   window.location.href = `tel:/${mobile}`;
    // } else {
    //   showLoginModal();
    // }
  };

  const handleAuthentication = (category, link) => {
    TrackGoogleAnalyticsEvent(category, link, link)
    if (auth.isAuthenticated) {
      history.push(link)
    } else {
      showLoginModal();
    }
  };

  const renderLoginModal = () => {
    return (
      <LoginModal
        title="Login / SignUp"
        loginModal={loginModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={null}
      />
    );
  };

  return {
    handleCallClick,
    renderLoginModal,
    handleAuthentication
  };
};
