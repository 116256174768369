import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FaMinus, FaPlus } from "react-icons/fa6";

const ProfileAbout = ({
  profile: {
    status,
    bio,
    skills,
    user: { name }
  },
  SkillsOpenDailogHandler,
  openSkill
}) => (
  // <div className='profile-about bg-light p-2'>
  //   {bio && (
  //     <Fragment>
  //       <h2 className='text-primary'>{name}'s Bio</h2>
  //       <p>{bio}</p>
  //       <div className='line' />
  //     </Fragment>
  //   )}
  //   <h2 className='text-primary'>Skill Set</h2>
  //   <div className='skills'>
  //     {skills.map((skill, index) => (
  //       <div key={index} className='p-1'>
  //         <i className='fas fa-check' /> {skill}
  //       </div>
  //     ))}
  //   </div>
  // </div>
  <>
    <div className=" mx-2  border-2 border-black/10">
      <div className=" flex justify-between px-1 items-center bg-black/10  py-1 ">
        <div className=" text-xl py-1 font-semibold  flex justify-center px-1 items-center  ">
          Bio
        </div>
        <p className="text-[0.8em] text-gray-500 bg-green-300 p-1 m-1 rounded-full px-4 shadow-sm shadow-green-100">
          {status}
        </p>
      </div>
      <div className=" mx-2 py-1 ">
        <p className="text-[0.8em] text-gray-800  py-2 tracking-normal ">
          {bio}
        </p>
      </div>
    </div>

    <div className="mx-2  border-2 border-black/10 ">
      <div className=" px-2 text-xl py-1 font-semibold  flex  justify-between  items-center bg-black/10   ">
        <p>Skills</p>
        <span>
          {
            openSkill ? (
              <FaMinus
                onClick={SkillsOpenDailogHandler}
                className="active:rotate-45 transition-all duration-200"
              />
            ) : (
              <FaPlus
                onClick={SkillsOpenDailogHandler}
                className="active:rotate-45 transition-all duration-200"
              />
            )
          }
        </span>
      </div>

      {openSkill && (
        <div className="flex  flex-wrap mx-2 p-2 gap-3 py-42">
          {skills.map((e, index) => (
            <p className="text-sm p-2 px-3  rounded-full bg-blue-100">
              {e}
            </p>
          ))}
        </div>
      )}
    </div>
  </>
);

ProfileAbout.propTypes = {
  profile: PropTypes.object.isRequired
};

export default ProfileAbout;