import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function Search({ input, searchCategory, onLocationSelect, onCancel }) {
    return (
        <div className="w-[92%] py-3">
            <Autocomplete
                options={input.map((option) => option)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={searchCategory}
                    />
                )}
                onChange={(e, value) => onLocationSelect(value ? value : '')}
            />
        </div>
    );
}