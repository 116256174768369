import React from "react";
import { FaLocationDot } from "react-icons/fa6";

const ServiceDescription = ({ name, description, location }) => {
  return (
    <div className="lg:mx-[20em] lg:-mt-[65em] md:mt-[3em] mt-3 mx-2">
      <p className="text-3xl font-extrabold mb-4">{name}</p>
      <p className="mb-6 text-gray-500 lg:font-semibold md:font-bold ">
        {description}
      </p>
      {location?.length > 0 && (
        <p className="flex gap-2 items-center mb-6 text-gray-800 font-semibold">
          <span>
            <FaLocationDot />
          </span>
          {location?.[0]}
        </p>
      )}
    </div>
  );
};

export default ServiceDescription;
