import React, { useState, useEffect } from "react";
import { Table, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSectors, addSector } from "../../actions/sector";

const Sectors = () => {
  const sector = useSelector((state) => state.sector);
  const auth = useSelector((state) => state.auth);
  const [sectors, setSectors] = useState(sector.sectors);

  const [sectorName, setSectorName] = useState("");
  const [desc, setDesc] = useState("");
  const [addSectorModal, setAddSectorModal] = useState(false);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: sectors.length, // Set the total count initially
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setSectors(sector.sectors);
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination: {
        ...prevParams.pagination,
        total: sector.sectors.length, // Update the total count
      },
    }));
  }, [sector.sectors]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getSectors());
    }
  }, [auth.isAuthenticated]);

  const showAddSectorModal = () => {
    setAddSectorModal(true);
  };

  const handleCloseAddSectorModal = () => {
    setAddSectorModal(false);
    setSectorName("");
    setDesc("");
  };

  const addSectorForm = () => {
    const newSector = {
      sector: sectorName,
      description: desc,
    }
    dispatch(addSector(newSector));
    setAddSectorModal(false);
    setSectorName("");
    setDesc("");
  };

  const renderAddSectorModal = () => {
    return (
      <Modal
        title="Add new Sector"
        open={addSectorModal}
        onOk={addSectorForm}
        onCancel={handleCloseAddSectorModal}
      >
        <input
          className="form-control border border-gray-300 rounded-md px-3 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          label="Name"
          value={sectorName}
          placeholder={`Sector Name`}
          onChange={(e) => setSectorName(e.target.value)}
        />
        <input
          className="form-control border border-gray-300 rounded-md px-3 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          label="Description"
          value={desc}
          placeholder={`Description`}
          onChange={(e) => setDesc(e.target.value)}
        />
      </Modal>
    );
  }

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      sorter: true,
      render: (text, record, index) =>
        (tableParams.pagination.current - 1) * tableParams.pagination.pageSize +
        index +
        1,
      width: "20%",
    },
    {
      title: "Sector",
      dataIndex: "sector",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (active) => active.toString(),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <div className="flex flex-col justify-center gap-3 my-4">
      <div className="flex justify-center">
        <button onClick={showAddSectorModal} className="blue-gradient px-3 py-2 rounded-full uppercase text-white font-extrabold">
          Add Sector +
        </button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={sectors.map((sector, index) => ({
          key: index,
          sector: sector.sector,
          description: sector.description,
          active: sector.active,
          createdBy: sector.createdBy,
          _id: sector._id,
        }))}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
      />
      {renderAddSectorModal()}
    </div>
  );
};

export default Sectors
