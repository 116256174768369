import React, { useEffect, useState } from "react";
import "./style.css";
// import flipkartLogo from "../../images/logo/flipkart.png";
// import goldenStar from "../../images/logo/golden-star.png";
import { IoIosArrowDown, IoIosCart, IoIosSearch } from "react-icons/io";
import { TfiAlignJustify } from 'react-icons/tfi'
import {
    MaterialInput,
    MaterialButton,
    DropdownMenu,
} from "../MaterialUI";
import Modal from '../Modal'
import { useDispatch, useSelector } from "react-redux";
import { login, logout as signout, register } from '../../../actions/auth';
import { getCartItems } from "../../../actions/cart";
import Cart from "../Cart";
import { Link } from 'react-router-dom';
import { MdSettingsPhone } from "react-icons/md";
import { IoIosCall } from 'react-icons/io'
import { withRouter } from 'react-router-dom';

/**
 * @author
 * @function Header
 **/
// ReactGA.initialize("376912278")
// ReactGA.initialize([
//     {
//       trackingId: "G-RNW69KQWD1"
//     }
//   ]);
const Header = (props) => {
    const [loginModal, setLoginModal] = useState(false);
    const [signup, setSignup] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    // state cart value
    const cart = useSelector((state) => state.cart);

    const userSignup = () => {
        const user = { firstName, lastName, email, password };
        if (
            firstName === "" ||
            lastName === "" ||
            email === "" ||
            password === ""
        ) {
            return;
        }

        dispatch(register(user));
    };

    const userLogin = () => {
        if (signup) {
            userSignup();
        } else {
            dispatch(login({ mobile }));
        }
    };

    const logout = () => {
        dispatch(signout());
    };

    useEffect(() => {
        if (auth.isAuthenticated) {
            setLoginModal(false);
        }
    }, [auth.isAuthenticated]);

    // useEffect(() => {
    //   dispatch(getCartItems());
    // }, []);

    const renderLoggedInMenu = () => {
        return (
            <DropdownMenu
                menu={
                        <a className="fullName">
                            <TfiAlignJustify/> &nbsp;
                            {auth?.user?.name}
                        </a>
                    }
                menus={[
                    { label: "My Profile", href: `/profile/${auth?.user?._id}`, icon: null },
                    // {
                    //     label: "Orders",
                    //     href: '/account/orders',
                    //     icon: null,
                    // },
                    {
                        label: "My Services",
                        href: '/myservices',
                        icon: null,
                    },
                    {
                        label: "Created Jobs",
                        href: '/myjobs',
                        icon: null,
                    },
                    { label: "Logout", href: "", icon: null, onClick: logout },
                    //   { label: "Wishlist", href: "", icon: null },
                    //   { label: "My Chats", href: "", icon: null },
                    //   { label: "Coupons", href: "", icon: null },
                    //   { label: "Rewards", href: "", icon: null },
                    //   { label: "Notifications", href: "", icon: null },
                    //   { label: "Gift Cards", href: "", icon: null },
                ]}
            />
        );
    };

    const renderNonLoggedInMenu = () => {
        return (
            <DropdownMenu
                // menu={
                //     <a
                //         className="loginButton"
                //         onClick={() => {
                //             setSignup(false);
                //             setLoginModal(true);
                //         }}
                //     >
                //         Login
                //     </a>
                // }
                menus={[
                    { label: "My Profile", href: "", icon: null },
                    //   { label: "Flipkart Plus Zone", href: "", icon: null },
                    {
                        label: "Orders",
                        href: '/account/orders',
                        icon: null,
                        onClick: () => {
                            !auth.isAuthenticated && setLoginModal(true);
                        },
                    },
                    //   { label: "Wishlist", href: "", icon: null },
                    //   { label: "Rewards", href: "", icon: null },
                    //   { label: "Gift Cards", href: "", icon: null },
                ]}
                firstMenu={
                    <div className="firstmenu">
                        <span>New Customer?</span>
                        <a
                            onClick={() => {
                                setLoginModal(true);
                                setSignup(true);
                            }}
                            style={{ color: "#2874f0" }}
                        >
                            Sign Up
                        </a>
                    </div>
                }
            />
        );
    };


    return (
        <div className="header p-1 my-1">
            <Modal
                show={loginModal}
                modalTitle={'Please Login'}
                handleClose={() => setLoginModal(false)}
                size="lg"
            >
                <div className="authContainer">
                    <div className="row">
                        <div className="leftspace">
                            <h2>Login</h2>
                            <p>Get access to your Orders, Wishlist and Recommendations</p>
                        </div>
                        <div className="rightspace">
                            <div className="loginInputContainer">
                                {auth.error && (
                                    <div style={{ color: "red", fontSize: 12 }}>{auth.error}</div>
                                )}
                                        {signup && (
                                            <MaterialInput
                                                type="text"
                                                label="First Name"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        )}
                                        {signup && (
                                            <MaterialInput
                                                type="text"
                                                label="Last Name"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        )}

                                        <MaterialInput
                                            type="text"
                                            label="Mobile Number"
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />
                                        {/* <MaterialInput
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // rightElement={<a href="#">Forgot?</a>}
                /> */}
                                        <MaterialButton
                                            title={signup ? "Register" : "Login"}
                                            bgColor="#fb641b"
                                            textColor="#ffffff"
                                            style={{
                                                margin: "40px 0 20px 0",
                                            }}
                                            onClick={userLogin}
                                        />
                                {/* <p style={{ textAlign: "center" }}>OR</p>
                <MaterialButton
                  title="Request OTP"
                  bgColor="#ffffff"
                  textColor="#2874f0"
                  style={{
                    margin: "20px 0",
                  }}
                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="subHeader p-1 my-1">
                {/* Logo  */}
                <div className="logo">
                    <a href="">
                        <img src="" className="logoimage" alt="" />
                    </a>
                    <a style={{ marginTop: "-10px" }}>
                        <span className="plusText">Making</span>
                        <span className="plusText">Life easy</span>
                        <img src="" className="goldenStar" alt="" />
                    </a>
                </div>
                {/* logo ends here */}

                {/* search component */}
                <div
                    style={{
                        padding: "0 10px",
                    }}
                >
                    {/* <div className="searchInputContainer">
                        <input
                            className="searchInput"
                            placeholder={"search for products, brands and more"}
                        />
                        <div className="searchIconContainer">
                            <IoIosSearch
                                style={{
                                    color: "#2874f0",
                                }}
                            />
                        </div>
                    </div> */}
                </div>
                {/* search component ends here */}

                {/* right side menu */}
                <div className="rightMenu">
                    {auth.isAuthenticated ? renderLoggedInMenu() : renderNonLoggedInMenu()}
                    <div>
                    <Link to='/dashboard' className="db">
                        <i className='fas fa-user' />{' '}
                        <span>Dashboard</span>
                    </Link>
                    </div>
                    <DropdownMenu
                        menu={
                            <a className="more">
                                <span>More</span>
                                <IoIosArrowDown />
                            </a>
                        }
                        menus={[
                            // { label: "Notification Preference", href: "", icon: null },
                            // { label: "Sell on flipkart", href: "", icon: null },
                            { label: "", href: "", icon: <IoIosCall />, value: "24x7 Customer Care", showAnchorTag: true, mobile: '+919805685009' }
                            // { label: "Advertise", href: "", icon: null },
                            // { label: "Download App", href: "", icon: null },
                        ]}
                    />
                    {/* <div>
                        <Link to='/cart' className="cart">
                            <Cart count={Object.keys(cart.cartItems).length} />
                            <span style={{ margin: "0 10px" }}>Cart</span>
                        </Link>
                    </div> */}
                </div>
                {/* right side menu ends here */}
            </div>
        </div>
    );
};

export default withRouter(Header);