import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PincodeData from '../utils/PincodeData';
import { IoIosCall } from 'react-icons/io'
import './ProfessionalList.css'

const ProfessionalItem = ({
    professional, showProfessionalServiceDetailsModal
}) => {
    return (
        <div className='professional-card'>
            <div className='card-header'>
                {/* <img src={''} alt='' className='round-img' /> */}
                <h2>{professional.name}</h2>
            </div>
            {/* <div className='text-lg'>
                {professional.sector} Sector
            </div> */}
            {/* <Link to={`/professionals/${professional._id}`} className="btn btn-primary">
                Discussion{' '}
            </Link> */}
            {/* <p className='my-1'>{location && <span>{location}</span>}</p> */}
            <div className='card-body'>
                <ul>
                    {/* {skills.slice(0, 4).map((skill, index) => (
                    <li key={index} className='text-primary'> */}
                    <li className='professional-text-primary'>
                        {/* <i className='fas fa-check' /> */}
                        {professional.service}{' '} 
                        {
                            professional.choiceOfService !== null ? <span> - {professional.choiceOfService.name} </span> : null
                        }
                    </li>
                    {/* ))} */}
                </ul>

                <PincodeData
                    poName={professional.address.pincodeData.poName}
                    cityBlock={professional.address.pincodeData.cityBlock}
                    district={professional.address.pincodeData.district}
                    stateName={professional.address.pincodeData.stateName}
                    pincode={professional.address.pincodeData.pincode}
                />

                <div className='action-buttons'>
                    <Link to={`/profile/${professional.userId}`} className='professional-btn view-profile-btn'>
                        View Profile
                    </Link>
                    <a href={`tel:/${professional.mobile}`} className="professional-btn call-btn"> <IoIosCall />
                        Call
                    </a>
                </div>
            </div>
            <button onClick={() => showProfessionalServiceDetailsModal(professional)} className='professional-btn info-btn'>
                Info
            </button>
            {/* Todo: */}
            {/* <button type="button" className="btn btn-danger"
                    onClick={() => {
                        dispatch(deleteServiceById(service._id));
                    }}
                >
                    Delete
                </button> */}
        </div>
    );
};

ProfessionalItem.propTypes = {
    professional: PropTypes.object.isRequired
};

export default ProfessionalItem;