import React from 'react';
import { Table } from 'antd';

const RegisterProfessional = (props) => {
  const { serviceName, choiceOfService, price, durationInMonths, subscriptionType } = props;

  const columns = [
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: 'Per month',
      dataIndex: 'perMonth',
      key: 'perMonth',
      render: (text, record) => (subscriptionType !== 'Free Trial' ? text : 'Free Trial'),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (text, record) => (subscriptionType === 'Free Trial' ? 'Free Trial' : text),
    },
    {
      title: 'Registration Fees',
      dataIndex: 'registrationFees',
      key: 'registrationFees',
    },
  ];

  const dataSource = [
    {
      key: '1',
      service: Object.keys(choiceOfService).length === 0 ? serviceName : choiceOfService.name,
      perMonth: price / durationInMonths,
      duration: `${durationInMonths} months`,
      registrationFees: price,
    },
  ];

  const totalRegistrationFees = (
    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', marginTop: '1rem', marginRight:'6em', marginLeft:"1em"}}>
      <span>Total Registration Fees</span>
      <span>{price}</span>
    </div>
  );

  return (
    <div>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
      {totalRegistrationFees}
    </div>
  );
};

export default RegisterProfessional;