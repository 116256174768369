import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import Spinner from './Spinner';


const PhonePeLoading = () => {

    const phonepe = useSelector((state) => state.phonepe)

    useEffect(() => {
        if (phonepe.res?.success === true) {
            window.location.href = phonepe.res.data.instrumentResponse.redirectInfo.url
        }
    }, [phonepe?.res]);

    return (
        <div className='w-full h-full flex flex-col item-center justify-center'>
            <Spinner />
            <p className='text-center'>Redirecting...</p>
        </div>

    )
}

export default PhonePeLoading