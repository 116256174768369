import React from "react";
import { InvoiceProfessionalPdfFooter } from "../../workerCategory/AddWorkerCallback";
import { checkAdminRole } from "../../../utils/checkRole";

export function DownLoadProfInvoice(prof) {
    return prof?.paymentStatus === "success" ?
        <InvoiceProfessionalPdfFooter prof={prof} />
        : null
}

export function checkToShowAddProfessionalDetailsButton(professional, auth) {
    return (professional?.servicePropertyFields
        || professional?.choiceOfService?.properties) &&
        (auth?.user?._id === professional.userId ||  checkAdminRole(auth?.user?.role));
}