import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import JobItem from './JobItem';
import JobActions from './JobActions';
import { getJobs } from '../../actions/job';
import LocationBasedFiltering from '../utils/LocationBasedFiltering';
import { checkForLocationFilter } from '../../utils/locationFilter';

const Jobs = ({ getJobs, job: { jobs, loading } }) => {

  const auth = useSelector((state) => state.auth);

  const [stateName, setStateName] = useState('')
  const [district, setDistrict] = useState('');
  const [pincode, setPincode] = useState('');
  const [postOffice, setPostOffice] = useState({})
  const [sectorId, setSectorId] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [displayMyJobs, setDisplayMyJobs] = useState(false);
  const [locationFilter, setLocationFilter] = useState('')

  useEffect(() => {
    getJobs();
  }, [])

  useEffect(() => {
    if (auth.isAuthenticated) {
      checkForLocationFilter(locationFilter, setDistrict, setStateName, setPostOffice, setPincode);
      const queryParams = setQueryParams(serviceId, pincode, postOffice, district, stateName);
      getJobs(queryParams)
    }
  }, [pincode, postOffice, district, stateName]);

  return (
    <Fragment>
      {/* {loading ? (
        <Spinner />
      ) : ( */}
      <Fragment>
        <p className='lead'>
          <i className='fab fa-connectdevelop' /> Welcome in search of Jobs
        </p>
        <div className="my-2">
          <button
            onClick={() => setDisplayMyJobs(!displayMyJobs)}
            type="button"
            className="btn btn-light"
          >
            Created Job Openings
          </button>
        </div>
        <div className='bg-light p-1 my-1'>
          {
            LocationBasedFiltering(postOffice, setPostOffice, setDistrict,
              setStateName, setPincode, locationFilter, setLocationFilter)
          }
        </div>
        <div className='jobs flex flex-wrap gap-12px row-gap-16px justify-between'>
          {
            jobs.length > 0 ? (
              jobs.map(job => (
                displayMyJobs ? (
                  job.createdBy._id === auth.user._id &&
                  <JobItem key={job._id} job={job} />
                ) : (
                  <JobItem key={job._id} job={job} />
                )
              ))
            ) : (
              <p style={{ color: 'darkgoldenrod' }}>
                No Jobs found in the area you are searching for. We are working on adding more jobs
                in your required area. Please try again after some days.
              </p>
            )
          }
        </div>
      </Fragment>
      {/* )}  */}
    </Fragment>
  );
}

Jobs.propTypes = {
  getJobs: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  job: state.job
});

export default connect(mapStateToProps, { getJobs })(Jobs);

function setQueryParams(serviceId, pincode, postOffice, district, stateName) {
  return {
    serviceId,
    pincode: pincode,
    poName: postOffice?.Name,
    district,
    stateName
  };
}

