import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import JobItem from '../jobs/JobItem'
import CommentForm from '../job/CommentForm'
import CommentItem from '../job/CommentItem'
import { getJob, getJobStatus } from '../../actions/job';
import { PDFViewer } from '@react-pdf/renderer';
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import AddJobPdf from '../pdf/invoicePDF/AddJobPdf';
import { DownLoadJobInvoice } from '../utils/jobs/Jobs';

export const InvoiceAddJobFooter = ({ job }) => {
  return (
    <button
      onClick={async () => {
        const doc = <AddJobPdf job={job} />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, "invoice.pdf");
      }}
    >
      Download Invoice
    </button>
  );
};

const Job = ({ getJobStatus, job: { job, loading }, match }) => {
  console.log(match.params.id);
  useEffect(() => {
    getJobStatus('', '', match.params.id);
  }, [getJobStatus]);
  return loading || job == null ? <Spinner /> :
    (<Fragment>
      <Link to='/jobs' className='btn'>
        Back to Jobs
      </Link>
      <JobItem job={job[0]} showActions={false} />
      <p className='text-primary'> Payment Status: {job[0]?.paymentStatus}</p>
      {
        DownLoadJobInvoice(job[0])
      }
      {/* <CommentForm jobId={job._id} />
    <div className="comments">
        {job.comments.map(comment => (
          <CommentItem key={comment._id} comment={comment} jobId={job._id} />
        ))}
      </div> */}
    </Fragment>
    );
};

Job.propTypes = {
  getJobStatus: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  job: state.job
})

export default connect(mapStateToProps, { getJobStatus })(Job);
