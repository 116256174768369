import { setAlert } from './alert';
import { REGISTER_SUCCESS, REGISTER_FAIL, USER_LOADED, AUTH_ERROR,
LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, CLEAR_PROFILE, LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE, USER_LOAD_REQUEST } from './types';
import { CartConstants } from './types';
import setAuthToken from '../utils/setAuthToken';
import backendAxios from '../utils/backendAxios';

export const loadUser = () => async dispatch => {
    setAuthToken(localStorage.token);
    try {
        dispatch({ type: USER_LOAD_REQUEST });
        const res = await backendAxios.get('/api/auth');

        dispatch({
            type: USER_LOADED,
            payload: res.data
        })
    } catch (error) {
        dispatch({
            type: AUTH_ERROR
        })
    }
}
// Register User
export const register = (newUser) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(newUser);

    try {
        const res = await backendAxios.post('/api/users', body, config);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });

        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors) {
            console.error("error from backend");
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: REGISTER_FAIL
        });
    }
}

// Login User
export const login = (mobile) => async dispatch => {
    console.log(mobile)
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ mobile });

    try {
        console.log("calling Auth API for user login")
        const res = await backendAxios.post('/api/auth', body, config);
        console.log("auth login action", res.data)
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });     

        dispatch(loadUser());
    } catch (err) {
        console.log(err)
        const errors = err.response.data.errors;

        if(errors) {
            console.error("error from backend");
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch(setAlert('Register First','danger'));
        dispatch({
            type: LOGIN_FAIL
        });
    }
}

//Logout /Clear Profile
export const logout = () => async dispatch => {
    try {
        dispatch({ type: LOGOUT_REQUEST })
        console.log("calling api/auth/logout")
        const res = await backendAxios.post('/api/auth/logout');
        dispatch({ type: CLEAR_PROFILE })
        console.log("clearing local storage after dispatching LOGOUT_SUCCESS action")
        dispatch({ type: LOGOUT_SUCCESS });
        dispatch({ type: CartConstants.RESET_CART })
    } catch (err) {
        console.error("error from backend while logging out");
        dispatch({ 
            type: LOGOUT_FAILURE,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}