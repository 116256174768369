import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});

const InvoiceTableFooter = ({ job: { amountForEnrollment, address } }) => {
    // const total = items.map(item => item.qty * item.rate)
    //     .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    return (
        <>
            <View style={styles.row}>
                <Text style={styles.description}>TOTAL</Text>
                <Text style={styles.total}>{amountForEnrollment}</Text>
            </View >
            {
                address.pincodeData.stateName === 'Himachal Pradesh' ? (
                    <>
                        <View style={styles.row}>
                            <Text style={styles.description}>SGST: 9%</Text>
                            <Text style={styles.total}>{((amountForEnrollment - (amountForEnrollment / 1.18)) / 2).toFixed(2)}</Text>
                        </View >
                        <View style={styles.row}>
                            <Text style={styles.description}>CGST: 9%</Text>
                            <Text style={styles.total}>{((amountForEnrollment - (amountForEnrollment / 1.18)) / 2).toFixed(2)}</Text>
                        </View >
                    </>
                ) : (
                    <View style={styles.row}>
                        <Text style={styles.description}>IGST 18% </Text>
                        <Text style={styles.total}>{(amountForEnrollment - (amountForEnrollment / 1.18)).toFixed(2)}</Text>
                    </View >

                )
            }
        </>
    )
};

export const InvoiceTableAddProfessionalFooter = ({ prof: { amountForEnrollment, address } }) => {
    return (
        <>
            <View style={styles.row}>
                <Text style={styles.description}>TOTAL</Text>
                <Text style={styles.total}>{amountForEnrollment}</Text>
            </View >
            {
                address.pincodeData.stateName === 'Himachal Pradesh' ? (
                    <>
                        <View style={styles.row}>
                            <Text style={styles.description}>SGST: 9%</Text>
                            <Text style={styles.total}>{((amountForEnrollment - (amountForEnrollment / 1.18)) / 2).toFixed(2)}</Text>
                        </View >
                        <View style={styles.row}>
                            <Text style={styles.description}>CGST: 9%</Text>
                            <Text style={styles.total}>{((amountForEnrollment - (amountForEnrollment / 1.18)) / 2).toFixed(2)}</Text>
                        </View >
                    </>
                ) : (
                    <View style={styles.row}>
                        <Text style={styles.description}>IGST 18% </Text>
                        <Text style={styles.total}>{(amountForEnrollment - (amountForEnrollment / 1.18)).toFixed(2)}</Text>
                    </View >

                )
            }
        </>
    )
};

export default InvoiceTableFooter;