export const ELECTRICIAN = "Electrician"
export const PLUMBER = "Plumber"
export const RO_PURIFIER = "RO Purifier Services"
export const AIR_CONDITIONER = "Air Conditioning Services"
export const REFRIGERATOR = "Refrigeration Services"
export const CCTV_CAMERAS = "CCTV services"
export const TELEVISION = "TV/LCD/LED Mechanic"
export const WASHING_MACHINE = "Washing Machine Services"
export const CHIMNEY = "Chimney Services"
export const GEYSER = "Geyser Services"
export const GAS_STOVE = "Gas Stove Repair and Services"
export const DISHWASHER = "Dishwasher"
export const MICROWAVE = "Microwave Repair"
export const PRINTER_AND_INK_REFILL = "Printer and Ink Refill"
export const PHOTOSTAT = "Photostat"
export const WIFI = "Wifi Setup or WLAN"

export const PROPERTY_RENTAL = "Property Rental"
export const PAYING_GUEST = "PG"
export const SINGLE_ROOM = "Single Room"
export const FLAT_HOMES = "Apartment Flat / Home"
export const COMMERCIAL_LEASE = "Commercial Lease"
export const RETAIL_SHOPS = "Retail Space (Shop)"

export const TAXIS = "Taxis for Passengers"
export const TAXI_MINI_CAR = "Mini Car"
export const TAXI_SEDAN_CAR = "Sedan Car"
export const TAXI_XUV_CAR = "XUVs"
export const TAXI_WINGER_VAN = "Winger Van"
export const TAXI_TRAVELLER_VAN = "Traveller Van"
export const TAXI_VAN_EECO_VAN = "Van / Eeco Van"
export const PICK_UP_VANS = "Pick Up Vans"
export const PICK_UP_TRANSPORTATION_JEEP = "Transportation Jeep / NC"
export const PICK_UP_THREE_WHEELER_AUTO = "3 Wheeler Auto"
export const PICK_UP_FOUR_WHEELER_AUTO = "4 Wheeler Auto"
export const PICK_UP_TRACTOR = "Tractor"
export const BUSES = "Buses"
export const VOLVO_BUS = "AC Volvo Bus"
export const WATER_TANKER  = "Water Tanker Services"
export const TRUCKS = "Trucks"
export const TOW_TRUCK = "Tow truck (Cranes)"

export const ARCHITECTS = "Architects"
export const RAJ_MISTRI = "Construction worker / House Raj Mistri"
export const TILE_INSTALLER = "Tile installers / Tile Raj Mistri"
export const MARBLE_INSTALLER = "Marbler / Marble Mistri"
export const PAINTER= "Painters"
export const POLISHING_SERVICE = "Polishing Service"
export const CARPENTER = "Carpenter"
export const WELDER_METAL_FABRICATOR = "Welder / Metal Fabricator"
export const MODULAR_KITCHEN = "Modular Kitchen and Wardrobe"
export const WOODEN_DOOR_FITTINGS = "Wooden Door Fittings"
export const PVC_PANELLING = "PVC Panelling"
export const GYPSUM_CEILING = "Gypsum Ceiling"
export const WALLPAPER_INSTALLATION = "Wallpaper Installation Services"
export const GLASS_FITTINGS = "Glass Fitting Services"
export const UPVC_ALUMINIUM_DOOR_WINDOW = "UPVC and Aluminium Door Window"
export const MACHINERY_FOR_CONSTRUCTION = "Machinery for construction"
export const JCB_BACKHOE = "JCB / Backhoe"
export const CONCRETE_MIXER = "Concrete Mixture"
export const SHUTTERING = "Shuttering"
export const HOUSE_CONTRACTOR = "House Contractor"
export const STONE_BREAKER = "Stone Breaker"
export const STONE_MAKER = "Stone Maker"
export const WATER_PROOFING = "Water Proofing"

export const EVENT_VENUES_OR_EVENT_SPACES = "Event Venues or Event Spaces"
export const PARTY_HALL = "Party Hall"
export const MARRIAGE_BANQUET_HALL = "Marriage Palace / Banquet Hall"
export const COOK = "Cook"
export const HIMACHALI_DHAM = "Himachali Dham"
export const GREEN_LEAF_PLATES = "Green Leaf Plates"
export const EVENT_CATERERS_AND_FOOD_SERVICES = "Event Caterers And Food Services"
export const CATERING = "Catering Services"
export const TENT = "Tent"
export const MUSICAL_BANDS = "Musical Bands"
export const BAND_BAJA = "Band Baja"
export const DHOL = "Dhol"
export const DJ_SOUND_SYSTEM = "DJ and Sound System"
export const EVENT_TRANSPORTATION_AND_LOGISTICS= "Event Transportation and Logistics"
export const GROOMS_CAR = "Groom's Car"
export const PHOTOGRAPHER = "Photographer"
export const FLEX_PRINTING_SERVICES = "Flex Printing Services"

export const DANCE_STUDIOS = "Dance Studios"
export const SWIMMING_POOLS = "Swimming Pools"
export const YOGA_STUDIOS = "Yoga Studios"
export const GYMS = "Gyms"
export const PILATES_STUDIOS = "Pilates Studios"
export const DANCE_CLUBS = "Dance Clubs"
export const SPA_CENTERS = "Spa Centers"
export const BARS = "Bars"

export const SOFA_CARPET_CLEANING = "Sofa and Carpet Cleaning"
export const WATER_TANK_CLEANING = "Water Tank Cleaning"
export const HOME_CLEANING = "Home Cleaning"

export const CA_CHARTERED_ACCOUNTANT = "CA (Chartered Accountant)"
export const ADVOCATES= "Advocates"

export const RENTAL_BROKERS = "Rental Brokers"
export const PROPERTY_DEALERS = "Property Dealers"

export const CAFES = "Cafes"
export const RESTAURANTS = "Restaurants"
export const BAKERS = "Bakers"

export const HOME_STAYS = "HomeStays"
export const HOTELS = "Hotels"


