import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Grid } from "@mui/material";

export function selectChoice(choices, choiceOfService, setChoiceOfService, serviceDetails) {
    return (
        <>
            {
                choices?.length > 0 &&
                // <select
                //     className='btn btn-light'
                //     onChange={(e) => setChoiceOfService(JSON.parse(e.target.value))}>
                //     <option key='all' value={JSON.stringify({})}>Choice of {serviceDetails.type}</option>
                //     {choices.map(ch => <option key={ch._id} value={JSON.stringify(ch)}>{ch.name}</option>)}
                // </select>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={choices}
                        getOptionLabel={(option) => option?.name}
                        value={choices.find((option) => option._id === choiceOfService?._id) || null}
                        onChange={(event, ch) => setChoiceOfService(ch || {})}
                        renderInput={(params) => (
                            <TextField {...params} label={`Choice of ${serviceDetails.type}`} variant="outlined" />
                        )}
                    />
                </Grid>
            }
        </>
    );
}

export function selectChoiceOfJobs(choices, setChoiceOfJob, jobCategory) {
    return choices?.length > 0 &&
        <select
            className='btn btn-light'
            // className="form-control"
            onChange={(e) => setChoiceOfJob(JSON.parse(e.target.value))}>
            <option key='all' value={JSON.stringify({})}>Choice of {jobCategory.jobCategoryName}</option>
            {choices.map(ch => <option key={ch._id} value={JSON.stringify(ch)}>{ch.name}</option>)}
        </select>;
}