import { AIR_CONDITIONER, ARCHITECTS, BAKERS, BAND_BAJA, CAFES, CARPENTER, CATERING, CA_CHARTERED_ACCOUNTANT, CCTV_CAMERAS, CHIMNEY, CONCRETE_MIXER, DHOL, DISHWASHER, DJ_SOUND_SYSTEM, ELECTRICIAN, FLAT_HOMES, FLEX_PRINTING_SERVICES, GAS_STOVE, GEYSER, GREEN_LEAF_PLATES, GROOMS_CAR, HIMACHALI_DHAM, HOME_STAYS, HOTELS, HOUSE_CONTRACTOR, JCB_BACKHOE, MARBLE_INSTALLER, MARRIAGE_BANQUET_HALL, MICROWAVE, MODULAR_KITCHEN, PAINTER, PARTY_HALL, PAYING_GUEST, 
    PHOTOGRAPHER, 
    PHOTOSTAT, PICK_UP_VANS, PLUMBER, PRINTER_AND_INK_REFILL, PROPERTY_DEALERS, RAJ_MISTRI, REFRIGERATOR, RENTAL_BROKERS, RESTAURANTS, RETAIL_SHOPS, RO_PURIFIER, SHUTTERING, SINGLE_ROOM, TAXIS, 
    TELEVISION, 
    TENT, 
    TILE_INSTALLER, UPVC_ALUMINIUM_DOOR_WINDOW, WASHING_MACHINE, WELDER_METAL_FABRICATOR } from "../constants/DB/ServiceNames";

const serviceLocations = [
    {
        "serviceName": ELECTRICIAN,
        "locations": [
            "Dharmshala"
            // "Chandigarh"
        ]
    },
    {
        "serviceName": PLUMBER,
        "locations": [
            "Dharmshala",
            // "Chandigarh",
            // "Kharar",
            "Shahpur"
        ]
    },
    {
        "serviceName": RO_PURIFIER,
        "locations": [
            "Dharmshala",
            "Kangra",
            "Palampur"
        ]
    },
    {
        "serviceName": AIR_CONDITIONER,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": REFRIGERATOR,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": CCTV_CAMERAS,
        "locations": [
            "Dharmshala",
            "Kangra"
        ]
    },
    {
        "serviceName": TELEVISION,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra"
        ]
    },
    {
        "serviceName": WASHING_MACHINE,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": CHIMNEY,
        "locations": [
            "Dharmshala",
            "Kangra"
        ]
    },
    {
        "serviceName": GEYSER,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": GAS_STOVE,
        "locations": [
            "Shahpur"
        ]
    },
    {
        "serviceName": DISHWASHER,
        "locations": [
            "Dharmshala",
            "Kangra"
        ]
    },
    {
        "serviceName": MICROWAVE,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": PRINTER_AND_INK_REFILL,
        "locations": [
            "Dharmshala",
            "Palampur",
            "Kangra"
        ]
    },
    {
        "serviceName": PHOTOSTAT,
        "locations": [
            "Dharmshala",
            "Palampur",
            "Kangra"
        ]
    },
    {
        "serviceName": PAYING_GUEST,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": SINGLE_ROOM,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": FLAT_HOMES,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": RETAIL_SHOPS,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": TAXIS,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Hamirpur",
            "Gaggal Airport"
        ]
    },
    {
        "serviceName": PICK_UP_VANS,
        "locations": [
            "Shahpur",
            "Dramman"
        ]
    },
    {
        "serviceName": ARCHITECTS,
        "locations": [
            "Dharmshala",
            "Shimla"
        ]
    },
    {
        "serviceName": RAJ_MISTRI,
        "locations": [
            "Shahpur"
        ]
    },
    {
        "serviceName": TILE_INSTALLER,
        "locations": [
            "Shahpur"
        ]
    },
    {
        "serviceName": MARBLE_INSTALLER,
        "locations": [
            "Shahpur"
        ]
    },
    {
        "serviceName": PAINTER,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": CARPENTER,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": WELDER_METAL_FABRICATOR,
        "locations": [
            "Shahpur"
        ]
    },
    {
        "serviceName": MODULAR_KITCHEN,
        "locations": [
            "Shahpur"
        ]
    },
    {
        "serviceName": UPVC_ALUMINIUM_DOOR_WINDOW,
        "locations": [
            "Dharmshala",
            "Mandi"
        ]
    },
    {
        "serviceName": JCB_BACKHOE,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": CONCRETE_MIXER,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": SHUTTERING,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": HOUSE_CONTRACTOR,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": PARTY_HALL,
        "locations": [
            "Shahpur"
        ]
    },
    {
        "serviceName": MARRIAGE_BANQUET_HALL,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": HIMACHALI_DHAM,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": GREEN_LEAF_PLATES,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": CATERING,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": PHOTOGRAPHER,
        "locations": [
            "Dharmshala",
            "Palampur"
        ]
    },
    {
        "serviceName": TENT,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": BAND_BAJA,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": DHOL,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": DJ_SOUND_SYSTEM,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": GROOMS_CAR,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
    },
    {
        "serviceName": FLEX_PRINTING_SERVICES,
        "locations": [
            "Dharmshala"
        ]
    },
    {
        "serviceName": CA_CHARTERED_ACCOUNTANT,
        "locations": [
            "PAN_INDIA"
        ]
    },
    {
        "serviceName": HOME_STAYS,
        "locations": [
            "Jibhi"
        ]
    },
    {
        "serviceName": HOTELS,
        "locations": [
            "Dharmshala"
        ]
    },
    {
        "serviceName": CAFES,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Bir",
            "Tosh Valley (Kasol)"
        ]
      },
      {
        "serviceName": RESTAURANTS,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ]
      },
      {
        "serviceName": BAKERS,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Tosh Valley (Kasol)"
        ]
      },
      {
        "serviceName": RENTAL_BROKERS,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Chandigarh",
            "Kharar",
            "Mohali",
            "Zirakpur",
            "Panchkula"
        ]
      },
      {
        "serviceName": PROPERTY_DEALERS,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Chandigarh",
            "Kharar",
            "Mohali",
            "Zirakpur",
            "Panchkula"
        ]
      }
]

export const getLocationByServiceNameWeServe= (serviceName) => {
    const matchingService = serviceLocations.find(service => service.serviceName === serviceName);

    // If the matching service is found, return its locations; otherwise, return an empty array
    return matchingService ? matchingService.locations : [];
};