import { PhonePeConstants } from "../types";
import backendAxios from '../../utils/backendAxios';

// Make payment (Primary API call)
export const payPhonePe = (paymentObj) => async dispatch => {
    try {
        dispatch({
            type: PhonePeConstants.PHONEPE_REQUEST
        });
        const res = await backendAxios.post('/api/payments/phonepe/pay', paymentObj, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }});
        dispatch({
            type: PhonePeConstants.PHONEPE_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        console.log(err)
        dispatch({
            type: PhonePeConstants.PHONEPE_FAILURE,
            payload: err
        });
    }
};

export const paymentStatusPhonePe = (paymentStatusObj) => async dispatch => {
    try {
        console.log(paymentStatusObj)
        dispatch({
            type: PhonePeConstants.PHONEPE_CHECK_STATUS_REQUEST
        });
        setTimeout(() => 30000);
        await backendAxios.get('/api/payments/phonepe/checkstatus', paymentStatusObj);
        dispatch({
            type: PhonePeConstants.PHONEPE_CHECK_STATUS_SUCCESS
        });
    } catch (err) {
        console.log(err)
        dispatch({
            type: PhonePeConstants.PHONEPE_CHECK_STATUS_FAILURE
        });
    }
}

// Make payment
export const paymentPhonePe = (payment) => async dispatch => {
    try {
        const res = await backendAxios.post('/api/payments/phonepe/initiate', payment);
        // const config = {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'X-VERIFY': 'AC9F77CE4003256ADB680735AE01CD2063BFBB5772EFC521DA60D0E89E0DE599###1'
        //     }
        // }
        // const body = JSON.stringify({
        //     request:
        //         "ewoibWVyY2hhbnRJZCI6IlBHVEVTVFBBWVVBVCIsCiJhbW91bnQiOjEwMDAwMDAwLAoidmFsaWRGb3IiOjkwMDAwMCwKInRyYW5zYWN0aW9uSWQiOiJUMTJSVFJUQkpHR1RHSk5ZR1giLAoibWVyY2hhbnRPcmRlcklkIjoiVDEyUlRSVEJKR0dUR0pOWUdYIiwKICJyZWRpcmVjdFVybCI6Imh0dHBzOi8vd3d3LmdlZWtzZm9yZ2Vla3Mub3JnL3NoYS0yNTYtaGFzaC1pbi1qYXZhLyIsCiAidHJhbnNhY3Rpb25Db250ZXh0IjogImV3b0pJbTl5WkdWeVEyOXVkR1Y0ZENJNklIc0tDUWtpZEhKaFkydHBibWRKYm1adklqb2dld29KQ1FraWRIbHdaU0k2SUNKSVZGUlFVeUlzQ2drSkNTSjFjbXdpT2lBaWFIUjBjSE02THk5bmIyOW5iR1V1WTI5dElnb0pDWDBLQ1gwc0Nna2labUZ5WlVSbGRHRnBiSE1pT2lCN0Nna0pJblJ2ZEdGc1FXMXZkVzUwSWpvZ016a3dNQ3dLQ1FraWNHRjVZV0pzWlVGdGIzVnVkQ0k2SURNNU1EQUtDWDBzQ2draVkyRnlkRVJsZEdGcGJITWlPaUI3Q2drSkltTmhjblJKZEdWdGN5STZJRnQ3Q2drSkNTSmpZWFJsWjI5eWVTSTZJQ0pUU0U5UVVFbE9SeUlzQ2drSkNTSnBkR1Z0U1dRaU9pQWlNVEl6TkRVMk56ZzVNQ0lzQ2drSkNTSndjbWxqWlNJNklETTVNREFzQ2drSkNTSnBkR1Z0VG1GdFpTSTZJQ0pVUlZOVUlpd0tDUWtKSW5GMVlXNTBhWFI1SWpvZ01Rb0pDWDFkQ2dsOUNuMD0iCn0="
        // })
        // const res = await backendAxios.post('https://apps-uat.phonepe.com/v3/transaction/sdk-less/initiate')
        //     .then(res => console.log(res.data))
        //     .catch(err => console.log(err))
        // dispatch({
        //   type: GET_JOBS,
        //   payload: res.data
        // });
        console.log(res)
    } catch (err) {
        console.log(err)
        // dispatch({
        //   type: JOB_ERROR,
        //   payload: { msg: err.response.statusText, status: err.response.status }
        // });
    }
};

// Charge payment
export const chargePhonePe = (payment) => async dispatch => {
    try {
        dispatch({
            type: PhonePeConstants.PHONEPE_REQUEST
        });
        const res = await backendAxios.post('/api/payments/phonepe/charge', payment);
        dispatch({
            type: PhonePeConstants.PHONEPE_SUCCESS,
            payload: res.data
        });
        console.log(res)
    } catch (err) {
        console.log(err)
        dispatch({
            type: PhonePeConstants.PHONEPE_FAILURE,
            payload: err
        });
    }
};