import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import formatDate from '../../utils/formatDate';
import { applyJob, unapplyJob, deleteJob } from '../../actions/job';
import PincodeData from '../utils/PincodeData'
import { IoIosTrash } from 'react-icons/io'

const JobItem = ({
  applyJob,
  unapplyJob,
  deleteJob,
  auth,
  job: {
    _id,
    description,
    createdBy,
    reachablePersonName,
    firmName,
    address: {
      pincodeData
    },
    jobCategoryId: {
      jobCategoryName
    },
    applies,
    status
  },
  showActions
}) => (
  <div className='job bg-light p-1 my-1 flex flex-col bg-light p-1 my-1 justify-between rounded-4'>
    <div>
      {/* Profile link who created this job */}
      {/* <Link to={`/profile/${createdBy}`}> */}
      {/* <img className="round-img" src={avatar} alt="" /> */}
      {/* <h4>Contact: {reachablePersonName}</h4> */}
      <h5>Employer: {firmName}</h5>
      {/* </Link> */}
      <p className="my-1"> Type of job: {jobCategoryName}</p>
      <p style={{ "marginBottom": "0.3rem" }} className="post-date">
        {
          status.type === 'active' ? 'Posted' : 'Applied'
        } on {formatDate(status.date)}</p>
      <div className="text-sm">
            <p>
              {description ? <div> <strong>Description: </strong> {description} </div> : null}
            </p>
      </div>

      <PincodeData 
        poName = {pincodeData.poName} 
        cityBlock = {pincodeData.cityBlock} 
        district = {pincodeData.district}
        stateName = {pincodeData.stateName}
        pincode = {pincodeData.pincode}
      />

      {showActions && <Fragment style={"justify"}>
        {
          createdBy._id !== auth.user._id &&
          <>
            <div style={{ "marginBottom": "0.1rem" }} className='flex gap-12px height-30px'>
              <button
                onClick={() => applyJob(_id)}
                type="button"
                className="btn sub-btn width-50 margin-0"
              >
                {/* <i className="fas fa-thumbs-up" />{' '} */}
                Apply {' '}
                <span>{applies.length > 0 && <span>{applies.length}</span>}</span>
              </button>

              <button
                onClick={() => unapplyJob(_id)}
                type="button"
                className="btn sub-btn width-50 margin-0"
              >
                UnApply
                {/* <i className="fas fa-thumbs-down" /> */}
              </button>
            </div>
          </>
        }
        {/* <Link to={`/jobs/${_id}`} className="btn btn-primary">
          Discussion{' '} */}
        {/* {comments.length > 0 && (
              <span className="comment-count">{comments.length}</span>
            )} */}
        {/* </Link> */}
        {!auth.loading && createdBy._id === auth.user._id && (<>
          <button
            onClick={() => deleteJob(_id)}
            type="button"
            className="btn btn-danger width-50 margin-0"
          >
            <IoIosTrash />
            <span>Delete</span>
            {/* <i className="fas fa-times" /> */}
          </button>
          {/* <Applicants applies={applies}/> */}
          <Link to={{
            pathname: '/applicants',
            aboutProps: { applies }
          }} className="btn sub-btn width-50 margin-0">
            <i className='fas fa-graduation-cap text-primary' />  View Applicants
          </Link>
        </>
        )}

      </Fragment>}

    </div>
  </div>
);

JobItem.defaultProps = {
  showActions: true
}

JobItem.propTypes = {
  job: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  applyJob: PropTypes.func.isRequired,
  unapplyJob: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { applyJob, unapplyJob, deleteJob })(JobItem);