import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import job from './job';
import category from './category';
import product from './product';
import order from './order';
import page from './page'
import cart from './cart'
import address from './address'
import sector from './sector'
import service from './service'
import professional from './workerCategory'
import subscription from './subscription'
import jobSector from './jobSector'
import jobCategory from './jobCategory'
import jobSubscription from './jobSubscription'
import states from './states'
import districts from './districts'
import phonepe from './Payments/phonepe'
import user from './user'

export default combineReducers({
    alert,
    auth,
    profile,
    job,
    category,
    product,
    order,
    page,
    cart,
    address,
    sector,
    service,
    professional,
    subscription,
    jobSector,
    jobCategory,
    jobSubscription,
    states,
    districts,
    phonepe,
    user
});