import { StateConstants, DistrictConstants } from './types';
import backendAxios from '../utils/backendAxios';

export const getStates = () => {
    return async dispatch => {
        try {
            dispatch({ type: StateConstants.GET_STATES_REQUEST });
            const res = await backendAxios.get('/api/state');
            if (res.status === 200) {
                const { states } = res.data;
                dispatch({
                    type: StateConstants.GET_STATES_SUCCESS,
                    payload: { states }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: StateConstants.GET_STATES_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: StateConstants.GET_STATES_FAILURE,
                payload: { error },
            });
        }
    }
}

export const getDistricts = (state) => {
    return async dispatch => {
        try {
            dispatch({ type: DistrictConstants.GET_DISTRICTS_REQUEST });
            const res = await backendAxios.post('/api/district/bystate', {state});
            if (res.status === 200) {
                const { districts } = res.data;
                dispatch({
                    type: DistrictConstants.GET_DISTRICTS_SUCCESS,
                    payload: { districts }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: DistrictConstants.GET_DISTRICTS_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: DistrictConstants.GET_DISTRICTS_FAILURE,
                payload: { error },
            });
        }
    }
}

