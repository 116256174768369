// Function to check admin salesPerson role
const checkAdminSalesPersonRole = (role) => {
   return role === "admin" || role === "salesPerson"
};

const checkAdminRole = (role) => {
   return role === "admin"
};

const checkUserRole = (role) => {
   return role === "user"
}

export {checkAdminSalesPersonRole, checkAdminRole, checkUserRole}
