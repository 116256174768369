import Compressor from 'compressorjs';

export async function compressImages(files) {
    return await Promise.all(
      Array.from(files).map(async (file) => {
        return new Promise((resolve) => {
          new Compressor(file, {
            quality: 0.6, // Adjust the quality as needed (0.6 means 60%)
            success: (result) => {
              resolve(result);
            },
            error: (err) => {
              console.error('Compression error:', err);
              resolve(file);
            },
          });
        });
      })
    );
  }