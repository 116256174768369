import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Spinner from './Spinner';


const Loading = () => {

    const history = useHistory();
    const professional = useSelector((state) => state.professional);

    useEffect(() => {
        if(professional?.professional?.[0]?._id) {
            history.push(`/professionals/${professional?.professional?.[0]?._id}`);
        }
    }, [professional?.professional]);

    return (
        <div className='w-full h-full flex flex-col item-center justify-center'>
            <Spinner />
            <p className='text-center'>Redirecting...</p>
        </div>
    )
}

export default Loading