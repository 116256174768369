import React from 'react';

const AboutUs = () => {
  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>About Us</h3>
      <p style={styles.description}>
        1. Bharatpros is a distinguished service provider platform dedicated to delivering essential everyday services to our valued customers. Our specialized focus is on assisting individuals relocating to new destinations, offering comprehensive support in various aspects of their transition.
      </p>
      <p style={styles.description}>
        2. Our array of services begins with aiding clients in finding suitable accommodations, be it hotels or homestays, during their initial days in a new location. As the journey progresses, we seamlessly guide them through the process of securing rental spaces tailored to their preferences. Furthermore, we extend our expertise to facilitate essential electrical and water fittings, ensuring a smooth and hassle-free settlement.
      </p>
      <p style={styles.description}>
        3. Recognizing the multifaceted challenges associated with relocation, Bharatpros emerges as a one-stop solution for all needs. We alleviate the complexities of navigating through multiple applications for various requirements, streamlining the entire experience for our esteemed clientele. Trust Bharatpros to be your comprehensive partner in simplifying the intricacies of relocation and providing essential services for a seamless transition.
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  description: {
    marginBottom: '20px',
    fontSize: '16px',
  },
};

export default AboutUs;
