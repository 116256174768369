import { JobSubscriptionConstants } from "../actions/types";

const initialState = {
    jobSubscriptions: [],
    error: null,
    loading: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case JobSubscriptionConstants.GET_JOB_SUBSCRIPTIONS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case JobSubscriptionConstants.GET_JOB_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                jobSubscriptions: payload.jobSubscriptions,
                loading: false,
            };
        case JobSubscriptionConstants.GET_JOB_SUBSCRIPTIONS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload.error
            }
        default:
            return state;
    }
}