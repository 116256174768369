import {
  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
  JOB_ERROR,
  UPDATE_APPLIES,
  ADD_JOB,
  GET_JOB,
  GET_JOBS_BY_DISTRICT,
  ADD_COMMENT,
  REMOVE_COMMENT,
  JobConstants
} from '../actions/types';

const initialState = {
  jobs: [],
  job: null,
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_JOBS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: payload,
        loading: false
      };
    case GET_JOB:
      return {
        ...state,
        job: payload,
        loading: false
      };
    case GET_JOBS_BY_DISTRICT:
      return {
        ...state,
        jobs: payload,
        loading: false
      };
    case ADD_JOB:
      return {
        ...state,
        jobs: [payload, ...state.jobs],
        loading: false
      }
    case JobConstants.GET_JOB_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case JobConstants.GET_JOB_STATUS_SUCCESS:
      return {
        ...state,
        job: payload.job,
        loading: false
      };
    case JobConstants.GET_JOB_STATUS_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: payload.errors
      }
    case JobConstants.DELETE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.filter(job => job._id !== payload),
        loading: false
      }
    case ADD_COMMENT:
      return {
        ...state,
        job: { ...state.job, comments: payload },
        loading: false
      }
    case REMOVE_COMMENT:
      return {
        ...state,
        job: {
          ...state.job,
          comments: state.job.comments.filter(comment => comment._id !== payload)
        },
        loading: false
      }
    case JobConstants.DELETE_JOB_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      }
    case UPDATE_APPLIES:
      return {
        ...state,
        jobs: state.jobs.map(job =>
          job._id === payload.id ? { ...job, applies: payload.applies } : job
        ),
        loading: false
      }
    default:
      return state;
  }
}