import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { getJobStatus } from '../../actions/job';
import { InvoiceAddJobFooter } from '../job/Job';
import JobItem from './JobItem';
import { DownLoadJobInvoice } from '../utils/jobs/Jobs';

const MyJobs = () => {

    const job = useSelector((state) => state.job);

    const [jobStatus, setJobStatus] = useState(job.jobs);

    useEffect(() => {
        setJobStatus(job.job);
    }, [job.job]);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log("calling job status in MyJobs useEffect")
        dispatch(getJobStatus())
    }, [getJobStatus]);

    return (
        // <Fragment>

        //     {
        //         jobStatus?.length === 0 ? <p>You have not added any job yet</p> :
        //             (
        //                 <div className='job bg-light p-1 my-1'>
        //                     <h2 className="my-2">Jobs that you are hiring for</h2>
        //                     <table className="table">
        //                         <thead>
        //                             <tr>
        //                                 <th>#</th>
        //                                 <th>Type of Job</th>
        //                                 <th>Sector</th>
        //                                 <th>Choice of Job</th>
        //                                 <th>Payment Status</th>
        //                                 <th>Status</th>
        //                                 <th>Action</th>
        //                             </tr>
        //                         </thead>
        //                         <tbody>
        //                             {
        //                                 jobStatus.map(job =>
        //                                     <tr key={job._id}>
        //                                         <td>#</td>
        //                                         <td>{job.jobCategoryId.jobCategoryName}</td>
        //                                         <td>{job.jobSectorId.jobSector}</td>
        //                                         <td>{job.choiceOfJobId === '' ? '-' : job.choiceOfJobId}</td>
        //                                         <td>{job.paymentStatus}</td>
        //                                         <td>{job.status.type}</td>
        //                                         {
        //                                             job.paymentStatus === "success" ? 
        //                                             <InvoiceAddJobFooter job={job}/> : null
        //                                         }
        //                                     </tr>
        //                                 )
        //                             }
        //                         </tbody>
        //                     </table>
        //                 </div>
        //             )
        //     }
        // </Fragment>
        <Fragment>
            <div>
                {
                    job.job?.length > 0 ? (
                        job.job.map(job => (
                            <div className='job bg-light p-1 my-1'>
                                <JobItem key={job._id} job={job} />
                                <p style={{ "marginBottom": "0.5rem" }} className='text-primary'>Payment Status: {job.paymentStatus}</p>
                                <p style={{ "marginBottom": "0.5rem" }}>Job Status: {job.status.type}</p>
                                {/* We can uncomment this to give the Employers the option to get their
                                previous invoices for the jobs they have created
                                {
                                    DownLoadJobInvoice(job)
                                } */}
                            </div>
                        )
                        )
                    ) : (
                        <>
                            <h4>You have not added any job yet..</h4>
                            <Link to='/add-job' className='btn sub-btn rounded-4'>
                                <i className='fab fa-black-tie text-primary' /> Create Job Opening
                            </Link>
                        </>
                    )
                }
            </div>
        </Fragment>
    )
}

export default MyJobs