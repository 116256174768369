import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getServices,addService, getServicesBySector } from "../../actions/service";
import { getSectors } from "../../actions/sector";
import { Modal } from "antd";

const Services = () => {

  const sector = useSelector((state) => state.sector);
  const service = useSelector((state) => state.service);
  const auth = useSelector((state) => state.auth);
  const [services, setServices] = useState(service.services);
  const [sectors, setSectors] = useState(sector.sectors);

  const [sectorId, setSectorId] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [desc, setDesc] = useState('')
  const [addServiceModal, setaddServiceModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setServices(service.services);
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination: {
        ...prevParams.pagination,
        total: service.services.length, // Update the total count
      },
    }));
  }, [service.services]);

  useEffect(() => {
    setSectors(sector.sectors);
  }, [sector.sectors]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getSectors());
      dispatch(getServices());
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    dispatch(getServicesBySector(sectorId));
  }, [sectorId]);

  const showAddServiceModal = () => {
    setaddServiceModal(true);
  };



  const handleCloseAddServiceModal = () => {
    setaddServiceModal(false);
  };



  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: services.length, // Set the total count initially
    },
  });

  const addServiceForm = () => {
    const newService = {
      sectorId,
      type: serviceName,
      description: desc,
    };
    dispatch(addService(newService));
    setaddServiceModal(false);
  };

  const columns = [
    {
      title: "S.no",
      dataIndex: "key",
      sorter: true,
      render: (text, record, index) =>
        (tableParams.pagination.current - 1) * tableParams.pagination.pageSize +
        index +
        1,
      width: "20%",
    },
    {
      title: "Sector",
      dataIndex: "sectorName", // Assuming you have a 'sectorName' property in your service data
      width: "20%",
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (active) => active.toString(),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const renderAddServiceModal = () => (
    <div className=" flex justify-center">
      <button
        onClick={showAddServiceModal}
        className=" my-4 blue-gradient uppercase text-white rounded-full font-extrabold p-3 px-4 "
      >
        Add Service +
      </button>
      <Modal
        title="Add new Service"
        open={addServiceModal}
        onOk={addServiceForm}
        onCancel={handleCloseAddServiceModal}
      >
        <select
          className="form-control border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={sectorId}
          onChange={(e) => setSectorId(e.target.value)}
        >
          <option>select sector</option>
          {sectors.map((option) => (
            <option key={option._id} value={option._id}>
              {option.sector}
            </option>
          ))}
        </select>

        <input
          className="form-control border border-gray-300 rounded-md px-3 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          label="Name"
          value={serviceName}
          placeholder={`Service Name`}
          onChange={(e) => setServiceName(e.target.value)}
        />

        <input
          className="form-control border border-gray-300 rounded-md px-3 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          label="Description"
          value={desc}
          placeholder={`Description`}
          onChange={(e) => setDesc(e.target.value)}
        />
      </Modal>
    </div>
  );

  return (
    <div className="flex flex-col justify-center gap-3 my-4">
      <select
        className="form-control"
        value={sectorId}
        onChange={(e) => setSectorId(e.target.value)}
      >
        <option value={""}>All</option>
        {sectors.map((option) => (
          <option key={option._id} value={option._id}>
            {option.sector}
          </option>
        ))}
      </select>
      {
        renderAddServiceModal()
      }
      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={services.map((service, index) => ({
          key: index,
          sectorName: service.type, // Replace with the actual sector name
          active: service.active,
          createdBy: service.createdBy,
          _id: service._id,
        }))}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Services;
