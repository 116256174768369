
// Function to handle onChange event of the PhoneInput and add "+"
const getCompleteMobileNumberWithPlus = (mobile) => {
    // Check if the mobile starts with "+", if not, add "+"
    if (!mobile.startsWith('+')) {
        return ('+' + mobile);
    } else {
        return mobile;
    }
};

export default getCompleteMobileNumberWithPlus