import { generatePublicImgURL } from "../../../urlConfig";
import { ADMIN, BACKWARD_ARROW, FORWARD_ARROW, ICONS_AND_PHOTOS } from "../utils/constants/s3Constants";

export function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img 
            src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + FORWARD_ARROW)}
            alt="forwardArrow"
            className={className}
            style={{ ...style }}
            onClick={onClick}
        />
    );
}

export function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img 
            src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + BACKWARD_ARROW)}
            alt="backwardArrow"
            className={className}
            style={{ ...style, marginLeft: "-17px" }}
            onClick={onClick}
        />
    );
}