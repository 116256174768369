import React from 'react'
import { Link } from 'react-router-dom';

const NoMatch = () => {
    return (
        <div>
            <div style={{"font-size": "1.3em", "padding-top": "10px", "margin-bottom": "35px"}}>Unfortunately the page you are looking
                for has been moved or deleted</div>
            <Link to="/" className='btn sub-btn rounded-4'>GO TO HOMEPAGE</Link>
        </div>
    )
}

export default NoMatch