import { ServiceConstants } from './types';
import backendAxios from '../utils/backendAxios';

//Admin
export const addService = (newService) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ServiceConstants.ADD_SERVICE_REQUEST });
            const res = await backendAxios.post(`/api/service`, newService);
            if (res.status === 201) {
                dispatch({ type: ServiceConstants.ADD_SERVICE_SUCCESS });
                dispatch(getServices());
            } else {
                const { error } = res.data;
                dispatch({
                    type: ServiceConstants.ADD_SERVICE_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: ServiceConstants.ADD_SERVICE_FAILURE,
                payload: { error }
            });
        }
    };
};

export const getServices = () => {
    return async dispatch => {
        try {
            dispatch({ type: ServiceConstants.GET_SERVICES_REQUEST });
            const res = await backendAxios.get('/api/service');
            if (res.status === 200) {
                const { services } = res.data;
                dispatch({
                    type: ServiceConstants.GET_SERVICES_SUCCESS,
                    payload: { services }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: ServiceConstants.GET_SERVICES_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: ServiceConstants.GET_SERVICES_FAILURE,
                payload: { error }
            });
          }
    }
}

export const getServicesBySector = (sectorId) => {
    return async dispatch => {
        try {
            if(sectorId === "all") {
                // Give all services and return from here
                dispatch(getServices());
                return;
            }
            dispatch({ type: ServiceConstants.GET_SERVICES_REQUEST });
            const res = await backendAxios.post('/api/service/bysector', {sectorId});
            if (res.status === 200) {
                const { services } = res.data;
                dispatch({
                    type: ServiceConstants.GET_SERVICES_SUCCESS,
                    payload: { services }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: ServiceConstants.GET_SERVICES_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: ServiceConstants.GET_SERVICES_FAILURE,
                payload: { error }
            });
          }
    }
}

/* This action needs to be tested as UI is not built, adding service fields manually by hiting the API */
export const addServiceFields = (newServiceFields) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ServiceConstants.ADD_SERVICE_FIELDS_REQUEST });
            const res = await backendAxios.post(`/api/service/fields`, newServiceFields);
            if (res.status === 201) {
                dispatch({ type: ServiceConstants.ADD_SERVICE_FIELDS_SUCCESS });
            } else {
                const { error } = res.data;
                dispatch({
                    type: ServiceConstants.ADD_SERVICE_FIELDS_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: ServiceConstants.ADD_SERVICE_FIELDS_FAILURE,
                payload: { error }
            });
        }
    };
};

/* Get service fields for corresponding to serviceId and choiceId */
export const getServiceFields = (serviceFieldsObj) => {
    return async dispatch => {
        try {
            dispatch({ type: ServiceConstants.GET_SERVICE_FIELDS_REQUEST });
            const res = await backendAxios.get('/api/service/fields', {
                params: serviceFieldsObj
            });
            if (res.status === 200) {
                const { serviceFields } = res.data;
                console.log(res.data)
                dispatch({
                    type: ServiceConstants.GET_SERVICE_FIELDS_SUCCESS,
                    payload: { serviceFields }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: ServiceConstants.GET_SERVICE_FIELDS_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: ServiceConstants.GET_SERVICE_FIELDS_FAILURE,
                payload: { error },
            });
        }
    }
}