import { SectorConstants } from './types';
import backendAxios from '../utils/backendAxios';

//Admin
export const addSector = (newSector) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SectorConstants.ADD_SECTOR_REQUEST });
            const res = await backendAxios.post(`/api/sector`, newSector);
            if (res.status === 201) {
                dispatch({ type: SectorConstants.ADD_SECTOR_SUCCESS });
                dispatch(getSectors());
            } else {
                const { error } = res.data;
                dispatch({
                    type: SectorConstants.ADD_SECTOR_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: SectorConstants.ADD_SECTOR_FAILURE,
                payload: { error }
            });
        }
    };
};

export const getSectors = () => {
    return async dispatch => {
        try {
            dispatch({ type: SectorConstants.GET_SECTORS_REQUEST });
            const res = await backendAxios.get('/api/sector');
            if (res.status === 200) {
                const { sectors } = res.data;
                dispatch({
                    type: SectorConstants.GET_SECTORS_SUCCESS,
                    payload: { sectors }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: SectorConstants.GET_SECTORS_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: SectorConstants.GET_SECTORS_FAILURE,
                payload: { error },
            });
        }
    }
}