export const NAVIGATION = "Navigation"
export const BUTTON = "Button"
export const FORM = "form"
export const LOGIN_MODAL_WINDOW = "Login Modal Window"

export const CLICKED = "Clicked"
export const SUBMITTED = "Submitted"

export const PROFESSIONAL_REGISTRATION_SUBMITTED = "PROFESSIONAL_REGISTRATION_SUBMITTED"
export const GET_LOGIN_OTP = "Get Login OTP"
export const VERIFY_LOGIN_OTP = "Verify Login OTP"
export const LOGOUT = "Logout"