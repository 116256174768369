import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile } from '../../actions/profile';
import { generatePublicImgURL } from '../../urlConfig';
import { ADMIN, SOCIAL_MEDIA_LOGOS } from '../aNewGoToUI/utils/constants/s3Constants';

const ProfileForm = ({ createProfile, history }) => {
  const initialState = {
    company: '',
    website: '',
    location: '',
    status: '',
    skills: '',
    bio: '',
    twitter: '',
    facebook: '',
    linkedin: '',
    youtube: '',
    instagram: ''
  };
  const [formData, setFormData] = useState(initialState);
  const {
    company,
    website,
    location,
    status,
    skills,
    bio,
    twitter,
    facebook,
    linkedin,
    youtube,
    instagram
  } = formData;

  const [displaySocialInputs, toggleSocialInputs] = useState(false);
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    createProfile(formData, history);
  };

  return (
    <Fragment>
      <h1 className="text-center py-3 text-xl font-bold text-gray-900 bg-black/20 ">
        Create Your Profile
      </h1>
      <p className="text-xs text-gray-600 mx-2 mt-2">
        <i className="fas fa-user" />
        Let's get some information to make your profile standout
      </p>
      <form onSubmit={e => onSubmit(e)}>
        <div className="flex flex-col gap-2 mx-2 mt-2">
          <select className="p-2" name="status" value={status} onChange={onChange}>
            <option>* Select Professional Status</option>
            <option value='Company'>Company</option>
            <option value='Professional'>Professional</option>
            <option value='Intern'>Intern</option>
            <option value='LookingForJobs'>Looking for Jobs</option>
            <option value='Other'>Other</option>
          </select>
        </div>
        <div className="flex flex-col gap-2 mx-2 mt-2">
          <input
            type="text"
            placeholder="Skills *"
            name="skills"
            value={skills}
            onChange={onChange}
            required
            className="bg-black/5 p-2"
          />
        </div>
        <div className="flex flex-col gap-2 mx-2 mt-2">
          <textarea
            placeholder="A short bio of yourself"
            name="bio"
            value={bio}
            onChange={onChange}
            className="bg-black/5 p-2"
          />
          <small>Tell us a little about yourself</small>
        </div>

        <div className="mt-3 mx-2 bg-black/10 text-center py-2 ">
          <button
            onClick={() => toggleSocialInputs(!displaySocialInputs)}
            type="button"
          >
            Add Social Network Links
          </button>
          <span className="ml-2 text-sm text-gray-500">Optional</span>
        </div>

        {displaySocialInputs && (
          <Fragment>
            <div className=" mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Twitter URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'TwitterLogo.png')} alt="twitter_logo" width={24} />
                <input
                  type="text"
                  // placeholder="Twitter URL"
                  name="twitter"
                  value={twitter || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>

            <div className="mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Facebook URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'FacebookLogo.png')} alt="facebook_logo" width={24} />

                <input
                  type="text"
                  // placeholder="Facebook URL"
                  name="facebook"
                  value={facebook || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>

            <div className="mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Youtube URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'YouTubeLogo.png')} alt="twitter_logo" width={24} />

                <input
                  type="text"
                  // placeholder="YouTube URL"
                  name="youtube"
                  value={youtube || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>

            <div className="mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Linkedin URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'LinkedinLogo.png')} alt="linkedin_logo" width={24} />

                <input
                  type="text"
                  // placeholder="Linkedin URL"
                  name="linkedin"
                  value={linkedin || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>

            <div className="mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Instagram URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'InstagramLogo.png')} alt="instagram_logo" width={24} />

                <input
                  type="text"
                  // placeholder="Instagram URL"
                  name="instagram"
                  value={instagram || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>
          </Fragment>
        )}


        <div className="w-full flex justify-center gap-5 mt-3">
          <Link className="p-[0.4em] px-5 rounded-md bg-green-400 active:scale-95 transitions-all duration-200" to="/">
            Go Back
          </Link>
          <input
            type="submit"
            className="p-1 px-6 rounded-md bg-blue-400"
          />
        </div>
      </form>
    </Fragment>
  );
}

ProfileForm.propTypes = {
  createProfile: PropTypes.func.isRequired
};

export default connect(null, { createProfile })(withRouter(ProfileForm));