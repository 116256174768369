import React from 'react'
import AddressForm from './AddressForm';
import PincodeData from '../PincodeData';
import ManualAddress from './ManualAddress';

const Address = ({
    adr,
    selectAddress,
    enableAddressEditForm,
    confirmAddressUsed
}) => {
    return (
        <div class="flex flex-col justify-center items-center  gap-0 py-1">
            <>
                {!adr.edit ? (
                    <div className='w-full text-center rounded-lg border-2 border-black/5 p-3 hover:bg-transparent hover:border-2 hover:border-dashed hover:border-blue-400'
                        onMouseMove={() => selectAddress(adr)}
                    >
                        <div class="flex gap-1 justify-center">
                            <span class="word-span font-semibold text-center block mb-[2px]">
                                {adr.name}
                            </span>
                            <span class="word-span font-semibold text-center block mb-[2px]">
                                {adr.mobile}
                            </span>
                        </div>

                        {/* Need to analyse if option to edit button should be given or not  */}
                        {/* {adr.selected && (
                                <Anchor
                                    name="EDIT"
                                    onClick={() => enableAddressEditForm(adr)}
                                    style={{
                                        fontWeight: "500",
                                        color: "#2874f0",
                                    }}
                                />
                            )} */}

                        <PincodeData
                            poName={adr.pincodeData.poName}
                            cityBlock={adr.pincodeData.cityBlock}
                            district={adr.pincodeData.district}
                            stateName={adr.pincodeData.stateName}
                            pincode={adr.pincodeData.pincode}
                        />
                        <ManualAddress
                            completeAdd={adr.manualAddress.completeAdd}
                            buildingDetails={adr.manualAddress.buildingDetails}
                            floorDetails={adr.manualAddress.floorDetails}
                            landmark={adr.landmark}
                        />
                        {adr.selected && (
                            <button
                                className="text-sm bg-blue-400 p-2 rounded-full text-white my-3 mt-2 px-4 hover:scale-105 transition-all duration-200 shadow-sm border border-black/10"
                                onClick={() => confirmAddressUsed(adr)}
                            >
                                Confirm Address
                            </button>
                        )}
                    </div>
                ) : (
                    <AddressForm
                        initialData={adr}
                    />
                )}
            </>
        </div>
    );
};

export default Address