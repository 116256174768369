import React from "react";
import { InvoiceAddJobFooter } from "../../job/Job";

export function DownLoadJobInvoice(job) {
    return job?.paymentStatus === 'success' ?
        <>
            {/* <PDFViewer>
                <AddJobPdf job={job} />
              </PDFViewer> */}
            <InvoiceAddJobFooter job={job} />
        </>
        : null;
}