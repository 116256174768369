import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import ReactGA from 'react-ga4'
import { getProfessionals } from '../../../../../actions/workerCategory';
import { PLUMBER } from '../../../utils/constants/DB/ServiceNames';
import CustomServiceComponent from '../../../utils/common/CustomServiceComponent';

const Plumber = () => {

    const professional = useSelector((state) => state.professional);
    const [professionals, setProfessionals] = useState(professional.professionals);
    const [selectedGoToLocation, setSelectedGoToLocation] = useState('');
    console.log(selectedGoToLocation)

    const handleLocationSelect = (location) => {
        // Handle the selected location as needed
        setSelectedGoToLocation(location);
    };

    const handleLocationCancel = () => {
        // Set selectedGoToLocation to an empty string when canceled
        setSelectedGoToLocation('');
    };


    const dispatch = useDispatch();

    useEffect(() => {
        const queryParams = setQueryParams()
        dispatch(getProfessionals(queryParams));
    }, [selectedGoToLocation])

    useEffect(() => {
        setProfessionals(professional.professionals);
    }, [professional.professionals]);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
      }, []);

    return (
        <CustomServiceComponent
            serviceName={PLUMBER}
            handleLocationSelect={handleLocationSelect}
            handleLocationCancel={handleLocationCancel}
            professional={professional}
            professionals={professionals}
            cardType='profile'
        />
    )

    function setQueryParams() {
        return {
            serviceName: PLUMBER,
            status: 'active',
            goToLocation: selectedGoToLocation
        };
    }
}

export default Plumber