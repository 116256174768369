const PincodeData = (props) => {
    const { poName, cityBlock, district, stateName, pincode } = props
    return (
        <div className="mt-3">
            {
                poName != 'NA' &&
                (
                    <div class="flex justify-center gap-1 text-[0.8em]">
                        <span class="word-span font-normal mb-[2px]">
                            Post Office:
                        </span>
                        <span class="word-span font-normal mb-[2px]">
                            {poName}
                        </span>
                    </div>
                )
            }
            <div class="flex justify-center gap-1 text-[0.8em]">
                {
                    cityBlock != 'NA' &&
                    (
                        <span class="word-span font-normal mb-[2px]">
                            {cityBlock}
                        </span>
                    )
                }
                {
                    district != 'NA' &&
                    (
                        <span class="word-span font-normal mb-[2px]">
                            {district},
                        </span>
                    )
                }
                {
                    stateName != 'NA' &&
                    (
                        <span class="word-span font-normal block mb-[2px]">
                            {stateName}
                        </span>
                    )
                }
            </div>
            {
                pincode != 'NA' &&
                (
                    <div class="flex justify-center gap-1 text-[0.8em]">
                        <span class="word-span font-normal mb-[2px]">
                            Pincode:
                        </span>
                        <span class="word-span font-normal mb-[2px]">
                            {pincode}
                        </span>
                    </div>
                )
            }
        </div>
    )
}
export default PincodeData