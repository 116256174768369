import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import AddProfessionalPdf from '../pdf/invoicePDF/AddProfessionalPdf';
import { getProfessionalStatus } from '../../actions/workerCategory';
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { DownLoadProfInvoice } from '../utils/workercategory/WorkerCategory';
import ServiceCardStatus from './ServiceCardStatus';

export const InvoiceProfessionalPdfFooter = ({ prof }) => {
  return (
    <div className='w-full flex justify-center'>
      <button className='btn-grad w-[70%]'
        onClick={async () => {
          const doc = <AddProfessionalPdf prof={prof} />;
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          saveAs(blob, "invoice.pdf");
        }}
      >
        Download Invoice
      </button>
    </div>
  );
};

const AddWorkerCallback = ({ match }) => {
  const professional = useSelector((state) => state.professional);
  const [profStatus, setProfStatus] = useState(professional.professional);

  const dispatch = useDispatch();

  useEffect(() => {
    const profId = match.params.id;
    console.log("profId =", match.params.id)
    dispatch(getProfessionalStatus('', '', profId));
  }, []);

  useEffect(() => {
    setProfStatus(professional.professional);
  }, [professional.professional]);

  return (
    <div className='w-full flex flex-col justify-center'>
      <ServiceCardStatus professional={professional?.professional[0]} />

      {
        DownLoadProfInvoice(professional.professional[0])
      }

    </div>
  )
}

export default AddWorkerCallback