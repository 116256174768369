import React from 'react'
import ServiceWrapper from './ServiceWrapper'
import SearchLayout from './SearchLayout'
import ServiceLayout from './ServiceLayout'
import Spinner from '../../../layout/Spinner'

const CustomServiceComponent = (props) => {
    const { serviceName, handleLocationSelect, handleLocationCancel, professional, professionals, cardType } = props
    return (
        <ServiceWrapper>
            <SearchLayout
                serviceName={serviceName}
                handleLocationSelect={handleLocationSelect}
                handleLocationCancel={handleLocationCancel}
            />
            {
                professional.loading ? (
                    // <Spinner />
                    <>
                    </>
                ) : (
                    <ServiceLayout
                        professionals={professionals}
                        cardType={cardType}
                    />
                )
            }
        </ServiceWrapper>
    )
}

export default CustomServiceComponent