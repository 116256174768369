import { setAlert } from './alert';
import {
  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
  JOB_ERROR,
  UPDATE_APPLIES,
  ADD_JOB,
  GET_JOB,
  GET_JOBS_BY_DISTRICT,
  ADD_COMMENT,
  REMOVE_COMMENT,
  JobConstants
} from './types';
import backendAxios from '../utils/backendAxios';

// Get Jobs
export const getJobs = (queryParams) => async dispatch => {
  try {
    dispatch({ type: GET_JOBS_REQUEST });
    const res = await backendAxios.get('/api/jobs', {
      params: queryParams
    });
    dispatch({
      type: GET_JOBS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const getJobStatus = (jobCategoryId, choiceOfJobId, jobId) => {
  return async dispatch => {
    try {
      dispatch({ type: JobConstants.GET_JOB_STATUS_REQUEST });
      const res = await backendAxios.get('/api/jobs/status', {
        params: {
          jobCategoryId,
          choiceOfJobId,
          jobId
        }
      });
      if (res.status === 201) {
        console.log(res.data)
        const { job } = res.data;
        dispatch({
          type: JobConstants.GET_JOB_STATUS_SUCCESS,
          payload: { job }
        });
      }
      else {
        const { error } = res.data;
        dispatch({
          type: JobConstants.GET_JOB_STATUS_FAILURE,
          payload: { error }
        });
      }
    } catch (error) {
      dispatch(setAlert(error.msg, 'danger'))
      dispatch({
        type: JobConstants.GET_JOB_STATUS_FAILURE,
        payload: { error },
      });
    }
  }
}

// Apply for Job
export const applyJob = id => async dispatch => {
  try {
    const res = await backendAxios.put(`/api/jobs/apply/${id}`);
    dispatch(setAlert('You have successfully applied for this job', 'success'));
    dispatch({
      type: UPDATE_APPLIES,
      payload: { id, applies: res.data }
    });
  } catch (err) {
    dispatch(setAlert('You have already applied for this job', 'danger'));
    dispatch({
      type: JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Unapply for Job
export const unapplyJob = id => async dispatch => {
  try {
    const res = await backendAxios.put(`/api/jobs/unapply/${id}`);
    dispatch(setAlert('You have successfully unapplied for this job', 'success'));
    dispatch({
      type: UPDATE_APPLIES,
      payload: { id, applies: res.data }
    });
  } catch (err) {
    dispatch(setAlert('You have not applied to this job', 'danger'));
    dispatch({
      type: JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Delete Job
export const deleteJob = id => async dispatch => {
  try {
    dispatch({ type: JobConstants.DELETE_JOB_REQUEST });
    const res = await backendAxios.delete(`/api/jobs/${id}`);
    dispatch({
      type: JobConstants.DELETE_JOB_SUCCESS,
      payload: id
    });

    dispatch(setAlert('Job Removed', 'success'));
  } catch (err) {
    dispatch({
      type: JobConstants.DELETE_JOB_FAILURE,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Add Job
export const addJob = newJob => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  try {

    const res = await backendAxios.post('api/jobs', newJob, config);
    dispatch({
      type: ADD_JOB,
      payload: res.data
    });

    dispatch(setAlert('Job Created', 'success'));
  } catch (err) {
    dispatch({
      type: JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Job
export const getJob = id => async dispatch => {
  try {
    const res = await backendAxios.get(`/api/jobs/${id}`);
    dispatch({
      type: GET_JOB,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Add COMMENT
export const addComment = (jobId, formData) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  try {

    const res = await backendAxios.post(`/api/jobs/comment/${jobId}`, formData, config);
    console.log("Adding");
    dispatch({
      type: ADD_COMMENT,
      payload: res.data
    });

    dispatch(setAlert('Comment Added', 'success'));
  } catch (err) {
    dispatch({
      type: JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Delete Comment
export const deleteComment = (jobId, commentId) => async dispatch => {
  try {
    const res = await backendAxios.delete(`/api/jobs/comment/${jobId}/${commentId}`);
    dispatch({
      type: REMOVE_COMMENT,
      payload: commentId
    });
    dispatch(setAlert('Comment Removed', 'success'));
  } catch (err) {
    dispatch({
      type: JOB_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
