import React from 'react';
import { generatePublicImgURL } from '../../../urlConfig';
import { ADMIN, HOME_PAGE_ICON_PHOTOS } from '../utils/constants/s3Constants';
import { useLoginPhoneUtils } from '../utils/common/loginPhoneUtils';
import { NAVIGATION } from '../../../utils/Google Analytics/GA4Constants';
import { REGISTER_ACTION } from '../utils/constants/CommonConstants';
const MobileTopBannerCard = () => {

  const { handleAuthentication, renderLoginModal } = useLoginPhoneUtils();

  return (
    <div className="flex bg-white rounded-lg shadow-md overflow-hidden max-w-2xl m-[0.4em] border border-black/10" >
      <div className="flex-1 p-6 pr-0">
        <h2 className="text-[1.4em] font-extrabold mb-1">
          Start Selling Your Services !!
        </h2>
        <p className="text-[0.7em] mb-3 mt-2">
          Become The Service Provider At BharatPros And Start Earning Online.
        </p>
        <button onClick={() => handleAuthentication(NAVIGATION, REGISTER_ACTION)} className=" flex items-center justify-center px-[3em] text-sm bg-black text-white px-4 py-1 rounded-full hover:bg-gray-800 transition-colors">
          Join now
        </button>
      </div>
      <div className="w-45  flex items-center justify-center">
        <div className="text-gray-500">
          <img src={generatePublicImgURL(ADMIN + '/'+ HOME_PAGE_ICON_PHOTOS + '/' + 'RegisterBannerIcon.png')} alt='img' width={170}/>
        </div>
      </div>
      {
        renderLoginModal()
      }
    </div>
  );
};

export default MobileTopBannerCard;