import { ADVOCATES, AIR_CONDITIONER, ARCHITECTS, BAKERS, BAND_BAJA, CAFES, CARPENTER, CATERING, CA_CHARTERED_ACCOUNTANT, CCTV_CAMERAS, CHIMNEY, CONCRETE_MIXER, DHOL, DISHWASHER, DJ_SOUND_SYSTEM, ELECTRICIAN, 
    FLAT_HOMES, FLEX_PRINTING_SERVICES, GAS_STOVE, GEYSER, GREEN_LEAF_PLATES, GROOMS_CAR, HIMACHALI_DHAM, HOME_STAYS, HOTELS, HOUSE_CONTRACTOR, JCB_BACKHOE, MARBLE_INSTALLER, 
    MARRIAGE_BANQUET_HALL, MICROWAVE, MODULAR_KITCHEN, PAINTER, PARTY_HALL, PAYING_GUEST, 
    PHOTOGRAPHER, 
    PHOTOSTAT, PICK_UP_FOUR_WHEELER_AUTO, PICK_UP_THREE_WHEELER_AUTO, PICK_UP_TRACTOR, PICK_UP_TRANSPORTATION_JEEP, PICK_UP_VANS, PLUMBER, PRINTER_AND_INK_REFILL, PROPERTY_DEALERS, RAJ_MISTRI, REFRIGERATOR, RENTAL_BROKERS, RESTAURANTS, RETAIL_SHOPS, RO_PURIFIER, SHUTTERING, SINGLE_ROOM, TAXIS, 
    TAXI_MINI_CAR, 
    TAXI_SEDAN_CAR, 
    TAXI_TRAVELLER_VAN, 
    TAXI_VAN_EECO_VAN, 
    TAXI_WINGER_VAN, 
    TAXI_XUV_CAR, 
    TELEVISION, 
    TENT, 
    TILE_INSTALLER, TOW_TRUCK, UPVC_ALUMINIUM_DOOR_WINDOW, WASHING_MACHINE, WATER_TANKER, WELDER_METAL_FABRICATOR, WIFI } from "../constants/DB/ServiceNames";

const serviceLocations = [
    {
        "serviceName": ELECTRICIAN,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": PLUMBER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": RO_PURIFIER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": AIR_CONDITIONER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": REFRIGERATOR,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": CCTV_CAMERAS,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": WIFI,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Nagrota",
            "Palampur",
            "Baijnath",
            "Hamirpur",
            "Nadaun",
            "Banikhet",
            "Chamba",
            "Mandi",
            "Kullu",
            "Manali",
            "Sundernagar",
            "Bilaspur"
        ],
        "payment": true
    },
    {
        "serviceName": TELEVISION,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": WASHING_MACHINE,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": CHIMNEY,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": GEYSER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": GAS_STOVE,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": DISHWASHER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": MICROWAVE,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Palampur",
            "Hamirpur"
        ],
        "payment": true
    },
    {
        "serviceName": PRINTER_AND_INK_REFILL,
        "locations": [
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": PHOTOSTAT,
        "locations": [
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": PAYING_GUEST,
        "locations": [
            "Chandigarh",
            "Kharar",
            "Mohali",
            "Zirakpur",
            "Panchkula",
            "Shahpur",
            "Dharmshala",
            "Tanda",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": SINGLE_ROOM,
        "locations": [
            "Chandigarh",
            "Kharar",
            "Mohali",
            "Zirakpur",
            "Panchkula",
            "Shahpur",
            "Dharmshala",
            "Tanda",
            "Palampur"
        ],
        "payment": false
    },
    {
        "serviceName": FLAT_HOMES,
        "locations": [
            "Chandigarh",
            "Kharar",
            "Mohali",
            "Zirakpur",
            "Panchkula",
            "Shahpur",
            "Dharmshala",
            "Tanda",
            "Palampur"
        ],
        "payment": false
    },
    {
        "serviceName": RETAIL_SHOPS,
        "locations": [
            "Chandigarh",
            "Kharar",
            "Mohali",
            "Zirakpur",
            "Panchkula",
            "Shahpur",
            "Dharmshala"
        ],
        "payment": false
    },
    {
        "serviceName": TAXI_MINI_CAR,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Hamirpur",
            "Gaggal Airport",
            "Shimla",
            "Una",
            "Mandi",
            "Manali",
            "Bhuntar",
            "Bir",
            "Kasol",
            "Una"
        ],
        "payment": true
    },
    {
        "serviceName": TAXI_SEDAN_CAR,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Hamirpur",
            "Gaggal Airport",
            "Shimla",
            "Una",
            "Mandi",
            "Manali",
            "Bhuntar",
            "Bir",
            "Kasol",
            "Una"
        ],
        "payment": true
    },
    {
        "serviceName": TAXI_XUV_CAR,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Hamirpur",
            "Gaggal Airport",
            "Shimla",
            "Una",
            "Mandi",
            "Manali",
            "Bhuntar",
            "Bir",
            "Kasol",
            "Una"
        ],
        "payment": true
    },
    {
        "serviceName": TAXI_WINGER_VAN,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Hamirpur",
            "Gaggal Airport",
            "Shimla",
            "Una",
            "Mandi",
            "Manali",
            "Bhuntar",
            "Bir",
            "Kasol",
            "Una"
        ],
        "payment": true
    },
    {
        "serviceName": TAXI_TRAVELLER_VAN,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Hamirpur",
            "Gaggal Airport",
            "Shimla",
            "Una",
            "Mandi",
            "Manali",
            "Bhuntar",
            "Bir",
            "Kasol",
            "Una"
        ],
        "payment": true
    },
    {
        "serviceName": TAXI_VAN_EECO_VAN,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Kangra",
            "Hamirpur",
            "Gaggal Airport",
            "Shimla",
            "Una",
            "Mandi",
            "Manali",
            "Bhuntar",
            "Bir",
            "Kasol",
            "Una"
        ],
        "payment": true
    },
    {
        "serviceName": PICK_UP_TRANSPORTATION_JEEP,
        "locations": [
            "Shahpur",
            "Dramman",
            "Dharmshala",
            "Hamirpur",
            "Palampur",
            "Bilaspur",
            "Mandi",
            "Una",
            "Shimla",
            "Manali"
        ],
        "payment": true
    },
    {
        "serviceName": PICK_UP_THREE_WHEELER_AUTO,
        "locations": [
            "Shahpur",
            "Dramman",
            "Dharmshala",
            "Hamirpur",
            "Palampur",
            "Bilaspur",
            "Mandi",
            "Una",
            "Shimla",
            "Manali"
        ],
        "payment": true
    },
    {
        "serviceName": PICK_UP_TRACTOR,
        "locations": [
            "Shahpur",
            "Dramman",
            "Dharmshala",
            "Hamirpur",
            "Palampur",
            "Bilaspur",
            "Mandi",
            "Una",
            "Shimla",
            "Manali"
        ],
        "payment": true
    },
    {
        "serviceName": PICK_UP_FOUR_WHEELER_AUTO,
        "locations": [
            "Shahpur",
            "Dramman",
            "Dharmshala",
            "Hamirpur",
            "Palampur",
            "Bilaspur",
            "Mandi",
            "Una",
            "Shimla",
            "Manali"
        ],
        "payment": true
    },
    {
        "serviceName": TOW_TRUCK,
        "locations": [
            "Shahpur",
            "Dramman",
            "Dharmshala",
            "Gaggal",
            "Kangra",
            "Palampur",
            "Shimla",
            "Mandi",
            "Hamirpur",
            "Pathankot"
        ],
        "payment": true
    },
    {
        "serviceName": WATER_TANKER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Gaggal",
            "Kangra",
            "Palampur",
            "Nagrota",
            "Shimla",
            "Solan",
            "Mandi",
            "Hamirpur",
            "Pathankot",
            "Chandigarh",
            "Zirakpur",
            "Panchkula",
            "Mohali",
            "Kharar"
        ],
        "payment": true
    },
    {
        "serviceName": ARCHITECTS,
        "locations": [
            "Dharmshala",
            "Shimla"
        ],
        "payment": true
    },
    {
        "serviceName": RAJ_MISTRI,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": TILE_INSTALLER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": MARBLE_INSTALLER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": PAINTER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": CARPENTER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": WELDER_METAL_FABRICATOR,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": MODULAR_KITCHEN,
        "locations": [
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": UPVC_ALUMINIUM_DOOR_WINDOW,
        "locations": [
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": JCB_BACKHOE,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": CONCRETE_MIXER,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": SHUTTERING,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": HOUSE_CONTRACTOR,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": PARTY_HALL,
        "locations": [
            "Dharmshala",
            "Palampur",
            "Hamirpur",
            "Shimla"
        ],
        "payment": true
    },
    {
        "serviceName": MARRIAGE_BANQUET_HALL,
        "locations": [
            "Dharmshala",
            "Palampur",
            "Hamirpur",
            "Shimla"
        ],
        "payment": true
    },
    {
        "serviceName": HIMACHALI_DHAM,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": GREEN_LEAF_PLATES,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": CATERING,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": PHOTOGRAPHER,
        "locations": [
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": TENT,
        "locations": [
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": BAND_BAJA,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ],
        "payment": true
    },
    {
        "serviceName": DHOL,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ],
        "payment": true
    },
    {
        "serviceName": DJ_SOUND_SYSTEM,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ],
        "payment": true
    },
    {
        "serviceName": GROOMS_CAR,
        "locations": [
            "Shahpur",
            "Dharmshala"
        ],
        "payment": true
    },
    {
        "serviceName": FLEX_PRINTING_SERVICES,
        "locations": [
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": CA_CHARTERED_ACCOUNTANT,
        "locations": [
            "PAN_INDIA"
        ],
        "payment": true
    },
    {
        "serviceName": ADVOCATES,
        "locations": [
            "PAN_INDIA"
        ],
        "payment": true
    },
    {
        "serviceName": HOME_STAYS,
        "locations": [
            "Dharmshala",
            "Dharmkot",
            "Bir Billing",
            "Jibhi",
            "Kasol",
            "Sissu Atal Tunnal",
            "Spiti",
            "Sangla Chitkul",
            "Reckong Peo",
            "Kalpa",
            "Narkanda",
            "Mashobra",
            "Hatu Peak",
            "Kufri"
        ],
        "payment": true
    },
    {
        "serviceName": HOTELS,
        "locations": [
            "Dharmshala",
            "Dalhousie",
            "Manali",
            "Shimla"
        ],
        "payment": true
    },
    {
        "serviceName": CAFES,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "McLeod Ganj",
            "Dharmkot",
            "Palampur",
            "Bir",
            "Tosh Valley (Kasol)",
            "Manali",
            "Chandigarh"
        ],
        "payment": true
      },
      {
        "serviceName": RESTAURANTS,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "McLeod Ganj",
            "Dharmkot",
            "Palampur",
            "Bir",
            "Tosh Valley (Kasol)",
            "Manali",
            "Chandigarh"
        ],
        "payment": true
      },
      {
        "serviceName": BAKERS,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "McLeod Ganj",
            "Dharmkot",
            "Palampur",
            "Bir",
            "Tosh Valley (Kasol)",
            "Manali",
            "Chandigarh"
        ],
        "payment": true
      },
      {
        "serviceName": RENTAL_BROKERS,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Chandigarh",
            "Kharar",
            "Mohali",
            "Zirakpur",
            "Panchkula",
            "Delhi",
            "Noida",
            "Gurugram",
            "Bengaluru",
            "Pune",
            "Hyderabad"
        ],
        "payment": true
      },
      {
        "serviceName": PROPERTY_DEALERS,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Chandigarh",
            "Kharar",
            "Mohali",
            "Zirakpur",
            "Panchkula",
            "Delhi",
            "Noida",
            "Gurugram",
            "Bengaluru",
            "Pune",
            "Hyderabad"
        ],
        "payment": true
      },
      {
        "serviceName": "Test",
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Chandigarh",
            "Kharar",
            "Mohali",
            "Zirakpur",
            "Panchkula",
            "Delhi",
            "Noida",
            "Gurugram",
            "Bengaluru",
            "Pune",
            "Hyderabad"
        ],
        "payment": true
      }
]

export const getLocationByServiceNameWeOnboard = (serviceName) => {
    const matchingService = serviceLocations.find(service => service.serviceName === serviceName);

    // If the matching service is found, return its locations; otherwise, return an empty array
    return matchingService ? matchingService.locations : [];
};

export const checkPaymentNeededByServiceName = (serviceName) => {
    console.log("payment check", serviceName)
    const paymentNeeded = serviceLocations.find(service => service.serviceName === serviceName);

    // If the matching service is found, return payment enable otherwise, return false
    return paymentNeeded ? paymentNeeded.payment : true; 
};