import React from 'react';
import PropTypes from 'prop-types';
import formatDate from '../../utils/formatDate.js';

const ProfileExperience = ({
  experience: { company, title, location, current, to, from, description }
}) => (
  <div className="flex item-start gap-2 border-b border-black/10 mx-2  ">
  <li className="text-[0.8em] py-[10px] list-none flex flex-col gap-[4px] ">
   <div className='flex gap-3'>
   <h3 className="text-dark font-bold">{company}</h3>
    <p className='text-gray-600'>
   ( {formatDate(from)} - {to ? formatDate(to) : 'Now'} )
    </p>
   </div>
    <p>
      {title ? <div> <strong>Position: </strong> {title} </div> : null}
    </p>
    <p>
      {location ? <div> <strong>Location: </strong> {location} </div> : null}
    </p>
    <p>
      {description ? <div> <strong>Description: </strong> {description} </div> : null}
    </p>
  </li>
</div>
);

ProfileExperience.propTypes = {
  experience: PropTypes.object.isRequired
};

export default ProfileExperience;