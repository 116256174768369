import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    titleContainer: {
        marginTop: 24,
    },
    reportTitle: {
        color: '#3778C2',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    label: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
});

const InvoiceTitle = ({ title }) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
        <Text style={styles.reportTitle}>BHARATPROS</Text>
        <Text style={styles.label}>GST: 02ABAFB9129N1ZE</Text>
        {/* <Text style={styles.label}>Address: </Text> */}
    </View>
);

export default InvoiceTitle;