import { CheckReferralConstants } from './types';
import backendAxios from '../utils/backendAxios';


export const checkReferralCodeRequest = () => {
  return {
    type: CheckReferralConstants.CHECK_REFERRAL_CODE_REQUEST,
  };
};

export const checkReferralCodeSuccess = (isValidReferral, referralMessage, referringUser) => {
  return {
    type: CheckReferralConstants.CHECK_REFERRAL_CODE_SUCCESS,
    payload: {
        isValidReferral,
        referralMessage,
        referringUser
    },
  };
};

export const checkReferralCodeFailure = (error) => {
  return {
    type: CheckReferralConstants.CHECK_REFERRAL_CODE_FAILURE,
    payload: {
      error,
    },
  };
};

export const checkReferralCode = (referralCode) => {
  return async (dispatch) => {
    dispatch(checkReferralCodeRequest());

    try {
      const response = await backendAxios.post('/api/users/checkReferralCode', { referralCode });
      const { isValidReferral, referralMessage, referringUser } = response.data;
      dispatch(checkReferralCodeSuccess(isValidReferral, referralMessage, referringUser));
    } catch (error) {
      dispatch(checkReferralCodeFailure(error));
    }
  };
};

export const updateReferralCode = () => {
    return {
      type: CheckReferralConstants.UPDATE_REFERRAL_CODE
    };
  };