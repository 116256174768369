import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { GrLocation } from "react-icons/gr";
import { MdLocalPhone } from "react-icons/md";
import { FaCircleInfo } from "react-icons/fa6";
import { generatePublicImgURL } from "../../../urlConfig";
import { ADMIN, ICONS_AND_PHOTOS } from "../utils/constants/s3Constants";
import { useLoginPhoneUtils } from "../utils/common/loginPhoneUtils";

const ProfileCard = ({ professional }) => {

  const { handleCallClick, renderLoginModal } = useLoginPhoneUtils();

  return (
    <div className="border border-[#58585850] shadow-sm rounded-lg mx-2 my-3 lg:my-0">
      <div className="py-2  bg-blue-400 flex items-center gap-1 pl-4 rounded-t-md">
        <span className="text-white"><GrLocation /></span>
        <p className="text-[0.8em] text-white    ">
          {professional.address.pincodeData.cityBlock} {professional.address.pincodeData.district} {professional.address.pincodeData.stateName}
          &nbsp; ({professional.address.pincodeData.pincode})
        </p>
      </div>

      <div className={`flex flex-row  gap-[5em] lg:px-6 lg:py-4 justify-evenly  lg:mx-3 items-center   p-2 w-full relative `}>
        <div className=" " >
          <div className="w-[120px] h-[120px] rounded-full">
            <img
              className=" object-cover rounded-full "
              src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + 'NoProfile.png')}
              alt="im"
            />
          </div>
          <div className=" text-center ">
            <p className="text-[0.9em]  font-bold">{professional.firmName ? professional.firmName : professional.address.name} </p>
          </div>
        </div>
        <div className="flex flex-col mt-5 justify-evenly w-full mb-3 gap-3 ">
          <button
            className="z-30 flex  items-center justify-center bg-white rounded-lg border-2 border-blue-400 p-2 hover:bg-blue-400"
            onClick={() => handleCallClick(professional.address.mobile)}>
            <MdLocalPhone className="text-black" />
            <span className="text-[0.8em] ml-1 ">
              Call Now
            </span>
          </button>
          <Link to={`/profile/${professional.userId}`}>
            <span className=" right-2 z-30 flex  items-center bg-blue-400 justify-center  rounded-lg text-white p-2 hover:bg-blue-400">
              <FaCircleInfo className="text-white" /><span className="text-[0.8em] ml-1 ">
                View Profile
              </span>
            </span>
          </Link>
        </div>
      </div>
      {
        renderLoginModal()
      }
    </div>
  );
};

export default ProfileCard;