import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfessionalById } from "../../../actions/workerCategory";
import ImageSection from "./ImageSection";
import ServiceDescription from "./ServiceDescription";

const ShowServiceDetails = ({ match }) => {
  const professional = useSelector((state) => state.professional);
  const [profDetails, setProfDetails] = useState(professional.professional);

  const dispatch = useDispatch();

  useEffect(() => {
    const profId = match.params.profId;
    dispatch(getProfessionalById(profId));
  }, []);

  useEffect(() => {
    setProfDetails(professional.professional);
  }, [professional.professional]);


  return (
    <div>
      <ImageSection images={profDetails?.images} />
      <ServiceDescription
        name={profDetails?.firmName}
        description={profDetails?.description}
        location={profDetails?.goToLocation}
      />
    </div>
  );
};

export default ShowServiceDetails;
