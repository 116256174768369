import { ServiceConstants } from "../actions/types";

const initialState = {
    services: [],
    serviceFields: [],
    error: null,
    loading: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ServiceConstants.GET_SERVICES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ServiceConstants.GET_SERVICES_SUCCESS:
            return {
                ...state,
                services: payload.services,
                loading: false,
            };
        case ServiceConstants.GET_SERVICES_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload.error
            }
        case ServiceConstants.GET_SERVICE_FIELDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ServiceConstants.GET_SERVICE_FIELDS_SUCCESS:
            return {
                ...state,
                serviceFields: payload.serviceFields,
                loading: false,
            };
        case ServiceConstants.GET_SERVICE_FIELDS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload.error
            }
        default:
            return state;
    }
}