import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import Address from "../utils/address/Address";
import AddressForm from "../utils/address/AddressForm";

import "../style.css";
import PincodeData from "../utils/PincodeData";
import ManualAddress from "../utils/address/ManualAddress";
import { IoArrowBackCircleOutline, IoRemoveOutline } from "react-icons/io5";
import { Grid } from "@mui/material";
import { RiHome5Line } from "react-icons/ri";
import SelectYourAddress from "../utils/address/SelectYourAddress";
import getCompleteMobileNumberWithPlus from "../../utils/MobileNumber";
import { getAddress } from "../../actions/address";
import PhoneInput from 'react-phone-input-2'
import { checkAdminSalesPersonRole } from "../../utils/checkRole";

const CheckoutServiceProvider = ({
    confirmAddress,
    setConfirmAddress,
    setAddressId,
    selectedAddress,
    setSelectedAddress,
    newAddress,
    setNewAddress,
    address,
    setAddress
}) => {

    const auth = useSelector((state) => state.auth);
    const [mobile, setMobile] = useState("");

    const dispatch = useDispatch();

    const [isAddressModal, setIsAddressModal] = useState(false);

    const showAddressModal = () => {
        setIsAddressModal(true);
        setConfirmAddress(false);
    };

    const closeAddressModal = () => {
        setIsAddressModal(false);
        setNewAddress(false)
    };

    // Function to handle onChange event of the PhoneInput and add "+"
    const handleMobileChange = (mobile) => {
        setMobile(getCompleteMobileNumberWithPlus(mobile))
    };

    const getAddressHandler = () => {
        const addressQuery = { mobile }
        dispatch(getAddress(addressQuery))
    };

    const selectAddress = (addr) => {
        const updatedAddress = address.map((adr) =>
            adr._id === addr._id
                ? { ...adr, selected: true }
                : { ...adr, selected: false }
        );
        setAddress(updatedAddress);
    };

    const confirmAddressUsed = (addr) => {
        setSelectedAddress(addr);
        setConfirmAddress(true);
        setAddressId(addr._id)
    };

    const enableAddressEditForm = (addr) => {
        const updatedAddress = address.map((adr) =>
            adr._id === addr._id ? { ...adr, edit: true } : { ...adr, edit: false }
        );
        setAddress(updatedAddress);
    };

    return (
        <Fragment>
            <Grid item xs={12} className="mt-4">
                <p
                    variant="h6"
                    className="mt-2 bg-blue-400 flex items-center gap-2 justify-center p-5 mb-3 rounded-md font-bold text-xl text-white"
                >
                    Your Address Details
                    <span className="text-2xl">
                        <RiHome5Line />
                    </span>{" "}
                </p>
                <div className="flex flex-col lg:flex-row md:flex-row justify-center gap-2">
                    {confirmAddress ? (
                        <div class="border-2 border-black/10 rounded-md flex flex-col justify-center items-center gap-0 px-3 py-4">
                            <div class="flex gap-1">
                                <span class="word-span font-semibold text-center block mb-2">
                                    {selectedAddress.name}
                                </span>
                                <span class="word-span font-semibold text-center block mb-2">
                                    {selectedAddress.mobile}
                                </span>
                            </div>
                            <PincodeData
                                poName={selectedAddress.pincodeData.poName}
                                cityBlock={selectedAddress.pincodeData.cityBlock}
                                district={selectedAddress.pincodeData.district}
                                stateName={selectedAddress.pincodeData.stateName}
                                pincode={selectedAddress.pincodeData.pincode}
                            />
                            <ManualAddress
                                completeAdd={selectedAddress.manualAddress.completeAdd}
                                buildingDetails={selectedAddress.manualAddress.buildingDetails}
                                floorDetails={selectedAddress.manualAddress.floorDetails}
                                landmark={selectedAddress.landmark}
                            />
                        </div>
                    ) : (
                        <div className="bg-[#f5f5f5] flex flex-col rounded-md">
                            <Modal
                                title="Choose Your Address"
                                open={isAddressModal}
                                onCancel={closeAddressModal}
                                footer={null}
                            >
                                {
                                    !newAddress ? (
                                        <>
                                           
                                            {
                                                checkAdminSalesPersonRole(auth?.user?.role) && (
                                                    <div className="grid grid-cols-10  gap-4  flex items-center md:ml-[3em]  mt-8 ">
                                                        <div className="col-span-7 ">
                                                            <PhoneInput
                                                                buttonStyle={{ border: "1px solid #e4e4e4" }}
                                                                inputStyle={{
                                                                    padding: "20px",
                                                                    paddingRight:"2px",
                                                                    paddingInline: "50px",
                                                                    border: "1px solid #e4e4e4",
                                                                    width: "calc(100% + 0.2px)",
                                                                    outline: "#0000FF",
                                                                }}
                                                                country={"in"}
                                                                value={mobile}
                                                                onChange={handleMobileChange}
                                                            />
                                                        </div>
                                                        <div>
                                                            <button
                                                                className="  w-[7em] col-span-3 bg-black text-white border border-black rounded-md flex items-center justify-center gap-1 text-black hover:text-white hover:bg-black transition-all duration-200 py-[7px]"
                                                                onClick={() => getAddressHandler(mobile)}
                                                            >
                                                                Get Address
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div
                                             className="flex justify-center"
                                             >
                                                <IoRemoveOutline className="text-6xl text-gray-300" />
                                            </div>

                                            <div 
                                              className=" flex justify-center   "
                                              >
                                                <button
                                                  onClick={() => setNewAddress(true)}
                                                  className="cursor-pointer font-bold text-white action:scale-95 duration-200 transition-all flex items-center gap-2 bg-none  px-2  rounded-md text-blue-500 -mt-4"
                                                >
                                               Add New Address <span className="text-xl">+</span>
                                                </button>
                                            </div>

                                            {
                                              <div
                                              className="mt-4"
                                              >
                                                {
                                                  address.map((adr) => (
                                                    <Address
                                                        key={adr._id}
                                                        selectAddress={selectAddress}
                                                        enableAddressEditForm={enableAddressEditForm}
                                                        confirmAddressUsed={confirmAddressUsed}
                                                        adr={adr}
                                                    />
                                                  ))
                                                }

                                              </div>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <span className="text-3xl cursor-pointer" onClick={() => setNewAddress(false)}>
                                                <IoArrowBackCircleOutline />
                                            </span>
                                            <AddressForm
                                                setNewAddress={setNewAddress}
                                                closeAddressModal={closeAddressModal}
                                            />
                                        </>
                                    )
                                }
                            </Modal>
                        </div>
                    )}
                    <SelectYourAddress
                        showAddressModal={showAddressModal}
                        confirmAddress={confirmAddress}
                    />
                </div>

            </Grid>

        </Fragment>
    )
}

export default CheckoutServiceProvider