import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        backgroundColor: '#3778C2',
        color: '#fff',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    description: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    hsn: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    permonth: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    duration: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    type: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
});

const InvoiceTableHeader = ({ subsType, stateName }) => (
    <View style={styles.container}>
        <Text style={styles.description}>Description</Text>
        <Text style={styles.hsn}>HSN</Text>
        {
            subsType=== "Enrolment Basis" ? <>
                <Text style={styles.permonth}>Charges Per Month</Text>
                <Text style={styles.duration}>Duration</Text>
            </> : <Text style={styles.type}>Type</Text>
        }
        <Text style={styles.amount}>Amount (Incl. of Taxes)</Text>
    </View>
);

export default InvoiceTableHeader;