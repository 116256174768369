import React from "react";
import { Link } from 'react-router-dom';
import { generatePublicImgURL } from "../../../urlConfig";
import { ADMIN, HOME_PAGE_ICON_PHOTOS } from "../utils/constants/s3Constants";

const HomeStaysAndTourismBanner = () => {
    return (
        <>
            <div className="flex text-xs text-center justify-center bg-blue-100 mx-2 mt-[80px] pb-[4em] rounded-b-[30px] gap-2 shadow-sm">
                {/* Image 3 */}
                <Link to='hotels'>
                    <div className="-mt-[62px] text-center relative">
                        <img
                            src={generatePublicImgURL(ADMIN + '/' + HOME_PAGE_ICON_PHOTOS + '/' + 'Hotel3D.png')}
                            alt="Hotels"
                            width={100}
                            height={100}
                            className=" bg-black/20 rounded-full p-2 hover:scale-95 duration-200 transition-all py-3 pointer-events-none"
                        />
                        {/* <p className="absolute bottom-[3em] text-xs translate-x-1/2 right-1/2 text-black rounded-b-full p-1 px-4  bg-blue-100 ">
                        coming soon.
                    </p> */}
                        <p className="mt-4">Hotels</p>
                    </div>
                </Link>

                {/* Image 1 */}
                <div className="-mt-[66px] text-center relative  ">
                    <img
                        src={generatePublicImgURL(ADMIN + '/' + HOME_PAGE_ICON_PHOTOS + '/' + 'Hills3D.png')}
                        alt="Hills3D"
                        width={130}
                        height={130}
                        className=" bg-black/20 rounded-full p-1 hover:scale-95 duration-200 transition-all pointer-events-none"
                    />
                    <p className="absolute bottom-[1em] text-xs translate-x-1/2 right-1/2 text-black rounded-b-full p-1 px-4  bg-blue-100 ">
                        coming soon.
                    </p>
                    <p className="mt-2 text-center  absolute ">
                        Adventure and Activities
                    </p>
                </div>

                {/* Image 2 */}
                <Link to='home-stays'>
                    <div className="-mt-[62px] text-center relative">
                        <img
                            src={generatePublicImgURL(ADMIN + '/' + HOME_PAGE_ICON_PHOTOS + '/' + 'HomeStays3D.png')}
                            alt="Homestays"
                            width={100}
                            height={100}
                            className=" bg-black/20 rounded-full p-1 hover:scale-95 duration-200 transition-all"
                        />
                        {/* <p className="absolute bottom-[3.2em] text-xs translate-x-1/2 right-1/2 text-black rounded-b-full p-1 px-4  bg-blue-100 ">
            coming soon.
          </p> */}
                        <p className="mt-4">HomeStays</p>
                    </div>
                </Link>
            </div>

            <p className=" mt-3 text-center text-xs">*= Now only in Himachal Region</p>
        </>
    );
};

export default HomeStaysAndTourismBanner;