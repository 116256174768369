import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom';
import { IoIosCall } from 'react-icons/io'

const Applicants = (props) => {
    console.log("applicants", props.location.aboutProps)
    const applies = props.location.aboutProps !== undefined ? props.location.aboutProps.applies : []

    if (props.location.aboutProps === undefined) {
        return <Redirect to='/dashboard' />
    }
    return (
        <Fragment>
            {
                applies.length === 0 ? <p>Nobody has applied Yet</p> :
                    (
                        <div className='job bg-light p-1 my-1'>
                            <h2 className="my-2">Applicants who applied for this job</h2>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        applies.map(applyApplicant =>
                                            <tr key={applyApplicant._id}>
                                                <td>#</td>
                                                <td>{applyApplicant.name}</td>
                                                <td>{<a href={`tel:/${applyApplicant.mobile}`} className="btn1 btn-success width-50"> <IoIosCall />
                                                    Call
                                                </a>}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    )
            }
        </Fragment>
    )
}

export default Applicants