// reducer.js
import {
    CheckReferralConstants
} from '../actions/types';

const initialState = {
    loading: false,
    isValidReferral: false,
    referralMessage: '',
    errorReferralMessage: null,
    referringUser: null
};

const referralCodeReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CheckReferralConstants.UPDATE_REFERRAL_CODE:
            return {
                ...state,
                loading: false,
                isValidReferral: false,
                referralMessage: '',
                referringUser: null,
                errorReferralMessage: null
            };
        case CheckReferralConstants.CHECK_REFERRAL_CODE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CheckReferralConstants.CHECK_REFERRAL_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                isValidReferral: payload.isValidReferral,
                referralMessage: payload.referralMessage,
                referringUser: payload.referringUser,
                errorReferralMessage: null
            };
        case CheckReferralConstants.CHECK_REFERRAL_CODE_FAILURE:
            return {
                ...state,
                loading: false,
                isValidReferral: false,
                referralMessage: '',
                referringUser: null,
                errorReferralMessage: payload.error,
            };
        default:
            return state;
    }
};

export default referralCodeReducer;