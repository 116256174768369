import React from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { GoEye } from "react-icons/go";
import { IoCall, IoCallOutline } from "react-icons/io5";
import { generatePublicImgURL } from '../../urlConfig';
import Carousel from "react-multi-carousel";
import { customDate } from '../../utils/formatDate';
import { ADMIN, ICONS_AND_PHOTOS } from '../aNewGoToUI/utils/constants/s3Constants';
import { checkToShowAddProfessionalDetailsButton } from '../utils/workercategory/WorkerCategory';

const ServiceCardStatus = ({ professional }) => {
    const auth = useSelector((state) => state.auth);
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
        },
    };

    return (
        <div className="flex flex-col gap-3 mx-2 border-2 rounded-sm border-black/10 mb-5 md:mx-[20em]">
            <div className="bg-black/10 rounded-b-full h-[150px] relative">
                <p className="text-center mt-9 font-medium pt-2 text-xl text-black/90">
                    {professional?.name}
                </p>
                {
                    professional?.paymentStatus === "success" &&
                    <p className="absolute text-xs rounded-full shadow-xs top-1 right-2 bg-green-300 py-1 px-2 text-gray-700 ">
                        Payment status : {professional?.paymentStatus}
                    </p>
                }

                <p className="absolute text-xs rounded-full shadow-xs top-1 left-2 py-1 px-2 text-gray-700 border border-gray-300 px-4 ">
                    {
                        professional?.status.type === 'active' ?
                            <span> Active till {customDate(professional?.status.date, 12)} </span> :
                            <>
                                <p>
                                    {professional?.status.type.charAt(0).toUpperCase() +
                                        professional?.status.type.slice(1)}
                                </p>
                            </>
                    }
                </p>
                {/* <div className="flex justify-around mt-[2.5em]">
                    <button className="bg-green-300 p-3 text-xl border-3 border-white rounded-full active:scale-95 duration-200 transition-all "><GoEye /></button>
                    <button className="bg-green-300 p-3 text-xl border-3 border-white rounded-full active:scale-95 duration-200 transition-all"><IoCall /></button>
                </div> */}
                <div className="w-[6em] absolute  -bottom-[5em] left-1/2 transform -translate-x-1/2 -translate-y-1/2   rounded-full border-3 border-white">
                    <img
                        src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + "profile.png")}
                        alt="profile_image"
                        width={100}
                        height={100}
                    />
                </div>
            </div>

            <div className="text-center text-xs text-black/70 mt-4 p-3 ">
                <p className="text-center text-sm py-1 my-2 text-black font-bold">
                    {professional?.service}
                    {
                        professional?.choiceOfService !== null ? <span> - {professional?.choiceOfService.name} </span> : null
                    }
                </p>
                <span>{professional?.address?.pincodeData.poName}, {professional?.address?.pincodeData.cityBlock}, {professional?.address?.pincodeData.district}, {professional?.address?.pincodeData.stateName}
                    ({professional?.address?.pincodeData.pincode})</span>
            </div>


            <div className=" mx-2  ">
                <Carousel
                    swipeable={true}
                    removeArrowOnDeviceType={["mobile"]}
                    dotListClass="mt-"
                    itemClass="carousel-item-padding-40-px mb-[6px] ml-2"
                    responsive={responsive}
                    autoPlay={1000}
                    infinite={true}
                >
                    {

                        professional?.images.length > 0 && (
                            professional?.images.map(image => (
                                <img src={generatePublicImgURL('serviceprofessionals/' + image.img)} alt="professionalImage" className="rounded-md md:w-[10em]" />
                            ))
                        )
                    }
                </Carousel>
            </div>
            {
                checkToShowAddProfessionalDetailsButton(professional, auth) && (
                    <div className='blue-gradient w-[14em] md:w-[20em] text-white font-extrabold text-xl py-2 text-center m-auto my-4 rounded-full'>
                        <Link
                            to={{
                                pathname: `/professional/${professional._id}/details`,
                                state: {
                                    properties: professional.properties,
                                    servicePropertyFields: professional.choiceOfService?.properties ? professional.choiceOfService.properties : professional.servicePropertyFields,
                                    profId: professional._id
                                }
                            }}
                        >
                            Add Professional Details
                        </Link>
                    </div>
                )
            }



        </div>
    )
}

export default ServiceCardStatus