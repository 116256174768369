import { ProductConstants } from "../actions/types";

const initialState = {
    products: [],
    priceRange: {},
    productsByPrice: {},
    pageRequest: false,
    page: {},
    error: null,
    productDetails: {},
    loading: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ProductConstants.ADD_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true   
            };
        case ProductConstants.GET_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: payload.products
            };
        case ProductConstants.GET_PRODUCTS_BY_SLUG:
            return {
                ...state,
                products: payload.products,
                priceRange: payload.priceRange,
                productsByPrice: {
                    ...payload.productsByPrice
                }
            };
        case ProductConstants.GET_PRODUCT_PAGE_REQUEST:
            return {
                ...state,
                pageRequest: true,
            };
        case ProductConstants.GET_PRODUCT_PAGE_SUCCESS:
            return {
                ...state,
                page: payload.page,
                pageRequest: false,
            };
        case ProductConstants.GET_PRODUCT_PAGE_FAILURE:
            return {
                ...state,
                pageRequest: false,
                error: payload.error,
            };
        case ProductConstants.GET_PRODUCT_DETAILS_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ProductConstants.GET_PRODUCT_DETAILS_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                productDetails: payload.productDetails,
            };
        case ProductConstants.GET_PRODUCT_DETAILS_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
}