import React from 'react'
import { generatePublicImgURL } from '../../../../urlConfig'
import { ADMIN, ICONS_AND_PHOTOS } from '../constants/s3Constants'

const NoProfessionalsFound = () => {
  return (
    <div className='col-span-2'>
      <div className='flex flex-col items-center justify-center'>
        <img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + 'ProfessionalNotFound.jpeg')} alt="error" />
        <p className='text-blue-400 font-bold text-4xl'>Coming Soon</p>
      </div>
    </div>
  )
}

export default NoProfessionalsFound