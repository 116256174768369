import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4'
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Grid } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import { FiPlusCircle } from "react-icons/fi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdPayment } from "react-icons/md";
import { Modal, Button } from 'antd';

import { useDispatch, useSelector } from "react-redux";
import { getSectors } from '../../../actions/sector'
import { getServiceFields, getServices, getServicesBySector } from '../../../actions/service';
import { addProfessional, getProfessionalStatus } from '../../../actions/workerCategory';
import { getSubscriptions } from '../../../actions/subscription';
import { payPhonePe } from '../../../actions/Payments/phonepe';
import { customDate } from '../../../utils/formatDate';
import { selectChoice } from '../../utils/choiceOfService';
import { getAddress } from '../../../actions/address';
import { checkReferralCode, updateReferralCode } from '../../../actions/user';
import Spinner from '../../layout/Spinner';
import RegisterProfessional from '../../invoices/RegisterProfessional'
import CheckoutServiceProvider from "../../checkout/CheckoutServiceProvider";
import { checkPaymentNeededByServiceName, getLocationByServiceNameWeOnboard } from "../utils/configs/serviceLocationWeOnboard";
import { generatePublicImgURL } from "../../../urlConfig";
import { ADMIN, ICONS_AND_PHOTOS } from "../utils/constants/s3Constants";
import PhoneInput from 'react-phone-input-2'
import { TrackGoogleAnalyticsEvent } from "../../../utils/Google Analytics/Hooks/useGAEventsTracker";
import { BUTTON, FORM, PROFESSIONAL_REGISTRATION_SUBMITTED, SUBMITTED } from "../../../utils/Google Analytics/GA4Constants";
import getCompleteMobileNumberWithPlus from "../../../utils/MobileNumber";
import { checkAdminRole, checkAdminSalesPersonRole, checkUserRole } from "../../../utils/checkRole";
import { compressImages } from "../../../utils/compressImages";
import SubscriptionPlanCard from "../../subscription/SubscriptionCard";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};


const RegisterServiceProfessional = () => {

    const sector = useSelector((state) => state.sector);
    const service = useSelector((state) => state.service);
    const auth = useSelector((state) => state.auth);
    const professional = useSelector((state) => state.professional);
    const subscription = useSelector((state) => state.subscription)
    const userAddress = useSelector((state) => state.address);
    const user = useSelector((state) => state.user);

    const [sectors, setSectors] = useState(sector.sectors);
    const [services, setServices] = useState(service.services);
    const [serviceFields, setServiceFields] = useState(service.serviceFields)
    const [professionalStatus, setProfessionalStatus] = useState(professional.professional);
    const [subscriptionPlans, setSubscriptionPlans] = useState(subscription.subscriptions);
    const [subscriptionPlan, setSubscriptionPlan] = useState(Object)

    const [serviceDetails, setServiceDetails] = useState(Object)
    const [choices, setChoices] = useState(null)
    const [enableButton, setEnableButton] = useState(true);
    const [confirmAddress, setConfirmAddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [newAddress, setNewAddress] = useState(false);
    const [address, setAddress] = useState([]);
    const [paymentEnabledOption, setPaymentEnabledOption] = useState(true)
    const [goToLocationOptions, setGoToLocationOptions] = useState([])
    const [adminPaymentOption, setAdminPaymentOption] = useState(false)
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [discountedSubscriptionPrice, setDiscountedSubscriptionPrice] = useState('')
    const [isValidReferral, setIsValidReferral] = useState(false);
    const [showReferralInput, setShowReferralInput] = useState(false);
    const [referralMessage, setReferralMessage] = useState('')
    const [referringUser, setReferringUser] = useState({})
    const [errorReferralMessage, setErrorReferralMessage] = useState(null)

    /* Input Fields */

    const [mobile, setMobile] = useState('')
    const [firmName, setFirmName] = useState('')
    const [sectorId, setSectorId] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [choiceOfService, setChoiceOfService] = useState(Object)
    const [choiceId, setChoiceId] = useState('')
    const [addressId, setAddressId] = useState('')
    const [description, setDescription] = useState('');
    const [productPictures, setProductPictures] = useState([]);
    const [goToLocation, setGoToLocation] = useState([]);
    const [referralCode, setReferralCode] = useState('');

    // Logging state fields
    // console.log("sectors", sectors)
    // console.log("Services", services)
    // console.log("ps", paymentStatus)
    // console.log(subscriptionType)
    // console.log(professionalStatus)
    // console.log(serviceDetails?.type)
    // console.log("subscriptionPlans", subscriptionPlans)
    // console.log("subscriptionPlan", subscriptionPlan)
    // console.log("sP", subscriptionPrice)
    // console.log("dP", discountedSubscriptionPrice)
    // console.log('sectorId', sectorId)
    // console.log('serviceDetails', serviceDetails)
    // console.log('serviceId', serviceId)
    // console.log('choiceId', choiceId)
    // console.log("choiceOfService ", choiceOfService)
    // console.log("gotolocationOptions", goToLocationOptions)
    // console.log("choices", choices)
    // console.log("Address Id", addressId)
    // console.log("enableButton", enableButton)
    // console.log("productPictures ", productPictures)
    // console.log("goToLocation", goToLocation)
    // console.log("paymentEnabledOption", paymentEnabledOption)
    // console.log(adminPaymentOption)
    // console.log("referralInput ", showReferralInput)
    // console.log("isValidReferral ", isValidReferral)


    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, []);

    useEffect(() => {
        // if (auth.isAuthenticated) { // commenting for registering without login
        dispatch(getSectors());
        dispatch(getServices());
        // }
        // }, [auth.isAuthenticated]); // commenting for registering without login
    }, []);




    useEffect(() => {
        setSectors(sector.sectors);
    }, [sector.sectors]);




    useEffect(() => {
        setServices(service.services);
    }, [service.services]);




    useEffect(() => {
        setServiceFields(service.serviceFields);
        if (
            (
                (
                    (
                        serviceDetails != null && serviceDetails?.choices?.length === 0
                    ) || (
                        Object.keys(choiceOfService).length > 0
                    )
                )
                && service.serviceFields.length === 0 // this is the case when service and its choice is not in serviceFields schema
            ) ||
            (
                service.serviceFields.length > 0 && service.serviceFields?.[0]?.multipleRegistration === false
            )
        ) {
            dispatch(getProfessionalStatus(serviceDetails._id, choiceOfService?._id, '', mobile))
        }
    }, [service.serviceFields]);





    useEffect(() => {
        setSubscriptionPlans(subscription.subscriptions);
        // setDiscountfForServiceProvider(user.isValidReferral, referralCode, setDiscountedSubscriptionPrice, subscription.subscriptions?.[0]?.price)
    }, [subscription.subscriptions]);





    useEffect(() => {
        setProfessionalStatus(professional.professional);
        if (
            professional.professional?.[0]?.status.type === 'active') {
            setEnableButton(false)
        }
        else {
            setEnableButton(true)
        }

    }, [professional.professional]);



    useEffect(() => {
        const address = userAddress.address.map((adr) => ({
            ...adr,
            selected: false,
            edit: false,
        }));
        setAddress(address);
    }, [userAddress.address]);




    useEffect(() => {
        console.log("useEffect sector Id")
        if (sectorId != '') {
            dispatch(getServicesBySector(sectorId))
            dispatch(getAddress())
            resettingInputFieldsWhenSectorChanges(setServiceId, setChoices, setChoiceOfService,
                setChoiceId, setProfessionalStatus,
                setServiceFields, setFirmName, setDescription, setAddressId,
                setConfirmAddress, setSelectedAddress, setNewAddress, setAddress, setServiceDetails, setGoToLocationOptions, setAdminPaymentOption,
                setDiscountedSubscriptionPrice);
        }
    }, [sectorId]);




    useEffect(() => {
        console.log("useEffect service")
        resettingInputFieldsIfServiceChanges(setServiceId, serviceDetails, setChoices,
            setChoiceOfService, setChoiceId, setProfessionalStatus, setEnableButton,
            setServiceFields, setFirmName, setDescription, setAddressId,
            setConfirmAddress, setSelectedAddress, setNewAddress, setAddress, setGoToLocationOptions, setGoToLocation, setAdminPaymentOption,
            setDiscountedSubscriptionPrice);

        if (serviceDetails != null) {
            dispatch(getAddress())
            if (serviceDetails?.choices?.length === 0) {
                const serviceFieldsObj = {
                    serviceId: serviceDetails._id
                }
                dispatch(getServiceFields(serviceFieldsObj))
                setPaymentEnabledOption(checkPaymentNeededByServiceName(serviceDetails?.type))
                setGoToLocationOptions(getLocationByServiceNameWeOnboard(serviceDetails?.type))
                // if (checkPaymentNeededByServiceName(serviceDetails?.type)) {
                // console.log("subscription call ")
                const subscriptionObj = {
                    serviceId: serviceDetails._id,
                    // type: "Enrolment Basis",
                    enabled: true
                }
                dispatch(getSubscriptions(subscriptionObj))
                // }
            }

            // dispatch(sendWhatsAppMessage(auth.user.mobile))
        }
    }, [serviceDetails?._id]);

    // Call professional status api with service Id and choice Id if we have choices with the type of service
    useEffect(() => {
        console.log("useEffect choice of service")
        resettingInputFieldsWhenChoiceChanges(setChoiceId, setProfessionalStatus, setServiceFields,
            setFirmName, setDescription, setAddressId, setConfirmAddress,
            setSelectedAddress, setNewAddress, setAddress, setGoToLocationOptions, setGoToLocation, setAdminPaymentOption,
            setDiscountedSubscriptionPrice);

        if (Object.keys(choiceOfService).length > 0) {
            dispatch(getAddress())
            setChoiceId(choiceOfService._id);
            const serviceFieldsObj = {
                serviceId: serviceDetails._id,
                choiceId: choiceOfService._id
            }
            dispatch(getServiceFields(serviceFieldsObj))
            // console.log("inside choice onboard")
            setPaymentEnabledOption(checkPaymentNeededByServiceName(Object.keys(choiceOfService).length > 0 ? choiceOfService?.name : serviceDetails?.type))
            setGoToLocationOptions(getLocationByServiceNameWeOnboard(Object.keys(choiceOfService).length > 0 ? choiceOfService?.name : serviceDetails?.type))
            // if (checkPaymentNeededByServiceName(Object.keys(choiceOfService).length > 0 ? choiceOfService?.name : serviceDetails?.type)) {
            // console.log("subscription call ")
            const subscriptionObj = {
                serviceId: serviceDetails._id,
                choiceId: choiceOfService._id,
                // type: "Enrolment Basis",
                enabled: true
            }
            dispatch(getSubscriptions(subscriptionObj))
            // }
        }

    }, [choiceOfService?._id]);

    useEffect(() => {
        setIsValidReferral(user.isValidReferral)
        setReferralMessage(user.referralMessage)
        setReferringUser(user.referringUser)
        setErrorReferralMessage(user.errorReferralMessage)
        // setDiscountfForServiceProvider(user.isValidReferral, referralCode, setDiscountedSubscriptionPrice, subscriptionPrice);
    }, [user]);

    const handleReferralClick = () => {
        setShowReferralInput(true);
    };

    const handleReferralCodeChange = (e) => {
        setReferralCode(e.target.value);
        // setIsValidReferral(false); // Reset validation status when the user modifies the input
        // set actual Price by giving 0% discount until it is validated
        // setDiscountedSubscriptionPrice(giveDiscountForServiceProvider(subscriptionPrice, 0))
        dispatch(updateReferralCode())
    };

    const handleReferralCodeSubmit = () => {
        // Perform validation logic here, e.g., make an API call to validate the referral code
        dispatch(checkReferralCode(referralCode));
    };

    // Function to handle onChange event of the PhoneInput and add "+"
    const handleMobileChange = (mobile) => {
        setMobile(getCompleteMobileNumberWithPlus(mobile))
    };

    const handleImageChange = async (e) => {
        const compressedImages = await compressImages(e.target.files);
        setProductPictures([
            ...productPictures,
            ...compressedImages
        ]);
    }

    // Subscription Plan modal 

    const closeSubscriptionModal = () => {
        setSubscriptionModal(false);
    };

    const showSubscriptionModal = () => {
        setSubscriptionModal(true);
    };

    const selectSubscriptionHandler = (subscriptionPlan) => {
        setSubscriptionPlan(subscriptionPlan);
        setSubscriptionModal(false);
    }

    //  ends here 


    const addProfessionalForm = () => {
        // Error handling
        if (serviceId === '') {
            alert('Please Select Service')
            return
        } else if (choices.length > 0 ? choiceId === '' : false) {
            alert('Please Select Choice Of Service')
            return
        }

        // Check if goToLocation is not empty before allowing form submission
        // Commenting to onboard any service provider
        // if (goToLocation.length === 0) {
        //     alert('Please provide a value for goToLocation');
        //     return; // Do not proceed with form submission
        // }

        const form = new FormData();
        // Set mobile number based on mobile state if present, otherwise take from auth -> this for admin form
        form.append('mobile', mobile || (auth && auth.user && auth.user.mobile));
        form.append('subs_id', subscriptionPlan?._id);
        form.append('paymentForCategory', "addProfessional");
        form.append('newProfessional[sectorId]', sectorId);
        form.append('newProfessional[serviceId]', serviceId);
        form.append('newProfessional[choiceId]', choiceId);
        form.append('newProfessional[addressId]', addressId)
        form.append('newProfessional[firmName]', firmName)
        form.append('newProfessional[description]', description)
        form.append('referralCode', referralCode)
        for (let loc of goToLocation) {
            form.append('newProfessional[goToLocation]', loc);
        }
        for (let pic of productPictures) {
            form.append('images', pic, pic.name);
        }
        if ((checkAdminRole(auth?.user?.role) && adminPaymentOption) || (!checkAdminRole(auth?.user?.role) && paymentEnabledOption)) {
            dispatch(payPhonePe(form));
            history.push('/phonepe-loading')
        }
        else {
            dispatch(addProfessional(form));
            history.push('/loading')
        }
        TrackGoogleAnalyticsEvent(SUBMITTED, PROFESSIONAL_REGISTRATION_SUBMITTED, FORM)
    }

    return (
        <Box className="p-4 lg:w-[80em] m-auto">
            <div className="bg-blue-400 text-white flex justify-center p-5 mb-3 rounded-md font-bold text-xl">
                <p className="flex gap-2 items-center ">
                    Add service with details
                    <span className="text-2xl">
                        <FiPlusCircle />
                    </span>
                </p>
            </div>
            <Grid container spacing={0.7}>
                <div className="flex md:flex-row lg:flex-row flex-col w-full gap-2 ml-1 md:py-2 lg:py-2">

                    {
                        checkAdminSalesPersonRole(auth?.user?.role) && (
                            <div className="col-span-6 mt-2">
                                <PhoneInput
                                    buttonStyle={{ border: "1px solid #e4e4e4" }}
                                    inputStyle={{
                                        padding: "20px",
                                        paddingInline: "50px",
                                        border: "1px solid #e4e4e4",
                                        width: "100%",
                                    }}
                                    country={"in"}
                                    value={mobile}
                                    onChange={handleMobileChange}
                                />
                            </div>
                        )
                    }

                    {/* Sectors Autocomplete */}
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={sectors}
                            getOptionLabel={(option) => option.sector}
                            value={sectors.find((option) => option._id === sectorId) || null}
                            onChange={(e, newValue) => setSectorId(newValue ? newValue._id : 'all')}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Sector" variant="outlined" />
                            )}
                        />
                    </Grid>

                    {/* Services Autocomplete */}
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={services}
                            getOptionLabel={(option) => option?.type}
                            value={services.find((option) => option._id === serviceDetails?._id) || null}
                            onChange={(event, newValue) => setServiceDetails(newValue || {})}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Service" variant="outlined" />
                            )}
                        />
                    </Grid>

                    {
                        selectChoice(choices, choiceOfService, setChoiceOfService, serviceDetails)
                    }
                </div>

                {
                    professionalStatus?.[0]?.status.type !== 'active' ? (
                        <>
                            <div className="flex md:flex-row lg:flex-row flex-col w-full gap-1 ml-1 md:py-2 lg:py-2 gap-2 mt-2">
                                <Grid item xs={12}>
                                    <TextField
                                        label="FirmName (If any)"
                                        variant="outlined"
                                        className="w-full"
                                        name='firmName'
                                        value={firmName}
                                        onChange={(e) => setFirmName(e.target.value)}
                                        required
                                    />

                                </Grid>

                                {
                                    selectGoToLocation()
                                }
                            </div>

                            {
                                checkForInputFields(productPictures, serviceFields, handleImageChange)
                            }


                            <Grid item xs={12}>
                                <TextArea
                                    placeholder="Add Description"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name='description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Grid>
                        </>
                    ) : ''
                }

                {
                    showContents(professionalStatus)
                }

                {
                    professionalStatus?.[0]?.status.type !== 'active' ? (
                        <>
                            <CheckoutServiceProvider
                                setAddressId={setAddressId}
                                confirmAddress={confirmAddress}
                                setConfirmAddress={setConfirmAddress}
                                selectedAddress={selectedAddress}
                                setSelectedAddress={setSelectedAddress}
                                newAddress={newAddress}
                                setNewAddress={setNewAddress}
                                address={address}
                                setAddress={setAddress}
                            />

                            {
                                checkAdminRole(auth?.user?.role) && (
                                    <select value={adminPaymentOption} onChange={(e) => setAdminPaymentOption(e.target.value === 'true')}>
                                        <option value="">Select Payment Option</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>
                                )
                            }

                            {
                                (addressId !== '' && ((checkAdminRole(auth?.user?.role) && adminPaymentOption) || (!checkAdminRole(auth?.user?.role) && paymentEnabledOption))) ? (
                                    <div className="container">

                                        {
                                            <div className="w-full  flex justify-center items-center p-10 my-3 border-2 border-dashed border-blue-300 rounded-lg cursor-pointer hover:bg-gray-100">
                                                <button className="blue-gradient px-4 py-2 rounded-md uppercase text-white font-bold" onClick={showSubscriptionModal}>Choose Your Subscription Plan</button>
                                            </div>
                                        }

                                        {
                                            <Modal
                                                title="Choose Your Subscription Plan"
                                                open={subscriptionModal}
                                                onCancel={closeSubscriptionModal}
                                                footer={null}
                                            >
                                                {
                                                    subscriptionPlans.length > 0 && (
                                                        <>
                                                            {
                                                                subscriptionPlans.map((subscriptionPlan) => (
                                                                    <SubscriptionPlanCard subscriptionPlan={subscriptionPlan} selectSubscriptionHandler={selectSubscriptionHandler} />
                                                                ))
                                                            }
                                                        </>
                                                    )
                                                }
                                            </Modal>

                                        }

                                        {
                                            checkUserRole(auth?.user?.role) && isSubscriptionPlanSet() && referralInput()
                                        }

                                        {
                                            Object.keys(subscriptionPlan).length > 0 && (
                                                <>
                                                    <div className="bg-blue-400 text-white flex justify-center p-5 mb-3 rounded-md font-bold text-xl">
                                                        <p className="flex gap-2 items-center ">
                                                            Payment Details
                                                            <span className="text-2xl">
                                                                <FiPlusCircle />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    {
                                                        checkoutInvoiceDetails(serviceId, choices, choiceOfService, professionalStatus, serviceDetails, subscriptionPlan)
                                                    }
                                                    {
                                                        checkoutPayment(enableButton, addProfessionalForm)
                                                    }
                                                </>
                                            )
                                        }

                                    </div>
                                ) : (
                                    <>
                                        {
                                            addressId !== '' ? (
                                                <div className="container lg:flex lg:justify-end">
                                                    {
                                                        registerProfessional(addProfessionalForm)
                                                    }
                                                </div>
                                            ) : ''
                                        }
                                    </>
                                )
                            }
                        </>
                    ) : ''
                }

            </Grid>
        </Box>
    );

    // Function to check if subscriptionPlan has been set
    function isSubscriptionPlanSet() {
        return Object.keys(subscriptionPlan).length > 0;
    };

    function selectGoToLocation() {
        return (
            <Grid item xs={12}>
                <Autocomplete
                    multiple
                    // options={getLocationByServiceNameWeOnboard(Object.keys(choiceOfService).length > 0 ? choiceOfService?.name : serviceDetails?.type)}
                    options={goToLocationOptions}
                    value={goToLocation}
                    onChange={(event, newValue) => setGoToLocation(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Go To Locations" />
                    )} />
            </Grid>
        );
    }

    function referralInput() {
        return (
            <div className="referral-code-input flex flex-col justify-center items-center">
                <div className="referral-code-button mx-2 text-sm transition-all duration-200 ">
                    Having a referral?{" "}
                    <button
                        onClick={() => {
                            /* Handle referral code input */
                            handleReferralClick();
                        }}
                        className="text-blue-500 hover:to-blue-500 transition-all duration-200 "
                    >
                        Click here{" "}
                    </button>{" "}
                    to enter your referral code.
                </div>

                {showReferralInput && (
                    <>
                        <div className="flex gap-2">
                            <input
                                type="text"
                                placeholder="Enter Referral Code"
                                value={referralCode}
                                onChange={(e) => handleReferralCodeChange(e)}
                                className="w-full flex items-center justify-center py-2 px-3 rounded-md border border-gray-300 "
                            />
                            <button className="border border-black rounded-md px-3 hover:bg-black hover:text-white transition-all duration-200" onClick={handleReferralCodeSubmit}>Submit</button>
                        </div>

                        {
                            user.loading ? <Spinner /> : isValidReferral && referralCode !== '' ? (
                                <p style={{ color: 'green' }}>Verified {referringUser.name}! {referralMessage}</p>
                            ) : referralMessage != '' && referralCode !== '' ? (
                                <p style={{ color: 'red' }}>{referralMessage}</p>
                            ) : null
                        }

                        {errorReferralMessage && <p style={{ color: 'red' }}>Error: {errorReferralMessage.message}</p>}
                    </>

                )}
            </div>
        );
    }

}

function setDiscountfForServiceProvider(isValidReferral, referralCode, setDiscountedSubscriptionPrice, subscriptionPrice) {
    if (isValidReferral && referralCode !== '') {
        // Give 10% off on giving referral code
        setDiscountedSubscriptionPrice(giveDiscountForServiceProvider(subscriptionPrice, 0));
    } else {
        // set actual Price by giving 0% off on giving referral code
        setDiscountedSubscriptionPrice(giveDiscountForServiceProvider(subscriptionPrice, 0));
    }
}

function checkoutInvoiceDetails(serviceId, choices, choiceOfService, professionalStatus, serviceDetails, subscriptionPlan) {
    return (
        <div className="invoice-details">
            {/* <p
                variant="h6"
                className="mb-2 mt-5 bg-[#dcd8d8fe] flex items-center gap-2 justify-center p-5 mb-3 rounded-md font-bold text-xl text-gray-600"
            >
                Invoice Details
                <span className="text-2xl">
                    <LiaFileInvoiceDollarSolid />
                </span>
            </p> */}
            {
                checkForShowInvoice(serviceId, choices, choiceOfService, professionalStatus) &&
                <>
                    <RegisterProfessional
                        price={subscriptionPlan?.price}
                        serviceName={serviceDetails.type}
                        choiceOfService={choiceOfService}
                        durationInMonths={subscriptionPlan?.durationInMonths}
                        subscriptionType={subscriptionPlan?.type}
                    />
                </>
            }
        </div>
    )
}

function checkoutPayment(enableButton, addProfessionalForm) {
    return (
        <div className="payment-options mt-5">
            {/* <p
                variant="h6"
                className=" my-2 mt-4 bg-[#dcd8d8fe] flex items-center gap-2 justify-center p-5 mb-3 rounded-md font-bold text-xl text-gray-600"
            >
                Payment Options
                <span className="text-2xl">
                    <MdPayment />
                </span>{" "}
            </p> */}
            <button
                className="flex items-center gap-2 bg-[#f5f5f581] border-2 border-gray-200 justify-center w-full py-2 rounded-md text-lg font-semibold "
                onClick={addProfessionalForm}
            >
                Pay Via UPI <img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + 'UPIIcon.jpg')} alt="UPIIcon" width={35} />
            </button>
        </div>
    )
}

function registerProfessional(addProfessionalForm) {
    return (
        <div className="mt-2">
            <button
                className="flex mt-5 items-center gap-2 border-2 bg-blue-400 justify-center lg:w-[15em] w-full py-[10px] rounded-md text-xl text-white font-semibold"
                onClick={addProfessionalForm}
            >
                Register <img src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + 'RegisterIcon.svg')} alt="RegisterIcon" width={27} className="filter invert" />
            </button>
        </div>
    )
}

function resettingInputFieldsWhenSectorChanges(setServiceId, setChoices, setChoiceOfService,
    setChoiceId, setProfessionalStatus,
    setServiceFields, setFirmName, setDescription, setAddressId,
    setConfirmAddress, setSelectedAddress, setNewAddress, setAddress, setServiceDetails, setGoToLocationOptions, setAdminPaymentOption,
    setDiscountedSubscriptionPrice) {
    setServiceId('');
    setChoices([]);
    setChoiceOfService({});
    setChoiceId(null);
    setProfessionalStatus([]);
    setServiceFields([]);
    setFirmName('')
    setDescription('')
    setAddressId('')
    setConfirmAddress(false)
    setSelectedAddress(null)
    setNewAddress(false)
    setAddress([])
    setServiceDetails({})
    setGoToLocationOptions([])
    setAdminPaymentOption(false)
    setDiscountedSubscriptionPrice('')
}

function resettingInputFieldsWhenChoiceChanges(setChoiceId, setProfessionalStatus,
    setServiceFields, setFirmName, setDescription, setAddressId,
    setConfirmAddress, setSelectedAddress, setNewAddress, setAddress,
    setGoToLocationOptions, setGoToLocation, setAdminPaymentOption, setDiscountedSubscriptionPrice) {
    setChoiceId('');
    setProfessionalStatus([]);
    setServiceFields([])
    setFirmName('')
    setDescription('')
    setAddressId('')
    setConfirmAddress(false)
    setSelectedAddress(null)
    setNewAddress(false)
    setAddress([])
    // setGoToLocationOptions([])
    setGoToLocation([])
    setAdminPaymentOption(false)
    setDiscountedSubscriptionPrice('')
}

function resettingInputFieldsIfServiceChanges(setServiceId, serviceDetails, setChoices, setChoiceOfService,
    setChoiceId, setProfessionalStatus, setEnableButton,
    setServiceFields, setFirmName, setDescription, setAddressId,
    setConfirmAddress, setSelectedAddress, setNewAddress, setAddress,
    setGoToLocationOptions, setGoToLocation, setAdminPaymentOption, setDiscountedSubscriptionPrice) {
    setServiceId(Object.keys(serviceDetails).length === 0 ? '' : serviceDetails._id);
    setChoices(serviceDetails ? serviceDetails.choices : null);
    setChoiceOfService({});
    setChoiceId('');
    setProfessionalStatus([]);
    setEnableButton(true);
    setServiceFields([])
    setFirmName('')
    setDescription('')
    setAddressId('')
    setConfirmAddress(false)
    setSelectedAddress(null)
    setNewAddress(false)
    setAddress([])
    setGoToLocationOptions([])
    setGoToLocation([])
    setAdminPaymentOption(false)
    setDiscountedSubscriptionPrice('')
}

function showContents(professionalStatus) {
    return (
        <div className="m-auto">
            {
                professionalStatus?.[0]?.status.type === 'applied' &&
                (professionalStatus?.[0]?.paymentStatus === "pending" || professionalStatus?.[0]?.paymentStatus === "failed") &&
                <p className="text-center"style={{ color: 'darkgoldenrod' }}>You have tried to register for the service, Please complete your registeration by completing the payment</p>
            }

            {
                professionalStatus?.[0]?.status.type === 'applied' &&
                professionalStatus?.[0]?.paymentStatus === "success" &&
                <p className="text-center">You have already applied to this service</p>
            }

            {
                professionalStatus?.[0]?.status.type === 'active' &&
                <div>
                    <p className="text-center">
                        You have already registered to this service and aapki
                        subscription {customDate(professionalStatus[0].status.date, 12)} khatam
                        ho jayegi
                    </p>
                </div>
            }

            {/* Refer AddWorker.js file for more checks in this function */}
        </div>
    );
}

function giveDiscountForServiceProvider(subscriptionPrice, percentage) {
    return (subscriptionPrice * (1 - percentage / 100));
}

function checkForShowInvoice(serviceId, choices, choiceOfService, professionalStatus) {
    return professionalStatus?.[0]?.status.type !== 'active' &&
        serviceId != '' && (choices?.length > 0 ? (Object.keys(choiceOfService).length > 0) : true);
}

function checkForInputFields(productPictures, serviceFields, handleImageChange) {
    return (
        <>
            {
                serviceFields?.[0]?.images.displayStatus ? (
                    <div className='ml-2 my-3'>
                        {
                            productPictures.length > 0 ?
                                productPictures.map((pic, index) => <div key={index}>{pic.name}</div>) : null
                        }
                        <input type="file" multiple onChange={handleImageChange} />
                    </div>
                ) : ''
            }
        </>
    )
}

export default RegisterServiceProfessional