import { JobCategoryConstants } from "../actions/types";

const initialState = {
    jobCategories: [],
    error: null,
    loading: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case JobCategoryConstants.GET_JOB_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case JobCategoryConstants.GET_JOB_CATEGORY_SUCCESS:
            return {
                ...state,
                jobCategories: payload.jobCategories,
                loading: false,
            };
        case JobCategoryConstants.GET_JOB_CATEGORY_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload.error
            }
        default:
            return state;
    }
}