import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Alert from './components/layout/Alert';
import ProfileForm from './components/profile-forms/ProfileForm';
import AddExperience from './components/profile-forms/AddExperience';
import AddEducation from './components/profile-forms/AddEducation';
import Profile from './components/profile/Profile';
import Jobs from './components/jobs/Jobs';
import PrivateRoute from './components/routing/PrivateRoute';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import { updateCart } from './actions/cart';
import { getStates } from './actions/countrySpecificData';
import './App.css';
import { getInitialData } from './actions/initialData';
import Header from './components/layout/Header';
import MenuHeader from './components/layout/MenuHeader';
import ProductListPage from './components/product/ProductListPage';
import ProductDetailsPage from './components/product/ProductDetailsPage';
import Page from './components/Page';
import CartPage from './components/cart/CartPage'
import CheckoutPage from './components/checkout/CheckoutPage'
import OrderDetailsPage from './components/order/OrderDetailsPage'
import OrderAdmin from './components/order/OrderAdmin'
import OrderPage from './components/order/OrderPage'
import Sectors from './components/sector/Sectors';
import Services from './components/service/Services';
import ProfessionalList from './components/workerCategory/ProfessionalList';
import ProfessionalAdmin from './components/workerCategory/ProfessionalAdmin';
import MyServices from './components/workerCategory/MyServices';
import Applicants from './components/jobs/Applicants';
import ProfileAddressForm from './components/profile-forms/ProfileAddressForm';
import TermsAndConditions from './components/documents/TermsAndConditions';
import AddWorkerCallback from './components/workerCategory/AddWorkerCallback';
import setAuthToken from './utils/setAuthToken';
import MyJobs from './components/jobs/Myjobs';
import { Spinner } from 'react-bootstrap';
import NoMatch from './components/layout/NoMatch';
import Loading from './components/layout/Loading';
import ReactGA from 'react-ga4'

/* New GoTo UI Pages*/
import Landing from './components/aNewGoToUI/layout/Landing';
import Electrician from './components/aNewGoToUI/professionalServices/epaService/electrician/Electrician';
import Hotels from './components/aNewGoToUI/professionalServices/travel/hotel/Hotels';
import HomeStays from './components/aNewGoToUI/professionalServices/travel/homeStays/HomeStays';
import FlatHomes from './components/aNewGoToUI/professionalServices/rentals/residentialProperty/FlatHomes/FlatHomes';
import Wifi from './components/aNewGoToUI/professionalServices/epaService/wifi/Wifi';
import Plumber from './components/aNewGoToUI/professionalServices/epaService/plumber/Plumber';
import AC from './components/aNewGoToUI/professionalServices/epaService/ac/AC';
import ROPurifier from './components/aNewGoToUI/professionalServices/epaService/roPurifier/ROPurifier';
import CCTV from './components/aNewGoToUI/professionalServices/epaService/cctv/CCTV';
import Refrigerator from './components/aNewGoToUI/professionalServices/epaService/refrigerator/Refrigerator';
import TV from './components/aNewGoToUI/professionalServices/epaService/tv/TV';
import WashingMachine from './components/aNewGoToUI/professionalServices/epaService/washingMachine/WashingMachine';
import Chimney from './components/aNewGoToUI/professionalServices/epaService/chimney/Chimney';
import Geyser from './components/aNewGoToUI/professionalServices/epaService/geyser/Geyser';
import GasStove from './components/aNewGoToUI/professionalServices/epaService/gasStove/GasStove';
import Dishwasher from './components/aNewGoToUI/professionalServices/epaService/dishwasher/Dishwasher';
import Microwave from './components/aNewGoToUI/professionalServices/epaService/microwave/Microwave';
import PrinterAndInk from './components/aNewGoToUI/professionalServices/epaService/printerAndInk/PrinterAndInk';
import Photostat from './components/aNewGoToUI/professionalServices/epaService/photostat/Photostat';
import PG from './components/aNewGoToUI/professionalServices/rentals/residentialProperty/pg/PG';
import SingleRoom from './components/aNewGoToUI/professionalServices/rentals/residentialProperty/singleRoom/SingleRoom';
import RetailShop from './components/aNewGoToUI/professionalServices/rentals/commercialProperty/retailShop/RetailShop';
import Taxis from './components/aNewGoToUI/professionalServices/transportationSector/taxisForPassengers/Taxis';
import PickUpVans from './components/aNewGoToUI/professionalServices/transportationSector/pickUpVans/PickUpVans';
import Architects from './components/aNewGoToUI/professionalServices/architects/Architects';
import RajMistri from './components/aNewGoToUI/professionalServices/construction/rajMistri/RajMistri';
import TileInstallers from './components/aNewGoToUI/professionalServices/construction/tileInstallers/TileInstallers';
import MarbleInstallers from './components/aNewGoToUI/professionalServices/construction/marbleInstallers/MarbleInstallers';
import Painter from './components/aNewGoToUI/professionalServices/construction/painter/Painter';
import Carpenter from './components/aNewGoToUI/professionalServices/construction/carpenter/Carpenter';
import MetalFabricator from './components/aNewGoToUI/professionalServices/construction/metalFabricator/MetalFabricator';
import ModularKitchen from './components/aNewGoToUI/professionalServices/homeAndDecors/modularKitchen/ModularKitchen';
import UPVCAluminiumDoorWindow from './components/aNewGoToUI/professionalServices/homeAndDecors/upvcAluminium/UPVCAluminiumDoorWindow';
import JCB from './components/aNewGoToUI/professionalServices/construction/jcb(backhoe)/JCB';
import ConcreteMixer from './components/aNewGoToUI/professionalServices/construction/concreteMixer/ConcreteMixer';
import Shuttering from './components/aNewGoToUI/professionalServices/construction/shuttering/Shuttering';
import PartyHall from './components/aNewGoToUI/professionalServices/eventManagement/partyHall/PartyHall';
import MarriageBanquetHall from './components/aNewGoToUI/professionalServices/eventManagement/marriageBanquetHall/MarriageBanquetHall';
import HimachaliDhamCook from './components/aNewGoToUI/professionalServices/eventManagement/dhamBoti/HimachaliDhamCook';
import GreenLeafPlates from './components/aNewGoToUI/professionalServices/eventManagement/greenpatal/GreenLeafPlates';
import CateringService from './components/aNewGoToUI/professionalServices/eventManagement/cateringService/CateringService';
import Photographer from './components/aNewGoToUI/professionalServices/eventManagement/photographer/Photographer';
import Tent from './components/aNewGoToUI/professionalServices/eventManagement/tent/Tent';
import BandBaja from './components/aNewGoToUI/professionalServices/eventManagement/bandbaja/BandBaja';
import Dhol from './components/aNewGoToUI/professionalServices/eventManagement/dhol/Dhol';
import DJSoundSystem from './components/aNewGoToUI/professionalServices/eventManagement/djSoundSystem/DJSoundSystem';
import GroomCar from './components/aNewGoToUI/professionalServices/eventManagement/groomCar/GroomCar';
import RegisterServiceProfessional from './components/aNewGoToUI/registerServiceProfessional/RegisterServiceProfessional';
import ProfileActions from './components/aNewGoToUI/profile/ProfileActions';
import AboutUs from './components/documents/AboutUs';
import InitializeGoogleAnalytics from './utils/Google Analytics/Hooks/useGAEventsTracker';
import ChateredAccountantCA from './components/aNewGoToUI/professionalServices/legalComplianceAccounting/ChateredAccountantCA';
import Advocates from './components/aNewGoToUI/professionalServices/legalComplianceAccounting/Advocates';
import VolvoBus from './components/aNewGoToUI/professionalServices/transportationSector/VolvoBus';
import TowTruck from './components/aNewGoToUI/professionalServices/transportationSector/TowTruck';
import WaterTanker from './components/aNewGoToUI/professionalServices/transportationSector/WaterTanker';
import PhonePeLoading from './components/layout/PhonePeLoading';
import RefundPolicy from './components/documents/RefundPolicy';
import ShowService from './components/aNewGoToUI/professionalServices/ShowService';
import ShowServiceDetails from './components/aNewGoToUI/professionalServices/ShowServiceDetails';
import ImageGallery from './components/aNewGoToUI/professionalServices/ImageGallery';
import AddProfessionalDetails from './components/workerCategory/AddProfessionalDetails';
import Subscriptions from './components/subscription/Subscriptions';

//Redux Store
window.store = store

const App = () => {
  const auth = useSelector((state) => state.auth);
  
  InitializeGoogleAnalytics();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);
  
  useEffect(() => {
    if (!auth.isAuthenticated) {
      store.dispatch(loadUser());
    }
    if (auth.isAuthenticated) {
      // store.dispatch(getInitialData());
      store.dispatch(getStates());
    }
  }, [auth.isAuthenticated]);
  setAuthToken(localStorage.token);

  if (auth.loading) {
    return <Spinner />
  }

  return (
    <>
        <Fragment>
          {/* {
            auth.isAuthenticated && auth?.user?.role === "admin" ? (
              <Navbar />
            ) : (
              <>
                <Header />
                <MenuHeader/>
              </>
            )
          } */}

          <section className='m-auto'>
            <Alert />
            <Switch>
            <Route exact path='/' component={Landing} />
              {/* <PrivateRoute exact path='/profiles' component={Profiles} /> */}
              <Route exact path='/profile/:id' component={Profile} />
              {/* <PrivateRoute exact path='/dashboard' component={Dashboard} /> */}
              {/* <PrivateRoute
                exact
                path='/add-info'
                component={ProfileAddressForm}
              /> */}
              <PrivateRoute
                exact
                path='/create-profile'
                component={ProfileForm}
              />
              {/* <PrivateRoute
                exact
                path='/edit-profile'
                component={EditProfile}
              /> */}
              <PrivateRoute
                exact
                path='/add-experience'
                component={AddExperience}
              />
              <PrivateRoute
                exact
                path='/add-education'
                component={AddEducation}
              />
              {/* <PrivateRoute
                exact
                path='/add-job'
                component={JobForm}
              /> */}
              {/* <PrivateRoute exact path='/jobs' component={Jobs} />
              <PrivateRoute exact path='/jobs/:id' component={Job} />
              <PrivateRoute exact path='/myjobs' component={MyJobs} /> */}
              
              {/* <PrivateRoute exact path='/add-service' component={AddWorker} /> */}
              {/* <PrivateRoute exact path='/professionals' component={ProfessionalList} />/ */}
              <PrivateRoute exact path='/professionalappl' component={ProfessionalAdmin} />
              <PrivateRoute exact path='/sectors' component={Sectors} />
              <PrivateRoute exact path='/services' component={Services} />
              <PrivateRoute exact path='/subscriptions' component={Subscriptions} />
              <PrivateRoute exact path='/myservices' component={MyServices} />
              <PrivateRoute exact path='/applicants' component={Applicants} />
              <PrivateRoute exact path='/professionals/:id' component={AddWorkerCallback} />
              <PrivateRoute exact path='/professional/:id/details' component={AddProfessionalDetails} />

              {/* Document Links */}
              <Route exact path='/terms' component={TermsAndConditions} />	             
              <Route exact path='/refund-policy' component={RefundPolicy} />
              <PrivateRoute exact path='/about-us' component={AboutUs} />

              <PrivateRoute exact path='/loading' component={Loading} />
              <PrivateRoute exact path='/phonepe-loading' component={PhonePeLoading} />

              {/* New UI Pages */}
              <PrivateRoute exact path='/add-service' component={RegisterServiceProfessional} />
              <PrivateRoute exact path='/profile-actions' component={ProfileActions} />
            

              <Route exact path='/show-service' component={ShowService} />
              <Route exact path='/show-service-details/:profId' component={ShowServiceDetails}/>
              <Route exact path='/image-gallery' component={ImageGallery}/>


              { /* EPA (Electrical, Plumbing, Appliance Service Pages) */ }
              
              <Route exact path='/electrician' component={Electrician} />
              <Route exact path='/plumber' component={Plumber} />
              <Route exact path='/ro' component={ROPurifier} />
              <Route exact path='/wifi' component={Wifi} />
              <Route exact path='/ac' component={AC} />
              <Route exact path='/refrigerator' component={Refrigerator} />
              <Route exact path='/cctv' component={CCTV} />
              <Route exact path='/tv' component={TV} />
              <Route exact path='/washing-machine' component={WashingMachine} />
              <Route exact path='/chimney' component={Chimney} />
              <Route exact path='/geyser' component={Geyser} />
              <Route exact path='/gas-stove' component={GasStove} />
              <Route exact path='/dishwasher' component={Dishwasher} />
              <Route exact path='/microwave' component={Microwave} />
              <Route exact path='/printer-ink' component={PrinterAndInk} />
              <Route exact path='/photostat' component={Photostat} />

              {/* Rentals */}
              <Route exact path='/pg' component={PG} />
              <Route exact path='/single-room' component={SingleRoom} />
              <Route exact path='/flat-homes' component={FlatHomes} />
              <Route exact path='/retail-shops' component={RetailShop} />

              {/* Hotels and Home Stays */}
              <Route exact path='/hotels' component={Hotels} />
              <Route exact path='/home-stays' component={HomeStays} />

              {/* Legal Compliance and Accounting */}
              <Route exact path='/ca' component={ChateredAccountantCA} />
              <Route exact path='/advocates' component={Advocates} />

              {/* Transportation */}
              <Route exact path='/taxis' component={Taxis} />
              <Route exact path='/pickup-vans' component={PickUpVans} />
              <Route exact path='/volvo-bus' component={VolvoBus} />
              <Route exact path='/tow-truck' component={TowTruck} />
              <Route exact path='/water-tanker' component={WaterTanker} />

              {/* Construction, Home and Decors and Architects */}
              <Route exact path='/architects' component={Architects} />
              <Route exact path='/construction-worker' component={RajMistri} />
              <Route exact path='/tile-installers' component={TileInstallers} />
              <Route exact path='/marble-installers' component={MarbleInstallers} />
              <Route exact path='/painter' component={Painter} />
              <Route exact path='/carpenter' component={Carpenter} />
              <Route exact path='/metal-fabricator' component={MetalFabricator} />
              <Route exact path='/modular-kitchen' component={ModularKitchen} />
              <Route exact path='/upvc-aluminium' component={UPVCAluminiumDoorWindow} />
              <Route exact path='/jcb' component={JCB} />
              <Route exact path='/concrete-mixer' component={ConcreteMixer} />
              <Route exact path='/shuttering' component={Shuttering} />


              {/* Wedding and Event Management */}
              <Route exact path='/party-hall' component={PartyHall} />
              <Route exact path='/marriage-banquet-hall' component={MarriageBanquetHall} />
              <Route exact path='/himachali-dham-cook' component={HimachaliDhamCook} />
              <Route exact path='/green-leaf-plates' component={GreenLeafPlates} />
              <Route exact path='/catering' component={CateringService} />
              <Route exact path='/photographer' component={Photographer} />
              <Route exact path='/tent' component={Tent} />
              <Route exact path='/band-baja' component={BandBaja} />
              <Route exact path='/dhol' component={Dhol} />
              <Route exact path='/dj-sound-system' component={DJSoundSystem} />
              <Route exact path='/groom-car' component={GroomCar} />

              {/* Jobs */}
              <Route exact path='/jobs' component={Jobs} />
            
              {/* <PrivateRoute path='/category' component={Category} />
              <PrivateRoute path='/product' component={Product} /> */}
              {/* <PrivateRoute exact path='/page' component={Page} />
              <PrivateRoute exact path="/cart" component={CartPage} />
              <PrivateRoute exact path="/checkout" component={CheckoutPage} />
              <PrivateRoute exact path="/account/orders" component={OrderPage} />
              <PrivateRoute exact path="/order_details/:orderId" component={OrderDetailsPage} />
              <PrivateRoute exact path="/orders" component={OrderAdmin} />
              <PrivateRoute exact path="/:productSlug/:productId/details" component={ProductDetailsPage}/>
              <PrivateRoute exact path='/:slug' component={ProductListPage} /> */}
              <Route path='*' component={NoMatch} />
            </Switch>
          </section>
        </Fragment>
    </>
  );
};
export default App;
