import {
    REGISTER_SUCCESS, REGISTER_FAIL, USER_LOADED, AUTH_ERROR,
    LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, ACCOUNT_DELETED, LOGOUT_SUCCESS, LOGOUT_REQUEST, LOGOUT_FAILURE, USER_LOAD_REQUEST
} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    loading: true, 
    user: null,
    error: null,
    message: ''
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_LOAD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload
            }
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            console.log("Auth reducer LOGIN_SUCCESS")
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false
            }
        case LOGOUT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case ACCOUNT_DELETED:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null
            }
        case LOGOUT_FAILURE:
            return {
                ...state,
                error: payload.error,
                loading: false,
            }
        default:
            return state;
    }
}