const ManualAddress = (props) => {
    const { completeAdd, buildingDetails, floorDetails, landmark } = props
    return (
        <div className="text-[0.8em] text-center word-span font-normal mt-2">
            {
                completeAdd != '' && completeAdd != undefined &&
                (
                        <p style={{ "marginBottom": "0.5rem" }}>Complete Address: {completeAdd}</p> 
                )
            }
            {
                buildingDetails != ''&& buildingDetails != undefined &&
                (
                    <p style={{ "marginBottom": "0.5rem" }}>Building Details: {buildingDetails} &nbsp;</p>
                )
            }
            {
                floorDetails != '' && floorDetails != undefined &&
                (
                    <p style={{ "marginBottom": "0.5rem" }}>Floor Details: {floorDetails} &nbsp;</p>
                )
            }
            {
                landmark != '' && landmark != undefined &&
                (
                    <p style={{ "marginBottom": "0.5rem" }}>Landmark: {landmark} &nbsp;</p>
                )
            }
        </div>
    )
}
export default ManualAddress