import React from 'react';

const TermsAndConditions = () => {
    return (
        <div style={styles.container}>
            <section>
                <h1 style={styles.heading}>Terms And Conditions</h1>
            </section>
            <div>
                <ol style={styles.list}>
                    <li style={styles.listItem}>
                        1. By accessing and placing an order with us, you confirm that you are in agreement with and bound by the terms of service contained in the Terms & Conditions outlined below. These terms apply to the entire website and any email or other type of communication between you and under no circumstances shall team be liable for any direct, indirect, special, incidental or consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the inability to use, the materials on this site, even if team or an authorized representative has been advised of the possibility of such damages. If your use of materials from this site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof will not be responsible for any outcome that may occur during the course of usage of our resources. We reserve the rights to change prices and revise the resources usage policy in any moment.
                    </li>
                    <li style={styles.listItem}>
                        2. Our Service and its contents are provided "as is" and "as available" without any warranty or representations of any kind, whether express or implied. We are a distributor and not a publisher of the content supplied by third parties; as such, we exercise no editorial control over such content and make no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via our Service. Without limiting the foregoing, We specifically disclaim all warranties and representations in any content transmitted on or in connection with our Service or on sites that may appear as links on our Service, or in the products provided as a part of, or otherwise in connection with, our Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by us or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty.
                    </li>
                    <li style={styles.listItem}>
                        3. We merely display the information submitted by the users of our service and their qualifications are not verified by us. We only verify their contact information. Employers and employees are advised to contact each other and verify their details. This service serves as a medium to connect people to each other and the verification of quality of work solely relies on the user of the service. This service will not be held responsible for checking and confirming any information other than contact details.
                    </li>
                    <li style={styles.listItem}>
                        4. Amendments to this T and C: We reserve the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use our service.
                    </li>
                    <li style={styles.listItem}>
                        5. Entire Agreement: The Agreement constitutes the entire agreement between you and us regarding your use of the service and supersedes all prior and contemporaneous written or oral agreements between you and us. You may be subject to additional terms and conditions that apply when you use or purchase other services from us, which we will provide to you at the time of such use or purchase.
                    </li>
                    <li style={styles.listItem}>
                        6. Updates to Our Terms: We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account.
                    </li>
                </ol>
            </div>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '80em',
        margin: 'auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    heading: {
        textAlign: 'center',
        margin: '10px 0 30px 0',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    list: {
        padding: 0,
    },
    listItem: {
        marginBottom: '20px',
        fontSize: '16px',
    },
};

export default TermsAndConditions;
